import { ViewerService } from './../../viewer/viewer.service';

import { Direction } from "@angular/cdk/bidi";
import { COMMA, ENTER } from "@angular/cdk/keycodes";
import { Platform } from "@angular/cdk/platform";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  ViewChild,
  ViewChildren,
  ViewContainerRef,
} from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from "@angular/forms";
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { MatSidenav } from "@angular/material/sidenav";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatTabGroup } from "@angular/material/tabs";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SwiperConfigInterface, SwiperDirective } from "ngx-swiper-wrapper";
import { Coordinate } from "ol/coordinate";
import {
  BehaviorSubject,
  merge,
  Observable,
  Subject,
  Subscription,
} from "rxjs";
import { debounceTime, distinctUntilChanged, skip, takeUntil } from "rxjs/operators";
// eslint-disable-next-line import/no-named-as-default
import Swiper from "swiper";
import { ZoomOptions } from "swiper/types/components/zoom";
import { Annotation } from "./sidebar/annotations/annotation.model";
import { environment } from "./../../../../environments/environment";
import { prefix } from "./../../../config";
import { FormCanDeactivateDirective } from "./../../../shared/can-deactivate/form-can-deactivate.directive";
import { DialogService } from "./../../../shared/dialog/dialog.service";
import { DrawingArea } from "./../../../shared/drawing";
import { FoldersService } from "./../../../shared/folders/folders.service";
import { PreviousRouteService } from "./../../../shared/helpers/previous-route.service";
import { LanguageService } from "./../../../shared/i18n/language.service";
import { KrpanoService } from "./../../../shared/pano/krpano.service";
import { pois } from "./../../../shared/pano/pois";
import { SidenavDetailService } from "./../../../shared/sidenav/sidenav-detail/sidenav-detail.service";
import { FilesService } from "./../../files/files.service";
import { LoginStateService } from "./../../login/login-state.service";
import { Login } from "./../../login/login.model";
import { AccountType, User, UserRole } from "../../users/models/user.model";
import { UsersService } from "./../../users/users.service";
import { FileModel } from "./file.model";
import {
  Permission,
  PermissionRole,
  PermissionScope,
} from "./permission.model";
import { AnnotationsComponent } from "./sidebar/annotations/annotations.component";
import { Viewer3dService } from './../../viewer/viewer3d/viewer3d.service';
import { OLMapsService } from 'src/app/shared/openlayers/maps.service';
import { Chart, Tooltip } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { MatSelectChange } from '@angular/material/select';
import { AddModifyCollaboratorsDialogComponent } from 'src/app/shared/add-modify-collaborators-dialog/add-modify-collaborators-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { CollaborationsService } from './sidebar/collaborations/collaborations.service';
import { Collaboration } from './sidebar/collaborations/collaboration.model';
import { OrdersService } from 'src/app/shared/orders/orders.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';
Chart.register(
  ChartDataLabels,
  Tooltip
);

@Component({
  selector: "app-file",
  templateUrl: "./file.component.html",
  styleUrls: ["./file.component.scss"],
})
export class FileComponent
  extends FormCanDeactivateDirective
  implements AfterViewInit, OnDestroy, OnInit
{
  currentComponent = null;
  tabIndex: number;
  addOnBlur = true;
  annotationDrawType: string;
  annotationInEdit = false;
  annotationUpdated = false;
  severityColor = "green";
  @ViewChild(AnnotationsComponent) annotationSidebar?: AnnotationsComponent;
  dir: Direction;
  @ViewChild("drawer") drawer: MatSidenav;
  drawing: any;
  drawingArea: DrawingArea;
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  fileModel: FileModel;
  collaborations: Collaboration[] = undefined;
  folderModel: FileModel;
  @ViewChild("form") form: NgForm;
  formGroup: FormGroup;
  formHotspotGroup: FormGroup;
  isMobile = this.platform.ANDROID || this.platform.IOS;
  isPano = false;
  isPanoLoaded = false;
  onMap = false;
  onViewer = false;
  isPublic = false;
  isInspection = false;
  isInspection3d = false;
  isSiteURL = false;
  loggedUser: User;
  login: Login;
  @ViewChildren('chartCanvas, matComment', { read: ElementRef }) elements: QueryList<ElementRef>;
  matComments: QueryList<ViewContainerRef>;
  @ViewChild("matTabGroup") matTabGroup: MatTabGroup;
  panoClickTimer: number;
  panoHView: string = null;
  permission$: BehaviorSubject<string> = new BehaviorSubject("");
  permissionAutocompleteOptions: Array<Permission> = [];
  permissionRole = PermissionRole;
  permissionScope = PermissionScope;
  pois = pois;
  poisTag = '';
  prefix = prefix;
  previousURL: string;
  removable = true;
  selectable = true;
  selectedCount = 0;
  readonly separatorKeysCodes: Array<number> = [ENTER, COMMA];
  swiperConfig: SwiperConfigInterface = {};
  @ViewChild(SwiperDirective) swiperDirectiveRef?: SwiperDirective;
  swiperSilent = false;
  swiperWheeling: NodeJS.Timeout;
  swiperZoomed = false;
  swiperZooming: NodeJS.Timeout;
  @ViewChild("tagsAutocomplete") tagsAutocomplete: MatAutocomplete;
  userRole = UserRole;
  accountType = AccountType
  usersSubscription: Subscription;
  view: string;
  virtualData: any = { slides: [] };
  width = 360;
  theme: string= this.themeService.changed$.value;
  reconfigureTheSwipper: boolean = false

  private ngDestroy$ = new Subject();

  constructor(
    private themeService: ThemeService,
    public filesService: FilesService,
    public sidenavDetailService: SidenavDetailService,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private foldersService: FoldersService,
    private krpanoService: KrpanoService,
    private languageService: LanguageService,
    public loginStateService: LoginStateService,
    private platform: Platform,
    private previousRouteService: PreviousRouteService,
    private renderer: Renderer2,
    private route: ActivatedRoute,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    public usersService: UsersService,
    private viewerService: ViewerService,
    private viewer3dService: Viewer3dService,
    private oLMapsService:OLMapsService,
    private dialog: MatDialog,
    private collaborationsService: CollaborationsService,
    private ordersService: OrdersService,
    private headerService: HeaderService,
    public permissionsService: PermissionsService
  ) {
    super(); // Constructors for derived classes must contain a 'super' call
    const reg = "(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?";

    this.filesService.forceRefreshed.pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.updateLive(String(this.fileModel._id));
    });

    this.formHotspotGroup = this.formBuilder.group({
      isChecked: new FormControl({ value: "", disabled: this.fetching }),
      alpha: new FormControl({ value: "", disabled: this.fetching }),
      icon: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.maxLength(256),
      ]),
      index: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.maxLength(256),
      ]),
      name: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.maxLength(256),
      ]),
      openURL: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.pattern(reg),
      ]),
      scale: new FormControl({ value: "", disabled: this.fetching }),
      tooltip: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.maxLength(256),
      ]),
    });

    this.formGroup = this.formBuilder.group({
      comment: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.maxLength(4000),
      ]),
      comments: this.formBuilder.array([]),
      panoCustomXML: new FormControl({ value: "", disabled: this.fetching }),
      panoLittlePlanet: new FormControl({
        value: false,
        disabled: this.fetching,
      }),
      panoTitle: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.maxLength(256),
      ]),
      panoView: this.formBuilder.group({
        fov: new FormControl({ value: "", disabled: this.fetching }),
        h: new FormControl({ value: "", disabled: this.fetching }),
        v: new FormControl({ value: "", disabled: this.fetching }),
      }),
      hotspots: this.formBuilder.array([]),
      inherit: new FormControl({ value: true, disabled: this.fetching }),
      name: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.required,
        // Safe Characters https://docs.aws.amazon.com/AmazonS3/latest/dev/UsingMetadata.html
        Validators.maxLength(256),
        Validators.pattern(/^[^:\\|\/{}<>´`"~^%#]+$/),
      ]),
      orderID: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.maxLength(24),
      ]),
      pattern: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.maxLength(256),
      ]),
      permission: new FormControl({ value: "", disabled: this.fetching }, [
        Validators.email,
        Validators.maxLength(40),
      ]),
      permissions: this.formBuilder.array([]),
      tags: this.formBuilder.array([]),
      tagsChipList: new FormControl({ value: "", disabled: this.fetching }),
      tagsInput: new FormControl({ value: "", disabled: this.fetching }),
    });

    this.filesService.annotationsTabOpen$.pipe(takeUntil(this.ngDestroy$),skip(1)).subscribe((open)=>{
      if (open==true){
        this.tabIndex = 0
      }
    })

    this.router.events.pipe(debounceTime(500),takeUntil(this.ngDestroy$)).subscribe((data) => {
      const firstChild = this.route.snapshot.parent.children[0];
      if(firstChild.routeConfig.path.includes('portfolio')) {
        this.isSiteURL = true
      } else { this.isSiteURL = false }
      if (firstChild.routeConfig.path.includes("inspection3d")) {
        this.isInspection3d = true;
      } else {
        this.isInspection3d = false;
      }
      if (firstChild.routeConfig.path.includes("inspection")) {
        this.isInspection = true;
        setTimeout(() => {
          if (!this.tabIndex) {
            this.tabIndex = 0;
          }
        }, 250);
      } else {
        this.isInspection = false;
      }

      if (
        firstChild.params["folderID"] &&
        firstChild.params["folderID"].length === 24 &&
        firstChild.params["folderID"] !== this.folderModel?._id
      ) { 
          this.filesService.findOne(firstChild.params["folderID"])
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe((response) => {
             this.folderModel = response.data;
          });
      }
    });

    // update form fields disabled state on fetching change
    this.fetchingChange$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((fetching) => {
        Object.keys(this.formGroup.controls).forEach((key) => {
          fetching || this.permissionsService.permissions$.value?.canUpdate.files
            ? this.formGroup.controls[key].enable()
            : this.formGroup.controls[key].disable();
        });
      });

    this.filesService.data$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(() => {
        if (this.swiper && this.fileModel) {
          //this.swiper.virtual.update(true);
          if(!this.isPanoLoaded) {
            this.swiperSetIndex(String(this.fileModel._id));
            this.updateLive(String(this.fileModel._id));
          }
        }
      });

    this.filesService.totalItems$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((data) => {
        if (this.swiper) {
          if(!this.isPanoLoaded) {
            this.swiper.pagination.update();
          }
        }
      });

    this.languageService.dir$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((dir) => (this.dir = dir));

    this.loginStateService.loggedUser$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((loggedUser) => {
        this.loggedUser = loggedUser;
      });

    this.loginStateService.login$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((login) => {
        this.login = login;
      });

    // Check if edit single or multiple files
    this.route.params.pipe(takeUntil(this.ngDestroy$)).subscribe((params) => {
      if (params) {
        const fileID = params["fileID"];
        this.selectedCount = params["selectedCount"] || 1;
        if (this.view != params["view"]) {
          this.view = params["view"];
          if (this.view === 'fullscreen') {
              this.reconfigureTheSwipper = true
          }
        }
        
        if (this.selectedCount === 1 ) {
          this.getFile(fileID);
        } else {
          const selected = this.filesService.data$.value.filter(
            (item) => item.selected
          );
          if (selected.length) {
            const fileIDs = selected.map((file) => file._id) as Array<string>;
            this.fileModel = { _id: fileIDs.join(",") };

            this.setFile();
          } else {
            this.router.navigate([{ outlets: { detail: null } }], {
              queryParamsHandling: "merge",
            });
          }
        }
      }
    });
    window.krpanoView = (hlookat: string, vlookat: string, fov: string) => {
      if(this.isPano && this.fileModel.pano?.compass) {
        let hLookAt = Number(hlookat);
        if(this.fileModel.pano.compassCorrection) {
          hLookAt = hLookAt - this.fileModel.pano.compassCorrection;
        }
        while (hLookAt < -0.0001) {
          hLookAt = hLookAt + 360
        }
        while (hLookAt > 360) {
          hLookAt = hLookAt - 360
        }
        this.panoHView = hLookAt.toFixed(1);
      } else {
        this.panoHView = null;
      }
    };
  }

  ngAfterViewInit(): void {
    // Permission autocomplete users
    merge(this.permission$.pipe(debounceTime(300), distinctUntilChanged()))
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((value: string) => {
        if (this.usersSubscription) {
          this.usersSubscription.unsubscribe(); // Cancel previous request
        }

        if (!value) {
          this.permissionAutocompleteOptions = undefined; // Clear autocomplete if search is empty

          // Add anyone permission if not already added
          if (
            !this.permissions.controls.some(
              (permissionGroup: FormGroup) =>
                permissionGroup.value.scope === PermissionScope.ANYONE
            )
          ) {
            this.permissionAutocompleteOptions = [
              {
                role: PermissionRole.VIEW,
                scope: PermissionScope.ANYONE,
              },
            ];
          }

          return;
        }

        let filter = {};
        if (value) {
          value = value.trim().replace(/ /g, ".*");
          if (/^[0-9a-f]{24}$/.test(value)) {
            // Check if its ObjectID
            filter = { _id: { $oid: value } };
          } else {
            filter["$or"] = [
              { email: { $regex: value, $options: "i" } },
              {
                "$expr": {
                  "$regexMatch": {
                    "input": { "$concat": ["$name", " ", "$surname"] },
                    "regex": value, // Directly using the value here for dynamic searches
                    "options": "i"
                  }
                }
              },
              { organization: { $regex: value, $options: "i" } },
            ];
          }

          // Exclude users already added
          if (this.permissions.controls.length) {
            const excludeIds = {
              _id: {
                $nin: this.permissions.controls
                  .filter(
                    (permissionGroup: FormGroup) => permissionGroup.value.userID
                  )
                  .map((permissionGroup) => ({
                    $oid: permissionGroup.value.userID,
                  })),
              },
            };
            filter = Object.keys(filter).length
              ? { $and: [filter, excludeIds] }
              : excludeIds;
          }

          const fields = { avatarIconLink: 1, email: 1, name: 1, surname:1 };

          this.usersSubscription = this.usersService
            .findMany(filter, null, 0, 7, fields)
            .pipe(takeUntil(this.ngDestroy$))
            .subscribe(
              (response) => {
                this.permissionAutocompleteOptions =
                  response.data &&
                  response.data.map((user: User) => ({
                    avatarIconLink: user.avatarIconLink,
                    email: user.email,
                    name: user.name,
                    surname: user.surname,
                    role: PermissionRole.EDIT,
                    scope: PermissionScope.USER,
                    userID: user._id,
                  }));
              },
              (error) => {
                this.dialogService.showDialog(
                  "USERS.GETTING_FAILED",
                  error.status,
                  error.url,
                  error.error
                );
              }
            );
        }
      });
     this.setSwiperConfig()
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  ngOnInit(): void {
    this.sidenavDetailService.resized$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((width: number) => {
        Promise.resolve().then(() => {
          this.width = width;

          // Fixes https://github.com/angular/material2/issues/6130
          this.matTabGroup.realignInkBar();

          this.swiperResize();
          if (this.drawingArea) {
            this.drawingArea.updateSize();
          }
        });
      });
  }

  hide(fileId,value: boolean) {
    this.filesService.hideFiles([fileId], value).pipe(takeUntil(this.ngDestroy$)).subscribe(
      (response) => {
        this.fileModel.hidden = value;
        if (value) {
          this.dialogService.showDialog("File.Component: File was hidden Successfully",null, null,null,null,true);
        } else {
          this.dialogService.showDialog("File.Component: File was unhidden Successfully",null, null,null,null,true);
        }
      },
      (error) => {
        this.snackBar.open(this.translate.instant('FILES.FILE_HIDE_ERROR'), this.translate.instant('FILES.CLOSE'), {
          duration: 2000,
        });
      }
    );
  }

  setSwiperConfig() {
      this.swiperConfig = {
        a11y: {
          enabled: true,
        },
        direction: "horizontal",
        keyboard: true,
        mousewheel: false,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          renderCustom: (swiper, current, total) => {
            return `${current} / ${this.filesService.data$.value.length}`;
          },
          type: "custom",
        },
        roundLengths: true,
        runCallbacksOnInit: false,
        scrollbar: false,
        slidesPerView: 1,
        virtual: {
          slides: this.filesService.data$.value,
          renderExternal: (data) => {
            if(!this.isPanoLoaded) {
              this.virtualData = data;
            }
          },
        },
        zoom: {
          maxRatio: 2,
        },
      }; 
  }

  closeSidenavDetail(): void {
    this.router.navigate([{ outlets: { detail: null } }], {
      queryParamsHandling: "merge",
    });

    if (this.foldersService.activeFolderID$.value === 'my-files') { // reset to your default permissions
      this.permissionsService.resetPermissions()
    }
  }

  commentAdd(event?: KeyboardEvent): void {
    if (event) {
      event.preventDefault(); // Prevent typing a new line
    }

    this.comments.push(
      this.formBuilder.group({
        avatarIconLink: this.loggedUser.avatarIconLink,
        content: this.comment.value,
        drawing: this.drawing,
        name: this.loggedUser.name,
        userID: this.loggedUser._id,
      })
    );

    this.commentClear();
    this.comments.markAsDirty();
  }

  commentClear(): void {
    this.drawing = null;
    this.comment.setValue("", { onlySelf: false, emitEvent: false });
    this.comment.markAsPristine();
  }

  commentDelete(index?: number): void {
    if (index === -1) {
      if (this.drawingArea) {
        this.drawingArea.delete(this.drawing);
      }
      this.commentClear();
    } else {
      const drawing = this.comments.controls[index].value.drawing;
      if (this.drawingArea && drawing) {
        this.drawingArea.delete(drawing);
      }
      this.comments.removeAt(index);
      this.comments.markAsDirty();
    }

    if (this.drawingArea) {
      if (!this.drawingArea.drawings.length) {
        // Destroy drawing area
        this.drawingArea.destroy();
        this.drawingArea = null;

        // Enable swiper touch
        this.swiper.allowTouchMove = true;
        this.swiper.zoom.enable();
      }
    }
  }

  addCoordsToAnnotation(coords: Array<Array<Coordinate>>): void {
    this.annotationSidebar.formComments.patchValue({
      coordinates: coords,
    });
  }

  commentDraw(shape: string, index: number, markAsDirty: boolean): void {
    if (this.swiper) {
      if (!this.drawingArea && !this.isPano) {
        const activeSlideZoom = this.swiper.$wrapperEl.find(
          `[data-index=\"${this.swiper.activeIndex}\"] .swiper-zoom-container`
        );
        const activeImage = activeSlideZoom.find("img");

        if (activeImage.length) {
          this.drawingArea = new DrawingArea(
            activeImage[0] as HTMLImageElement,
            {
              renderAtNaturalSize: true,
              targetRoot: activeSlideZoom[0] as HTMLElement,
            }
          );
          this.drawingArea.create();

          this.swiper.allowTouchMove = false; // Disable swiper touch
          this.swiper.zoom.disable();
        }
      }

      if (index === -1) {
        if (this.drawingArea) {
          if (this.drawing) {
            this.drawingArea.delete(this.drawing);
          }
          this.drawing = this.drawingArea.draw(shape);
        }

        if (markAsDirty) {
          this.comment.markAsDirty();
        }
      } else {
        const commentGroup = this.comments.controls[index] as FormGroup;
        if (this.drawingArea) {
          // Delete previous drawing
          if (markAsDirty && commentGroup.value.drawing) {
            this.drawingArea.delete(commentGroup.value.drawing);
            commentGroup.value.geo = null;
          }
          // Create new drawing
          const drawing = this.drawingArea.draw(shape, commentGroup.value.geo);
          const drawingControl = this.formBuilder.control(drawing);
          drawing.change.subscribe(() => drawingControl.markAsDirty());
          commentGroup.setControl("drawing", drawingControl);
        }

        if (markAsDirty) {
          commentGroup.markAsDirty();
        }
      }
    }
  }

  copiedToClipboard(): void {
    this.translate.get("FILE.COPIED_TO_CLIPBOARD").subscribe((translation) => {
      this.snackBar.open(translation, "", { duration: 3000 });
    });
  }

  discardChanges(): void {
    this.setFile();
  }

  download(): void {
    this.filesService.download(
      this.renderer,
      this.selectedCount === 1 ? this.fileModel : null,
      this.login.token
    );
  }

  drawingIcon(shape: string): string {
    switch (shape) {
      case "Rectangle":
        return "crop_16_9";
      case "Ellipse":
        return "panorama_fish_eye";
      case "Arrow":
        return "arrow_right_alt";
      case "Text":
        return "text_format";
      default:
        return "more_vert";
    }
  }

  export(): void {
    this.drawingArea.render((dataUrl) => {
      const a = this.renderer.createElement("a");
      if (typeof a.download !== "undefined") {
        this.renderer.setStyle(a, "display", "none");
        this.renderer.setAttribute(a, "href", dataUrl);
        this.renderer.setProperty(a, "download", this.fileModel.name);
        this.renderer.appendChild(document.body, a);
        a.click();
      }
      setTimeout(() => {
        this.renderer.removeChild(document.body, a); // Remove the form after the download has started
      }, 1);
    });
  }

  get showContextualHeader(): boolean {
    return this.formGroup.dirty;
  }

  get fetching(): boolean {
    return this.fetchingChange$.value;
  }
  get permission(): AbstractControl {
    return this.formGroup.get("permission");
  }
  get permissions(): FormArray {
    return this.formGroup.get("permissions") as FormArray;
  }
  get comment(): AbstractControl {
    return this.formGroup.get("comment");
  }
  get comments(): FormArray {
    return this.formGroup.get("comments") as FormArray;
  }
  get hotspot(): FormArray {
    return this.formGroup.get("hotspot") as FormArray;
  }
  get panoCustomXML(): AbstractControl {
    return this.formGroup.get("panoCustomXML");
  }
  get panoLittlePlanet(): AbstractControl {
    return this.formGroup.get("panoLittlePlanet");
  }
  get panoTitle(): AbstractControl {
    return this.formGroup.get("panoTitle");
  }
  get panoView(): AbstractControl {
    return this.formGroup.get("panoView");
  }
  get hotspots(): FormArray {
    return this.formGroup.get("hotspots") as FormArray;
  }
  get inherit(): AbstractControl {
    return this.formGroup.get("inherit");
  }
  get name(): AbstractControl {
    return this.formGroup.get("name");
  }
  get orderID(): AbstractControl {
    return this.formGroup.get("orderID");
  }
  get pattern(): AbstractControl {
    return this.formGroup.get("pattern");
  }
  get tags(): FormArray {
    return this.formGroup.get("tags") as FormArray;
  }
  get tagsInput(): AbstractControl {
    return this.formGroup.get("tagsInput");
  }
  get webContentLink(): string {
    return (
      this.fileModel &&
      this.fileModel.webContentLink &&
      (this.fileModel.public
        ? this.fileModel.webContentLink.split("?")[0] // Return link without query parameters for embedding
        : this.fileModel.webContentLink)
    ); // Return pre-signed link with query parameters that force download
  }
  get publicLink(): string {
    return `${environment.host}shared/${this.fileModel._id}`;
  }
  get swiper(): Swiper {
    return this.swiperDirectiveRef
      ? this.swiperDirectiveRef.swiper()
      : undefined;
  }
  get zoomMaxRatio(): number {
    // Dynamic max zoom depending on resolution
    // <= 500px = 2 zoom levels = scale(2)
    // > 500px = 3 zoom levels = scale(4)
    // > 2K = 4 zoom levels = scale(8)
    // > 4K = 5 zoom levels = scale(16)
    // > 8K = 6 zoom levels = scale(32)
    return this.fileModel
      ? Math.pow(
          2,
          Math.ceil(
            Math.sqrt(
              Math.max(this.fileModel.height, this.fileModel.width) / 500
            )
          )
        )
      : 2;
  }

  getFile(fileID: string): void {
    this.fetchingChange$.next(true);
    this.filesService
      .findOne(fileID)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        (response) => {
          if (response) {
            this.fileModel = response.data; // update data models

            const activeOrderLegacyId = this.ordersService.activeOrder$.value?.legacyId;
            const orderID = this.fileModel?.orderID;
            
            if (orderID && ((activeOrderLegacyId !== orderID) || (!this.collaborations && activeOrderLegacyId === orderID))) {
                this.ordersService.getActiveOrder(this.fileModel.orderID).then(() => {
                const filter = this.collaborationsService.constructFilter('order',this.ordersService.activeOrder$.value._id)

                if (this.loggedUser.accountType !== "admin" && this.loggedUser.accountType !== "superAdmin") {
                  this.permissionsService.setPermissions(this.ordersService.activeOrder$.value.accessLevel || 'owner')
                } 

                this.collaborationsService.getCollaborations(filter).pipe(takeUntil(this.ngDestroy$)).subscribe((response) => {
                  if (response.data) {
                    this.collaborations = this.collaborationsService.enrichCollaborationsWithUsersData(response.data);
                  }
                  
                })
              })
            }
            this.poisTag = '';
            if (this.fileModel.tags) {
              this.fileModel.tags.forEach(tag => {
                if (tag.toLowerCase().startsWith('pois:')) {
                  this.poisTag = tag.replace('pois:','')
                }
              });
            }
            this.setFile(); // update form models
          } else {
            this.closeSidenavDetail();
          }
        },
        (error) => {
          this.fetchingChange$.next(false);
          this.dialogService.showDialog(
            "FILE.GETTING_FAILED",
            error.status,
            error.url,
            error.error
          );
        }
      );
  }

  loadMap(): void {
    if(this.swiper) {
      this.swiper.allowTouchMove = false;
      this.swiper.zoom.disable();
      import('../../olmap/ol-map.component').then(({ OlMapComponent }) => {
        this.currentComponent = OlMapComponent
      });
    }
  }

  setAnnotationInEdit(inEdit: boolean): void {
    this.annotationInEdit = inEdit;
    if (inEdit) {
      this.swiper.allowTouchMove = false;
      this.swiper.allowSlideNext = false;
      this.swiper.allowSlidePrev = false;
    } else {
      this.swiper.allowTouchMove = true;
      this.swiper.allowSlideNext = true;
      this.swiper.allowSlidePrev = true;
    }
  }

  setCurrentSeverityColor(color: string) {
    this.severityColor = color;
  }

  annotationFinishEdit(annotation: Annotation): void {
    this.annotationInEdit = false;
    if (!this.fileModel.annotations) {
      this.fileModel.annotations = [];
    }

    if (!annotation.index) {
      this.fileModel.annotations.push(annotation);
    } else {
      this.fileModel.annotations[annotation.index] = annotation;
    }

    this.annotationUpdated = true;
    this.formGroup.markAsDirty();
    this.updateFile();
    // this.drawer.close();
  }

  annotationRemove(index): void {
    this.annotationInEdit = false;
    if (index !== -1) {
      this.fileModel.annotations.splice(index, 1);
      this.annotationUpdated = true;
      this.formGroup.markAsDirty();
    }
    // this.drawer.close();
  }

  /* eslint-disable complexity */
  getFileUpdates(writable: boolean): FileModel {
    // get only the updates
    const fileUpdates: FileModel = {};
    if (this.fileModel && this.fileModel._id) {
      Object.assign(fileUpdates, { _id: this.fileModel._id });
    }

    // Set inherit only if explicitly set 'inherit: false', else set 'inherit: null' to delete the field altogether
    if (this.inherit.dirty) {
      Object.assign(fileUpdates, {
        inherit: this.inherit.value === false ? false : null,
      });
    }
    if (this.name.dirty) {
      Object.assign(fileUpdates, { name: this.name.value });
    } // Files will be copied in s3
    if (this.panoCustomXML.dirty) {
      if (this.panoCustomXML.value.length !== 0) {
        this.fileModel.pano.customXML = this.panoCustomXML.value;
      } else {
        delete this.fileModel.pano.customXML;
      }
    }
    if (this.panoTitle.dirty) {
      if (this.panoTitle.value.length !== 0) {
        this.fileModel.pano.title = this.panoTitle.value;
      } else {
        delete this.fileModel.pano.title;
      }
    }
    if (writable && this.panoView.dirty) {
      this.fileModel.pano.view = {
        fov: this.panoView.value.fov,
        hlookat: this.panoView.value.h,
        vlookat: this.panoView.value.v,
      };
    }
    if (this.panoLittlePlanet.dirty) {
      this.fileModel.pano.littlePlanet = this.panoLittlePlanet.value;
    }
    if (
      this.panoCustomXML.dirty ||
      this.panoView.dirty ||
      this.panoLittlePlanet.dirty ||
      this.panoTitle.dirty
    ) {
      Object.assign(fileUpdates, { pano: this.panoChangesGet() });
    }
    if (this.orderID.dirty) {
      Object.assign(fileUpdates, { orderID: Number(this.orderID.value) });
    }
    if (this.pattern.dirty) {
      Object.assign(fileUpdates, { pattern: this.pattern.value });
    }
    if (this.tags.dirty) {
      Object.assign(fileUpdates, {
        tags: this.tags.controls.map((tagControl) => tagControl.value),
      });
    }

    if (this.comment.dirty) {
      this.commentAdd();
    }

    if (this.comments.dirty) {
      Object.assign(fileUpdates, {
        comments: this.comments.controls.map((commentGroup: FormGroup) => {
          const value = commentGroup.value;
          const comment = {}; // we use type: any to avoid adding null fields

          if (value.content) {
            comment["content"] = value.content;
          }
          if (value.drawing) {
            comment["geo"] = value.drawing.geoJSON;
          }
          if (writable) {
            // When we save to the server
            if (value._id) {
              comment["_id"] = { $oid: value._id };
            }
            if (value.userID) {
              comment["userID"] = { $oid: value.userID };
            }
          } else {
            // When the comments are refreshed
            comment["_id"] = value._id;
            comment["avatarIconLink"] = value.avatarIconLink;
            comment["drawing"] = value.drawing;
            comment["modified"] = commentGroup.dirty
              ? new Date()
              : value.modified;
            comment["name"] = value.name;
            comment["surname"] = value.surname;
            comment["userID"] = value.userID;
          }

          return comment;
        }),
      });
    }

    if (this.annotationUpdated) {
      Object.assign(fileUpdates, { annotations: this.fileModel.annotations });
    }

    return fileUpdates;
  }
  /* eslint-enable complexity */

  goBack(): void {
    const previousUrl = this.previousRouteService.getPreviousUrl();
    if (previousUrl) {
      if (this.router.url.replace(";view=fullscreen", "") === previousUrl) {
        this.router.navigateByUrl(previousUrl);
      } else {
        this.closeSidenavDetail();
      }
    }
  }

  goFullscreen(): void {
    this.router.navigate(
      [
        {
          outlets: {
            detail: ["file", this.fileModel._id, { view: "fullscreen" }],
          },
        },
      ],
      { queryParamsHandling: "merge" }
    );
  }

  onImageLoad(): void {
    if (this.drawingArea) {
      this.drawingArea.updateSize();
    }
  }

  onPdfLoad(event: any, url: string): void {
    if (event.target.src !== url) {
      event.target.src = url;
    }
  }

  openFile(event: Event, url: string): void {
    if (url) {
      event.preventDefault();
      if (!window.open(url)) {
        // Fallback if popup blocker is active
        window.location.href = url;
      }
    }
  }

  panoChangesGet(): any {
    const pano = {};
    if (
      this.hotspots.value.length > 0 ||
      this.fileModel.pano.view ||
      this.fileModel.pano.customXML ||
      this.fileModel.pano.title ||
      this.fileModel.pano.littlePlanet !== undefined
    ) {
      if (this.fileModel.pano.customXML) {
        Object.assign(pano, { customXML: this.fileModel.pano.customXML });
      }
      if (this.fileModel.pano.littlePlanet !== undefined) {
        if (!this.fileModel.pano.littlePlanet) {
          Object.assign(pano, { littlePlanet: false });
        }
      }
      if (this.fileModel.pano.title) {
        Object.assign(pano, { title: this.fileModel.pano.title });
      }
      if (this.fileModel.pano.view) {
        Object.assign(pano, { view: this.fileModel.pano.view });
      }
      if (this.hotspots.value.length > 0) {
        Object.assign(pano, {
          hotspots: this.hotspots.controls.map((hotspotGroup: FormGroup) => {
            const value = hotspotGroup.value;
            const hotspot = {}; // we use type: any to avoid adding null fields
            if (value.alpha !== 1) {
              hotspot["alpha"] = Number(value.alpha) as number;
            }
            hotspot["ath"] = Number(value.ath) as number;
            hotspot["atv"] = Number(value.atv) as number;
            hotspot["icon"] = value.icon as string;
            hotspot["name"] = value.name as string;

            if (value.openURL && value.openURL.length > 0) {
              hotspot["openURL"] = value.openURL as string;
            }
            if (value.scale !== 1) {
              hotspot["scale"] = Number(value.scale) as number;
            }
            if (value.tooltip && value.tooltip.length > 0) {
              hotspot["tooltip"] = value.tooltip as string;
            }
            hotspot["type"] = value.type as string;

            return hotspot;
          }),
        });
      }

      return pano;
    } else {
      return "";
    }
  }

  panoFileUpdates(): FileModel {
    const fileUpdates: FileModel = {};
    if (this.fileModel && this.fileModel._id) {
      Object.assign(fileUpdates, { _id: this.fileModel._id });
    }
    Object.assign(fileUpdates, { pano: this.panoChangesGet() });

    return fileUpdates;
  }

  panoFinishEdit(): void {
    const pos = this.krpanoService.hotspotGetPosition(
      this.formHotspotGroup.value.name
    );
    this.krpanoService.hotspotMove(this.formHotspotGroup.value.name, false);

    if (!this.formHotspotGroup.value.isChecked) {
      this.formHotspotGroup.patchValue({ openURL: "" });
    }

    const hotspotValues = {
      alpha: this.formHotspotGroup.value.alpha,
      ath: pos.ath as string,
      atv: pos.atv as string,
      icon: this.formHotspotGroup.value.icon as string,
      name: this.formHotspotGroup.value.name as string,
      openURL: this.formHotspotGroup.value.openURL as string,
      scale: this.formHotspotGroup.value.scale as number,
      tooltip: this.formHotspotGroup.value.tooltip as string,
      type: "poi",
    };
    if (this.formHotspotGroup.value.index === -1) {
      this.hotspots.push(this.formBuilder.group(hotspotValues));
    } else {
      (this.formGroup.get("hotspots") as FormArray)
        .at(this.formHotspotGroup.value.index)
        .setValue(hotspotValues);
    }
    this.panoUpdate();
    this.drawer.close();
  }

  panoHotspotAdd(): void {
    const hsName = `hs_${Date.now()}`;
    this.formHotspotGroup.setValue({
      isChecked: false,
      alpha: 1,
      name: hsName,
      openURL: "",
      icon: "marker",
      scale: 1,
      index: -1,
      tooltip: "",
    });
    this.krpanoService.hotspotAdd(
      this.formHotspotGroup.value.name,
      this.formHotspotGroup.value.icon,
      this.poisTag
    );
    this.drawer.open();
  }

  panoHotspotAddContextMenu(h: number, v: number): void {
    const hsName = `hs_${Date.now()}`;
    this.formHotspotGroup.setValue({
      isChecked: false,
      alpha: 1,
      name: hsName,
      openURL: "",
      icon: "marker",
      scale: 1,
      index: -1,
      tooltip: "",
    });
    this.krpanoService.hotspotAdd(
      this.formHotspotGroup.value.name,
      this.formHotspotGroup.value.icon,
      this.poisTag,
      '',
      h,
      v
    );
    this.drawer.open();
  }

  panoHotspotEdit(i: number, lookTo = false): void {
    if (lookTo) {
      this.panoHotspotLookTo(this.hotspots.value[i].name);
    }
    const item = (this.formGroup.get("hotspots") as FormArray).at(
      i
    ) as FormArray;

    // declare tmp Variables
    let tmpAlpha: number;
    let tmpOpenURL: string;
    let tmpScale: number;
    let tmpShowURL: boolean;
    item.value.alpha ? (tmpAlpha = item.value.alpha) : (tmpAlpha = 1);
    item.value.scale ? (tmpScale = item.value.scale) : (tmpScale = 1);
    item.value.openURL ? (tmpShowURL = true) : (tmpShowURL = false);
    item.value.openURL ? (tmpOpenURL = item.value.openURL) : (tmpOpenURL = "");
    this.formHotspotGroup.setValue({
      isChecked: tmpShowURL,
      alpha: tmpAlpha,
      name: item.value.name,
      openURL: tmpOpenURL,
      icon: item.value.icon,
      scale: tmpScale,
      index: i,
      tooltip: item.value.tooltip,
    });
    this.krpanoService.hotspotMove(this.formHotspotGroup.value.name, true);
    this.drawer.open();
  }

  panoHotspotLookTo(name: string): void {
    this.krpanoService.hotspotLookTo(name);
  }

  panoHotspotRemove(): void {
    this.krpanoService.hotspotRemove(this.formHotspotGroup.value.name);
    if (this.formHotspotGroup.value.index !== -1) {
      this.hotspots.removeAt(this.formHotspotGroup.value.index);
      this.panoUpdate();
    }
    this.drawer.close();
  }

  panoHotspotUpdateIcon(key: string): void {
    this.krpanoService.hotspotUpdate(
      this.formHotspotGroup.value.name,
      "url",
      key
    );
    this.formHotspotGroup.controls.icon.setValue(key);
  }

  panoHotspotUpdateScale(event: any): void {
    this.krpanoService.hotspotUpdate(
      this.formHotspotGroup.value.name,
      "scale",
      event.value
    );
  }

  panoHotspotUpdateTooltip(event: any): void {
    this.krpanoService.hotspotUpdate(
      this.formHotspotGroup.value.name,
      "tooltip",
      event.target.value
    );
  }

  panoHotspotUpdateTransparency(event: any): void {
    this.krpanoService.hotspotUpdate(
      this.formHotspotGroup.value.name,
      "alpha",
      event.value
    );
  }

  panoLoad(): void {
    if (document.getElementById("id-" + this.fileModel._id) !== null) {
      setTimeout(() => {
        this.swiper.allowTouchMove = false;
        this.swiper.zoom.disable();

        if (this.fileModel.pano === undefined) {
          this.fileModel.pano = {};
        }

        this.hotspots.controls = [];
        if (this.fileModel.pano.hotspots) {
          this.fileModel.pano.hotspots.forEach((hotspot, index) => {
            this.hotspots.push(this.formBuilder.group(hotspot));
          });
        }

        const container = document.getElementById(
          "id-" + this.fileModel._id
        ) as HTMLDivElement;
        //this.krpanoService.panoDivAdd(container);
        this.krpanoService.divAdd(container);
        this.krpanoService.loadPano(
          this.fileModel,
          false,
          this.permissionsService.permissions$.value?.canUpdate.files,
          this.poisTag
        );
        this.isPanoLoaded = true;
      }, 0);
    } else {
      if (!this.fileModel.isFolder)
      { setTimeout(() => {
         this.panoLoad();
        }, 250);
      }
    }
  }

  panoUpdate(): void {
    const fileUpdates = this.panoFileUpdates();
    this.filesService
      .updateOne(fileUpdates)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        () => {
          this.filesService.updateItem(this.fileModel);
        },
        (error) => {
          this.dialogService.showDialog(
            "FILE.SAVING_FAILED",
            error.status,
            error.url,
            error.error
          );
        }
      );
  }

  panoViewLookTo(): void {
    this.krpanoService.lookTo(
      this.fileModel.pano.view ? this.fileModel.pano.view.hlookat : 0,
      this.fileModel.pano.view ? this.fileModel.pano.view.vlookat : 0
    );
  }

  panoViewSet(): void {
    const panoView = this.krpanoService.viewGet();
    this.panoView.value.fov = Number(panoView.fov) as number;
    this.panoView.value.h = Number(panoView.ath) as number;
    this.panoView.value.v = Number(panoView.atv) as number;

    this.panoView.markAsDirty();
  }

  removeFile(): void {
    this.translate
      .get([
        this.selectedCount > 1 ? "FILES.DELETE" : "FILE.DELETE",
        "ARE_YOU_SURE_YOU_WANT_TO_CONTINUE",
      ])
      .subscribe((translations) => {
        const dialogRef = this.dialogService.showDialog(
          null,
          null,
          this.selectedCount > 1
            ? translations["FILES.DELETE"]
            : translations["FILE.DELETE"],
          translations["ARE_YOU_SURE_YOU_WANT_TO_CONTINUE"],
          true
        ) as Observable<boolean>;

        dialogRef.subscribe((confirm) => {
          if (confirm) {
            this.fetchingChange$.next(true);

            if (this.selectedCount === 1) {
              this.filesService
                .deleteOne(String(this.fileModel._id))
                .pipe(takeUntil(this.ngDestroy$))
                .subscribe(
                  () => {
                    setTimeout(() => {
                      this.closeSidenavDetail();
                    }, 1);

                    this.filesService.removeItems([String(this.fileModel._id)]);

                    // Navigate one folder up if current folder is deleted
                    if (
                      this.foldersService.activeFolderID$.value ===
                      String(this.fileModel._id)
                    ) {
                      this.router.navigate(
                        [
                          {
                            outlets: {
                              primary: [
                                "files",
                                this.fileModel.folderID ||
                                  this.foldersService._myFiles._id,
                              ],
                              detail: this.fileModel.folderID
                                ? ["file", this.fileModel.folderID]
                                : null,
                            },
                          },
                        ],
                        { queryParamsHandling: "merge" }
                      );

                      this.foldersService.refresh();
                    } else {
                      this.closeSidenavDetail();
                    }
                  },
                  (error) => {
                    this.fetchingChange$.next(false);
                    this.dialogService.showDialog(
                      "FILE.DELETE",
                      error.status,
                      error.url,
                      error.error
                    );
                  }
                );
            } else {
              const filter = {
                _id: {
                  $in: String(this.fileModel._id)
                    .split(",")
                    .map((_id) => ({ $oid: _id })),
                },
              };
              this.filesService
                .deleteMany(filter)
                .pipe(takeUntil(this.ngDestroy$))
                .subscribe(
                  () => {
                    this.fetchingChange$.next(false);
                    this.filesService.refreshed.emit("refreshed");
                    this.closeSidenavDetail();
                  },
                  (error) => {
                    this.fetchingChange$.next(false);
                    this.dialogService.showDialog(
                      "FILES.DELETE_FAILED",
                      error.status,
                      error.url,
                      error.error
                    );
                  }
                );
            }
          }
        });
      });
  }

  setFile(): void {
    if (this.fileModel) {
      // The reset method has an optional state value so you can reset the flags and the control values at the same time.
      // Internally, reset passes the argument to setValue
      let customXML: string;
      let littlePlanet = false;
      let title: string;
      if (this.fileModel.pano as string === "") {
        this.fileModel.pano = {};
      }
      if (this.fileModel.pano) {
        customXML = this.fileModel.pano.customXML;
        if (this.fileModel.pano.littlePlanet === undefined) {
          littlePlanet = true;
        }
        if (this.fileModel.pano.hotspots) {
          this.fileModel.pano.hotspots.map((x) => {
            if (!("tooltip" in x)) {
              x.tooltip = "";
            }
            if (!("alpha" in x)) {
              x.alpha = 1;
            }
            if (!("openURL" in x)) {
              x.openURL = "";
            }
            if (!("scale" in x)) {
              x.scale = 1;
            }

            return x;
          });
        }
        title = this.fileModel.pano.title;
      } else {
        littlePlanet = true;
      }
      if (this.selectedCount === 1) {
        this.isPublic = this.fileModel.public
      }
      this.formGroup.reset({
        inherit: this.fileModel.inherit === false ? false : true, // Default to 'inherit: true' if not explicitly set to 'inherit: false'
        name: String(this.fileModel.name),
        orderID: this.fileModel.orderID,
        pattern: this.fileModel.pattern,
        panoCustomXML: customXML,
        panoLittlePlanet: littlePlanet,
        panoTitle: title,
      });

      this.tags.controls = [];
      if (this.fileModel.tags) {
        this.fileModel.tags.forEach((value) => {
          this.tags.push(this.formBuilder.control(value));
        });
      }

      this.swiperInit();
      if (this.swiper) {
        this.swiper.allowTouchMove = true; // Enable swiper touch
        this.swiper.zoom.enable();
      }

      this.drawing = null;
      if (this.drawingArea) {
        this.drawingArea.destroy();
        this.drawingArea = null;
      }

      if(this.onMap) {
        this.currentComponent = null;
      }
      this.onMap = false;
      if (this.fileModel.tags && (this.fileModel.tags.includes('orthomosaic') || this.fileModel.tags.includes('dem')) && this.fileModel.mimeType === 'image/tiff') {
        setTimeout(() => {
          this.tabIndex = 0
        }, 500);
        
        this.onMap = true;
        this.loadMap();
      }

      if(this.onViewer) {
        this.currentComponent = null;
      }
      this.onViewer = false;
      if (this.fileModel?.tags && this.fileModel?.tags.includes('3dtiles') && this.view === 'fullscreen') {
        this.onViewer = true;
        this.viewerService.showWater$.next(false);
        this.viewer3dService.fileModel$.next(this.fileModel);
        if(this.swiper) {
          this.swiper.allowTouchMove = false;
          this.swiper.zoom.disable();
        }
        import('../../viewer/viewer3d/viewer3d.component').then(({ Viewer3dComponent }) => {
          this.currentComponent = Viewer3dComponent
        });
      }

      this.isPano = false;
      this.isPanoLoaded = false;
      const panoDiv = document.getElementById("panoDiv") as HTMLDivElement;
      if (panoDiv !== null) {
        this.krpanoService.divRemove(panoDiv);
        this.isPano = false;
      }
      if (
        this.fileModel.tags &&
        this.fileModel.tags.some((x) => x === "360_stitched") &&
        this.fileModel.width / 2 === this.fileModel.height
      ) {
        this.setSwiperConfig()
        this.panoLoad();
        this.isPano = true;
      } else {
        if (this.krpanoService.getPanoID()) {
          this.krpanoService.idUnset();
        }
      }
      if (this.reconfigureTheSwipper && this.fileModel.mimeType === 'application/pdf' ) { 
        this.setSwiperConfig() //to open pdf
        this.reconfigureTheSwipper = false;
      }

      this.comments.controls = [];
      if (this.fileModel.comments) {
        this.fileModel.comments.forEach((comment, index) => {
          if (!comment.content) {
            comment["content"] = "";
          }
          this.comments.push(this.formBuilder.group(comment));
          if (comment.geo) {
            setTimeout(() => {
              this.commentDraw(comment.geo.properties.shape, index, false);
            }, 0);
          }
        });
      }
    }
    // update fileModel to access them from different components (map)
    this.filesService.updateDisplayedFile(this.fileModel);
    this.fetchingChange$.next(false);
  }

  swiperIndexChange(index: number): void {
    if (!this.swiperSilent) {
      const id = this.filesService.data$.value[index]._id;
      const params = this.view ? { view: this.view } : {};
      this.router.navigate([{ outlets: { detail: ["file", id, params] } }], {
        queryParamsHandling: "merge",
      });
    }
  }

  swiperInit(): void {
    if (this.swiper) {
      // Define zoom.out with dynamic transitionDuration parameter
      this.swiper.zoom.out = (transitionDuration = 300) => {
        const swiper = this.swiper as any;

        const zoom = swiper.zoom as any;
        const params = swiper.params.zoom;
        const { gesture } = zoom;

        if (!gesture.$slideEl) {
          gesture.$slideEl = swiper.clickedSlide
            ? this.swiper["$"](swiper.clickedSlide)
            : swiper.slides.eq(swiper.activeIndex);
          gesture.$imageEl = gesture.$slideEl.find("img, svg, canvas");
          gesture.$imageWrapEl = gesture.$imageEl.parent(
            `.${params.containerClass}`
          );
        }
        if (!gesture.$imageEl || gesture.$imageEl.length === 0) {
          return;
        }

        zoom.scale = 1;
        zoom.currentScale = 1;
        gesture.$imageWrapEl
          .transition(transitionDuration)
          .transform("translate3d(0,0,0)");
        gesture.$imageEl
          .transition(transitionDuration)
          .transform("translate3d(0,0,0) scale(1)");
        gesture.$slideEl.removeClass(`${params.zoomedSlideClass}`);
        gesture.$slideEl = undefined;
      };

      // Remove momentum with 300ms transition causing the image to flicker
      this.swiper.zoom["onTouchEnd"] = () => {
        const zoom = this.swiper.zoom as any;
        const { gesture, image, velocity } = zoom;
        if (!gesture.$imageEl || gesture.$imageEl.length === 0) {
          return;
        }

        if (!image.isTouched || !image.isMoved) {
          image.isTouched = false;
          image.isMoved = false;

          return;
        }
        image.isTouched = false;
        image.isMoved = false;

        // Define if we need image drag
        const scaledWidth = image.width * zoom.scale;
        const scaledHeight = image.height * zoom.scale;
        image.minX = Math.min(gesture.slideWidth / 2 - scaledWidth / 2, 0);
        image.maxX = -image.minX;
        image.minY = Math.min(gesture.slideHeight / 2 - scaledHeight / 2, 0);
        image.maxY = -image.minY;
        image.currentX = Math.max(
          Math.min(image.currentX, image.maxX),
          image.minX
        );
        image.currentY = Math.max(
          Math.min(image.currentY, image.maxY),
          image.minY
        );

        gesture.$imageWrapEl
          .transition(0)
          .transform(`translate3d(${image.currentX}px, ${image.currentY}px,0)`);
      };

      this.swiperSetIndex(String(this.fileModel._id));

      (this.swiper.params.zoom as ZoomOptions).maxRatio = this.zoomMaxRatio;
      if (this.swiper.zoom.scale > 1) {
        this.swiperZoomed = false;
        (this.swiper.zoom as any).out(0);
      }

      this.swiper.on("resize", () => {
        if (this.drawingArea) {
          this.drawingArea.updateSize(this.swiper.zoom.scale);
        }
      });

      this.swiperResize();
    }
  }

  swiperResize(): void {
    if (this.swiper) {
      this.swiperSilent = true;
      this.swiper.updateSize();
      this.swiper.updateSlides();
      this.swiper.slideTo(this.swiper.activeIndex, 0, false);
      this.swiperSilent = false;
    }
  }

  swiperSetIndex(_id: string): void {
    this.swiperSilent = true; // Silence events
    if (this.swiperDirectiveRef) {
      const index = this.filesService.data$.value.findIndex(
        (file) => file._id === _id
      );

      if (index === -1) {
        //if (this.swiper.virtual.slides !== [this.fileModel]) {
          this.swiper.virtual.slides = [this.fileModel];
          this.swiper.virtual.update(true); // When array has only 1 element we need to force update the virtual array
        //}
        this.swiperDirectiveRef.setIndex(0, 0, true);
      } else {
        if (this.swiper.virtual.slides !== this.filesService.data$.value) {
          this.swiper.virtual.slides = this.filesService.data$.value;
          this.swiper.virtual.update(true); // When array has only 1 element we need to force update the virtual array
        }
        this.swiperDirectiveRef.setIndex(index, 0, true);
      }
    }
    this.swiperSilent = false;
  }

  swiperWheel(event: any): void {
    if (this.view) {
      const swiper = this.swiper as any;

      const zoom = swiper.zoom as any;
      const params = swiper.params.zoom;
      const { gesture } = zoom;

      if (!gesture.$slideEl || gesture.$slideEl.length === 0) {
        gesture.$slideEl = this.swiper["$"](event.target).closest(
          ".swiper-slide"
        );
        gesture.$imageEl = gesture.$slideEl.find("img");
        gesture.$imageWrapEl = gesture.$imageEl.parent(
          `.${params.containerClass}`
        );
      }
      if (!gesture.$imageEl || gesture.$imageEl.length === 0) {
        return;
      }

      // Prevent default scroll on the image
      if (
        event.target === gesture.$imageEl[0] ||
        event.target.closest("svg") ||
        zoom.scale > 1
      ) {
        event.preventDefault();
      } else {
        // Prevent scrolling the parent slide from wheel inertia from the image
        if (this.swiperWheeling) {
          event.preventDefault();
        }

        return;
      }

      // Detect wheeling inertia by using a 1s timeout
      clearTimeout(this.swiperWheeling);
      this.swiperWheeling = setTimeout(() => {
        this.swiperWheeling = undefined;
      }, 1000);

      let offsetX: number;
      let offsetY: number;
      let diffX: number;
      let diffY: number;
      let translateX = 0;
      let translateY = 0;
      let imageWidth: number;
      let imageHeight: number;
      let scaledWidth: number;
      let scaledHeight: number;
      let translateMinX: number;
      let translateMinY: number;
      let translateMaxX: number;
      let translateMaxY: number;
      let slideWidth: number;
      let slideHeight: number;

      if (event) {
        slideWidth = gesture.$slideEl[0].offsetWidth;
        slideHeight = gesture.$slideEl[0].offsetHeight;
        offsetX = gesture.$slideEl.offset().left;
        offsetY = gesture.$slideEl.offset().top;
        diffX = event.clientX - offsetX - slideWidth / 2;
        diffY = event.clientY - offsetY - slideHeight / 2;

        const transform = gesture.$imageWrapEl.css("transform").split(",");
        if (transform.length === 6) {
          translateX = parseFloat(transform[4]);
          translateY = parseFloat(transform[5]);
        }

        // Determine the point on where the slide is zoomed in
        const zoomTarget = {
          x: (diffX - translateX) / zoom.scale,
          y: (diffY - translateY) / zoom.scale,
        };

        // Apply zoom
        zoom.scale += event.deltaY * -0.01; // Change scale
        zoom.scale = Math.min(Math.max(1, zoom.scale), params.maxRatio); // Restrict scale
        zoom.currentScale = zoom.scale;

        if (zoom.scale > 1) {
          imageWidth = gesture.$imageEl[0].offsetWidth;
          imageHeight = gesture.$imageEl[0].offsetHeight;
          scaledWidth = imageWidth * zoom.scale;
          scaledHeight = imageHeight * zoom.scale;

          translateMinX = Math.min(
            (slideWidth - imageWidth) / 2 - scaledWidth / 2,
            0
          );
          translateMinY = Math.min(
            (slideHeight - imageHeight) / 2 - scaledHeight / 2,
            0
          );
          translateMaxX = -translateMinX;
          translateMaxY = -translateMinY;

          // Calculate x and y based on zoom
          translateX = -zoomTarget.x * zoom.scale + diffX;
          translateY = -zoomTarget.y * zoom.scale + diffY;

          if (translateX < translateMinX) {
            translateX = translateMinX;
          }
          if (translateX > translateMaxX) {
            translateX = translateMaxX;
          }

          if (translateY < translateMinY) {
            translateY = translateMinY;
          }
          if (translateY > translateMaxY) {
            translateY = translateMaxY;
          }
        } else {
          translateX = 0;
          translateY = 0;
        }
      } else {
        translateX = 0;
        translateY = 0;
      }

      // Apply scale transform
      if (zoom.scale > 1) {
        gesture.$slideEl.addClass(`${params.zoomedSlideClass}`);
      } else {
        gesture.$slideEl.removeClass(`${params.zoomedSlideClass}`);
      }
      gesture.$imageWrapEl
        .transition(0)
        .transform(`translate3d(${translateX}px, ${translateY}px,0)`);
      gesture.$imageEl
        .transition(0)
        .transform(`translate3d(0,0,0) scale(${zoom.scale})`);

      if (this.drawingArea) {
        this.drawingArea.updateSize(zoom.scale);
      }
    }
  }

  swiperZoomChange(event: Array<any>): void {
    // 0: scale, 1: imageEl, 2: slideEl
    const scale = event[0];
    const imageEl = event[1];

    if (scale > 1) {
      this.swiperZoomed = true;

      // Fix blurry image on mobile
      // https://github.com/nolimits4web/swiper/issues/45
      if (this.isMobile) {
        clearTimeout(this.swiperZooming);
        this.swiperZooming = setTimeout(() => {
          this.renderer.setStyle(
            imageEl,
            "transform",
            `translate(0,0) scale(${scale})`
          );
        }, 300);
      }
    }
  }

  tabChanged(): void {
    if (this.matTabGroup.selectedIndex === 2) {
      // TODO: show active element
      /*const element = this.matComments.last.element.nativeElement;
      element.getElementsByClassName('mat-input-element')[0].focus();
      element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });*/
    }
  }

  tagsAdd(event: MatChipInputEvent): void {
    // Add tag only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    if (!this.tagsAutocomplete.isOpen && event.value) {
      const value = event.value.trim();

      // Add new tag
      if (value && !this.tags.controls.some((tag) => tag.value === value)) {
        this.tags.push(this.formBuilder.control(value));
        this.tags.markAsDirty();
        // Reset the input value
        if (event && event.input) {
          event.input.value = "";
        }
      }
    }
  }

  tagsRemove(index: number): void {
    if (index >= 0) {
      this.tags.removeAt(index);
      this.tags.markAsDirty();
    }
  }

  tagsSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;

    // Add new tag
    if (value && !this.tags.controls.some((tag) => tag.value === value)) {
      this.tags.push(this.formBuilder.control(value));
      this.tags.markAsDirty();
    }
    // Reset the input value
    this.tagsInput.setValue("", { onlySelf: false, emitEvent: false });
  }

  toggleInherit(): void {
    this.inherit.setValue(!this.inherit.value, {
      onlySelf: false,
      emitEvent: false,
    });
    this.inherit.markAsDirty();
  }

  updateFile(): void {
    const fileUpdates = this.getFileUpdates(true);

    if (this.selectedCount === 1) {
      // When the file name was changed
      const fileNameChanged = fileUpdates.name && !this.fileModel.isFolder;
      const noExtension =
        fileNameChanged && !/.\.[^\.]+$/.test(fileUpdates.name);
      const bigFile =
        fileNameChanged && this.fileModel.size > 1024 * 1024 * 1024 * 5;
      // Check if file has no extension or if bigFile > 5 GiB
      if (noExtension || bigFile) {
        this.translate
          .get([
            "FILE.NAME_WITHOUT_EXTENSION",
            "FILE.RENAMING_BIG_FILES_CAN_TAKE_A_LONG_TIME",
            "ARE_YOU_SURE_YOU_WANT_TO_CONTINUE",
          ])
          .subscribe((translations) => {
            const dialogRef = this.dialogService.showDialog(
              null,
              null,
              (noExtension ? translations["FILE.NAME_WITHOUT_EXTENSION"] : "") +
                (noExtension && bigFile ? "\n" : "") +
                (bigFile
                  ? translations["FILE.RENAMING_BIG_FILES_CAN_TAKE_A_LONG_TIME"]
                  : ""),
              translations["ARE_YOU_SURE_YOU_WANT_TO_CONTINUE"],
              true
            ) as Observable<boolean>;

            dialogRef.subscribe((confirm) => {
              if (confirm) {
                this.updateOne(fileUpdates);
              }
            });
          });
      } else {
        this.updateOne(fileUpdates);
      }
    } else {
      this.updateMany(fileUpdates);
    }
  }

  updateMany(fileUpdates: FileModel): void {
    this.fetchingChange$.next(true);

    this.filesService
      .updateMany(fileUpdates)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        () => {
          this.filesService.refreshed.emit("refreshed");
          this.setFile(); // update form models & mark form as pristine
          this.closeSidenavDetail();
        },
        (error) => {
          this.fetchingChange$.next(false);
          this.dialogService.showDialog(
            "FILE.SAVING_FAILED",
            error.status,
            error.url,
            error.error
          );
        }
      );
  }

  updateLive(_id: string): void {
    if (_id) {
      this.filesService
        .findOne(_id)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((response) => {
          if (response) {
            let tmpFileUpdate = false;
            // check if dem value was changed
            if (
              response.data.tags &&
              response.data.tags.indexOf("dem") !== -1
            ) {
              if (
                this.fileModel.dem?.max !== response.data.dem?.max ||
                this.fileModel.dem?.min !== response.data.dem?.min
              ) {
                tmpFileUpdate = true;
              }
            }
            // check if 360_stitched was added or removed to tags
            if (
              response.data.tags &&
              response.data.tags.indexOf("360_stitched") !== -1
            ) {
              if (
                this.fileModel.tags === undefined ||
                this.fileModel.tags.indexOf("360_stitched") === -1
              ) {
                tmpFileUpdate = true;
              }
            } else {
              if (
                this.fileModel.tags &&
                this.fileModel.tags.indexOf("360_stitched") !== -1
              ) {
                tmpFileUpdate = true;
              }
            }
            if (
              this.fileModel.pano?.compass !== response.data.pano?.compass ||
              this.fileModel.pano?.compassCorrection !== response.data.pano?.compassCorrection ||
              this.fileModel.pano?.customXML !== response.data.pano?.customXML ||
              this.fileModel.pano?.title !== response.data.pano?.title
            ) {
              tmpFileUpdate = true;
            }

            this.fileModel = response.data;
            /*
          if (this.fileModel.pano?.title !== response.data.pano?.title) { this.fileModel.pano = response.data.name; }
          if (this.fileModel.name !== response.data.name) { this.fileModel.name = response.data.name; }
          if (this.fileModel.tags !== response.data.tags) { this.fileModel.tags = response.data.tags; } */

            //reload File
            if (tmpFileUpdate) {
              this.setFile();
            }
          } else {
            this.closeSidenavDetail();
          }
        });
    }
  }

  updateOne(fileUpdates: FileModel): void {
    this.fetchingChange$.next(true);
    this.filesService
      .updateOne(fileUpdates)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        () => {
          // update data models
          this.fileModel = {
            ...this.fileModel,
            ...this.getFileUpdates(false),
            ...{ modified: new Date() },
          };
          this.filesService.updateItem(this.fileModel);

          if (fileUpdates.name && !this.fileModel.isFolder) {
            this.getFile(String(this.fileModel._id));
          } else {
            this.setFile(); // update form models & mark form as pristine
          }
        },
        (error) => {
          this.fetchingChange$.next(false);
          this.dialogService.showDialog(
            "FILE.SAVING_FAILED",
            error.status,
            error.url,
            error.error
          );
        }
      );
  }
}
