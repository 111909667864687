import { Component, Inject } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FileModel } from 'src/app/pages/files/file/file.model';
import { statuses, Task, urgencies } from 'src/app/pages/tasks/task/task.model';
import { OLLayerService } from '../openlayers/layer.service';
import { OLMapsService } from '../openlayers/maps.service';
import { Annotation } from 'src/app/pages/files/file/sidebar/annotations/annotation.model';
import { AnnotationsOverviewDialogComponent } from '../annotations-overview-dialog/annotations-overview-dialog.component';
import { ThemeService } from '../theme/theme.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { OrdersService } from '../orders/orders.service';
import { TasksService } from 'src/app/pages/tasks/tasks.service';
import { DialogService } from '../dialog/dialog.service';
import { customEmailValidator } from '../helpers/data-helpers';

@Component({
  selector: 'app-task-dialog',
  templateUrl: './task-dialog.component.html',
  styleUrls: ['./task-dialog.component.scss']
})
export class TaskDialogComponent {
  // Getters for each form control
  get title() { return this.taskForm.get('title'); }
  get assigneeEmail() { return this.taskForm.get('assigneeEmail'); }
  get urgency() { return this.taskForm.get('urgency'); }
  get status() { return this.taskForm.get('status'); }
  get deadline() { return this.taskForm.get('deadline'); }
  get description() { return this.taskForm.get('description'); }

  loadingObj = {};
  loading: boolean = false;
  theme: string;
  ngDestroy$ = new Subject();
  task: any = {}

  taskForm: FormGroup;
  urgencies = urgencies
  statuses = statuses
  customAnnotations = []
  annotations: string[] = [];
  showCustomAnnotations: boolean = false;

  constructor(
      private dialogRef: MatDialogRef<TaskDialogComponent>,
      private fb: FormBuilder,
      @Inject(MAT_DIALOG_DATA) public data,
      private oLLayerService: OLLayerService,
      private oLMapsService: OLMapsService,
      private readonly dialog: MatDialog,
      private themeService: ThemeService,
      private ordersService: OrdersService,
      private tasksService: TasksService,
      private dialogService: DialogService
  ) {

    this.taskForm = this.fb.group({
      title: ['', Validators.required],
      assigneeEmail: ['', [Validators.required, customEmailValidator]],
      urgency: ['', Validators.required],
      status: ['', Validators.required],
      deadline: ['', Validators.required],
      description: ['']
    });

    if (this.data.action == "create") {
      this.task = this.data.task
      this.populateForm(this.task) 
    } else if (this.data.action == "update") {
        this.tasksService.findOne(this.data.task?._id).pipe(takeUntil(this.ngDestroy$))
        .subscribe ((response)=> {
          this.task = response
          this.data.folderId = this.task.linkedResources[0].resource.file.folderID
          this.populateForm(this.task)
          this.ordersService.findManyOrders({"legacyId":this.task.linkedResources[0].resource.file.orderID},null).pipe(takeUntil(this.ngDestroy$))
          .subscribe((response: any) => {
            if (response.count === 1) {
              this.task.orderId = response.orders[0]._id
            }
          })
        })
    }
    
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });
  }

  populateForm(task): void {  
    this.taskForm.patchValue({
      title: this.task?.title ?? '',
      assigneeEmail: this.task?.assigneeEmail ?? '',
      urgency: this.task?.urgency ?? '',
      status: this.task?.status ?? '',
      deadline: this.task?.deadline ? this.setMidnightTime(new Date(task.deadline)) : this.setMidnightTime(new Date()),
      description: this.task?.description ?? ''
    });
    if (this.task?.linkedResources && Array.isArray(this.task.linkedResources)) {
      this.taskForm.setControl('linkedResources', this.fb.array(this.task.linkedResources));
    } else {
      this.taskForm.setControl('linkedResources', this.fb.array([]));
    }
    if (this.taskForm.get('linkedResources')?.value) {
      this.updateAnnotationsCards()
    }
  }

  private setMidnightTime(date: Date): string {
    date.setUTCHours(0, 0, 0, 0);
    return date.toISOString();
  }

  toggleAnnotationsSelection() {
    const dialogRef = this.dialog.open<AnnotationsOverviewDialogComponent>(AnnotationsOverviewDialogComponent, {
      data: {
        currentLang: this.data.currentLang,
        folderID: this.data.folderId,
        addMoreAnnotationsToTask: true,
        alreadySelectedResources: this.task?.linkedResources?.map(resource => resource.resource._id) || []
      },
      width: '85vw',
      height: '85vh',
      panelClass: 'all-annotations',
      maxWidth: '1300px',
      maxHeight: '1300px',
      closeOnNavigation: true
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        if (result.annotationsToAdd) {
          // Handle the case where annotations are added
          this.task.linkedResources = result.annotationsToAdd
          this.updateAnnotationsCards()
        } else if (result.createdTasks) {
          // TODO:Handle the case where tasks are created
          // TODO:Implement your logic for handling created tasks here
        }
      }
    });
  }

  deleteResource(resourceToDelete: any): void {
    const index = this.task.linkedResources.findIndex(resource => resource.resource._id === resourceToDelete.resource._id);
    if (index > -1) {
      this.task.linkedResources.splice(index, 1);
    }
  }


  updateAnnotationsCards() {
    this.task.linkedResources?.forEach( resource => {
      if (resource.type === 'annotation') {
        setTimeout(() => {
          const mapDiv = document.querySelector("._" + resource.resource?._id);
          this.loadMap(mapDiv, (resource.resource as Annotation).file, [resource.resource], resource.resource._id.toString());
        }, 1000);
      }
    });
  }
  
  loadMap(mapDiv: any, fileModel: FileModel, annotations: any, id: string): void {
    let image = fileModel.webViewLink
    const map = this.oLMapsService.renderImage({
      extent: [0, 0, fileModel.width, fileModel.height],
      target: mapDiv,
      image: image,
      imagePreview: fileModel.thumbnailLink,
      interactions: false,
      annotations: annotations,
      labeling: false
    });
    map.on('loadstart', () => {
      this.loadingObj[id] = true;
    });
    map.on('loadend', () => {
      this.loadingObj[id] = false;
      this.checkLoadingObj();
    });
    map.getView().fit(this.oLLayerService.getAnnotationExtent(annotations[0]), {padding:[15, 15, 15, 15]});
  }

  checkLoadingObj(): void {
    const values = Object.values(this.loadingObj);
    if (values.every(v => v === false)) {
      this.loading = false;
    }
  }

  updateTask() {
    const updatedLinkedResources = this.task.linkedResources.map(linkedResource => ({
      ...linkedResource,
      resource: linkedResource.resource._id,
    }));
    const { creatorId, orderId } = this.task;
    const payload: Task = {
      linkedResources: updatedLinkedResources,
      title: this.title?.value,
      assigneeEmail: this.assigneeEmail?.value,
      urgency: this.urgency?.value,
      status: this.status?.value,
      deadline: this.setMidnightTime(new Date(this.deadline?.value)),
      description: this.description?.value,
      creatorId,
      orderId
    };
    this.tasksService.updateOne(this.data.task._id, payload).subscribe(
      this.onTaskUpdateSuccess.bind(this),
      this.onTaskUpdateError.bind(this)
    );
  }

  onTaskUpdateSuccess(response: any) {
    this.dialogRef.close(response);
  }

  onTaskUpdateError(error: any) {
    console.error('Error updating task:', error);
    this.dialogService.showDialog('Failed to Update Task', null, 'error', null, null, true);
  }

  createTask() {
    const updatedLinkedResources = this.task.linkedResources.map(linkedResource => ({
      ...linkedResource,
      resource: linkedResource.resource._id,
    }));
    const orderId = this.ordersService.activeOrder$.value?._id;
    const payload: Task = {
      linkedResources: updatedLinkedResources,
      orderId,
      title: this.title?.value,
      assigneeEmail: this.assigneeEmail?.value,
      urgency: this.urgency?.value,
      status: this.status?.value,
      deadline: this.setMidnightTime(new Date(this.deadline?.value)),
      description: this.description?.value,
    };
    this.tasksService.insertOne(payload).subscribe(
      this.onTaskCreateSuccess.bind(this),
      this.onTaskCreateError.bind(this)
    );
  }

  onTaskCreateSuccess(data: any) {
    this.dialogRef.close(data);
  }

  onTaskCreateError(error: any) {
    this.dialogService.showDialog('Failed to Create Task', null, 'error', null, null, true);
  }

  loadCustomAnnotations(): string[] {
    return ['Annotation1', 'Annotation2', 'Annotation3']; 
  }

  markAllAsTouched(): void {
    this.taskForm.markAllAsTouched();
  }

  onCancel(): void {
    this.dialogRef.close();
  }

  getErrorMessage(field: string): string {
    const control = this.taskForm.get(field);
    if (control?.hasError('required')) {
      return 'This field is required';
    }
    if (control?.hasError('email')) {
      return 'Not a valid email';
    }
    return '';
  }

  isInvalidAndTouched(field: string): boolean {
    const control = this.taskForm.get(field);
    return control?.invalid && control?.touched;
  }
}
