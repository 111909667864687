import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Portfolio } from "../portfolio/portfolio.model";
import { PortfoliosService } from "src/app/pages/portfolios/portfolios.service";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { Subject } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  takeUntil,
} from "rxjs/operators";
import { UsersService } from "src/app/pages/users/users.service";

@Component({
  selector: "app-add-modify-portfolio-dialog",
  templateUrl: "./add-modify-portfolio-dialog.component.html",
  styleUrls: ["./add-modify-portfolio-dialog.component.scss"],
})
export class AddModifyPortfolioDialogComponent implements OnInit, OnDestroy {
  public form: FormGroup;
  public usersAutocompleteOptions: Array<any> = [];
  private ngDestroy$ = new Subject();
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  // mockData = [
  //   {
  //       "_id": "5c508feee7798974068149a3",
  //       "created": "2017-09-09T15:15:19Z",
  //       "email": "sadete.ukshini@wuestenrot.de",
  //       "modified": "2023-03-03T10:02:15.381Z",
  //       "name": "Anis",
  //       "surname": "Ben GHANEM",
  //       "password": "$2y$13$uOesyzYWnEM7/zY58.s.7uaC2EbD/Z4zirFOsYG8A1oCYsun5VSYe",
  //       "phone": "+49-176-64668989",
  //       "accountType": "client",
  //       "language": "de",
  //       "avatarLink": "https://fairfleet-dev.s3-accelerate.amazonaws.com/f/5c827e0fe779894905eb83a4/DJI_0813.JPG?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXMRQCKXB25IUEC4L%2F20250121%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20250121T210155Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=0727143eb8a585212238d051c8e95c0c03ea93d375af5ef50cfd4c188d39d6b3"
  //   },
  //   {
  //       "_id": "5c508feee779897406814d36",
  //       "active": true,
  //       "created": "2017-02-04T09:37:58Z",
  //       "email": "dgrohe@gmx.de",
  //       "modified": "2024-05-10T13:39:11.922Z",
  //       "name": "Daniel",
  //       "surname": "Grohe",
  //       "password": "$2a$13$jo6OWdJdNceX1EuybCvJVeWgibkSDHghoA/wtMsHu9vHt7irTIlVO",
  //       "phone": "01605360256",
  //       "accountType": "pilot",
  //       "language": "de",
  //       "avatarLink": "https://fairfleet-dev.s3-accelerate.amazonaws.com/f/5d725fa09c3923e71336d9d4/firstorder-white-black.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXMRQCKXB25IUEC4L%2F20250121%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20250121T210155Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=981f9c9ec461eec9fc72fb503970505d3f1aeb629feb1cdc7faa40afbb27e89e"
  //   },
  //   {
  //       "_id": "5c508feee779897406814669",
  //       "active": true,
  //       "created": "2016-10-17T20:59:16Z",
  //       "email": "kingofryr@aol.com",
  //       "modified": "2019-06-10T20:05:48.706Z",
  //       "name": "",
  //       "surname": "",
  //       "password": "$2y$13$N9DSK8/nlPYicMHY.8YnJe3ixxYJAuUzvcCW/Cif72o84pj8KAf5W",
  //       "accountType": "client",
  //       "language": "en",
  //       "avatarLink": "https://fairfleet-dev.s3-accelerate.amazonaws.com/f/5cfeb80a9472064f97c15886/32DF181A-9B7A-44BA-AC99-03B83B98853C.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAXMRQCKXB25IUEC4L%2F20250121%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20250121T210155Z&X-Amz-Expires=86400&X-Amz-SignedHeaders=host&X-Amz-Signature=7153b6da76e13246a7330f1f960f63e932f2b99cc86bcf43e734303cdd28f25c"
  //   },
  //   {
  //       "_id": "5c508feee77989740681468d",
  //       "active": true,
  //       "created": "2016-11-17T11:25:12Z",
  //       "email": "rolandkueppers@web.de",
  //       "modified": "2017-01-11T10:46:51Z",
  //       "name": "Roland",
  //       "surname": "Küppers",
  //       "password": "$2y$13$EcnJm6Fd98W0IF8iT04pj.FD8gYEBZQn32Zj7dZZcqmuJR2Ltt63G",
  //       "accountType": "client",
  //       "language": "de"
  //   },
  //   {
  //       "_id": "5c508feee7798974068147fc",
  //       "active": true,
  //       "created": "2017-03-30T13:51:59Z",
  //       "email": "anke.betzler@eu.jll.com",
  //       "modified": "2024-05-10T13:52:02.52Z",
  //       "name": "Anke",
  //       "surname": "Betzler",
  //       "organization": "Jones Lang LaSalle SE",
  //       "password": "$2a$13$uO1bFu83fzB6B95BU/6BxOqVgfmG1S6puJc3YK06doZ3LdsXnH6d2",
  //       "phone": "+49 69 2003 1109",
  //       "accountType": "client",
  //       "language": "de"
  //   },
  //   {
  //       "_id": "5c508feee779897406814929",
  //       "active": true,
  //       "created": "2017-08-08T15:34:11Z",
  //       "email": "catalin@fairfleet360.com",
  //       "modified": "2017-10-24T15:04:36Z",
  //       "name": "catalin",
  //       "surname": "avram",
  //       "password": "$2y$13$tqIoGZ5fYmdB8FDC.pEYWuKejpUCHPVR.YOekB7tV1ngZElmxXwJq",
  //       "accountType": "pilot",
  //       "language": "de"
  //   },
  //   {
  //       "_id": "5c508feee779897406814d3d",
  //       "active": true,
  //       "created": "2017-02-10T14:23:27Z",
  //       "email": "trickymcgee@mailinator.com",
  //       "modified": "2017-02-15T11:50:36Z",
  //       "name": "test",
  //       "surname": "test",
  //       "password": "$2y$13$HjfIzRwLDuaBmHBZA0LOhuaEVfOO0FrAqVNUXgf5aFqqBd.CcBSHO",
  //       "phone": "1234",
  //       "accountType": "pilot",
  //       "language": "de"
  //   },
  //   {
  //       "_id": "5c508feee779897406814d8f",
  //       "active": true,
  //       "created": "2017-03-11T20:47:01Z",
  //       "email": "info@inprex-immo.de",
  //       "modified": "2017-03-20T13:42:03Z",
  //       "name": "Andreas",
  //       "surname": "Grossmann",
  //       "organization": "INPREX-IMMO GmbH",
  //       "password": "$2y$13$dgLEfUC8lvM1dwyDOoqhE.pmzf8hCMcELZ5LBmK87dqg.fACDiah6",
  //       "phone": "0176-47015985",
  //       "accountType": "pilot",
  //       "language": "de"
  //   },
  //   {
  //       "_id": "5c508feee779897406814e9a",
  //       "active": true,
  //       "created": "2017-05-24T08:46:41Z",
  //       "email": "foto@henningkaiser.de",
  //       "modified": "2017-05-24T09:11:34Z",
  //       "name": "Kaiser",
  //       "surname": "Henning",
  //       "password": "$2y$13$XTjcOoTE9VzXvcBoAiBk3.EBnxAt.DnKVt3DjNkorNc4YmCbqlsp6",
  //       "phone": "01718376491",
  //       "accountType": "pilot",
  //       "language": "de"
  //   },
  //   {
  //       "_id": "5c508feee779897406815026",
  //       "active": true,
  //       "created": "2017-11-05T17:46:01Z",
  //       "email": "wgordon60@yahoo.com",
  //       "modified": "2017-11-05T19:04:32Z",
  //       "name": "William",
  //       "surname": "Gordon",
  //       "password": "$2y$13$0f84VJ2YvmTBD3d2neXDyOFOgSziZx8Mt3OqC.6XIOCc0IAMjHdcy",
  //       "phone": "4157173307",
  //       "accountType": "pilot",
  //       "language": "en"
  //   }
  // ]

  constructor(
    private readonly modal: MatDialogRef<AddModifyPortfolioDialogComponent>,
    private readonly fb: FormBuilder,
    private portfoliosService: PortfoliosService,
    private usersService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: { portfolio?: Portfolio; isAdmin: boolean }
  ) {}

  public ngOnInit(): void {
    this.buildForm();
    if (this.data.isAdmin) {
      this.setupUserAutocomplete();
    }
  }

  public ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public addUserFromAutoComplete(selectedUser: any): void {
    if (selectedUser) {
      this.form.get('userEmail').setValue(selectedUser.email);
      if (!this.form.contains('userId')) {
        this.form.addControl('userId', this.fb.control(''));
      }
      this.form.get('userId').setValue(selectedUser._id);
    }
  }

  public handleKeydown(event: KeyboardEvent): void {
    if (this.data.isAdmin) {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    }
  }

  public close(): void {
    this.modal.close();
  }

  public submit(): void {
    const formValue = this.form.value;
  
    if (this.data.portfolio) {
      this.data.portfolio.name = formValue.name;
      this.data.portfolio.userId = formValue.userId; // Attach the userId to the portfolio object
      this.portfoliosService
        .updateOne(this.data.portfolio)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((response) => {
          this.modal.close(response.data);
        });
    } else {
      const portfolio = { name: formValue.name, userId: formValue.userId }
      this.portfoliosService
        .insertOne(portfolio)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe((response) => {
          this.modal.close(response.data);
        });
    }
  }

  private buildForm(): void {
    this.form = this.fb.group({
      name: [this.data.portfolio?.name || "", Validators.required],
    });

    if (this.data.isAdmin) {
      this.form.addControl("userEmail", this.fb.control("", Validators.required));
    }
  }

  private setupUserAutocomplete(): void {
    this.form.get("userEmail").valueChanges
      .pipe(
        debounceTime(300),
        distinctUntilChanged(),
        switchMap((value) => {
          const pageSize = 10;
          const cursor = 0;
          const filter = { query: value };
          const fields = { email: 1, name: 1 };
          return this.usersService.findMany(filter, null, cursor, pageSize, fields);
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe((users) => {
        this.usersAutocompleteOptions = users.data;
        //this.usersAutocompleteOptions = this.mockData;
      });
  }
}
