import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  NgZone,
  Renderer2,
  ViewChild
} from '@angular/core';
import { MatAutocompleteSelectedEvent, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { Platform } from '@angular/cdk/platform';
import { BehaviorSubject,Subject} from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { Column } from './../interfaces/column.interface';
import { isBentleyEnabled, isImogentEnabled, isRaptorMapsEnabled } from './../../config';
import { getPathValue } from './../helpers/data-helpers';
import { FoldersService } from './../folders/folders.service';
import { HeaderService } from './header.service';
import { Login } from './../../pages/login/login.model';
import { LoginStateService } from './../../pages/login/login-state.service';
import { SidenavDetailService } from './../sidenav/sidenav-detail/sidenav-detail.service';
import { SidenavNavigationService } from './../sidenav/sidenav-navigation/sidenav-navigation.service';
import {AccountType, User, UserRole} from '../../pages/users/models/user.model';
import { environment } from './../../../environments/environment.dev';
import {statusGroups, OrderGroup, OrdersService} from "../orders/orders.service";
import {PilotsService} from "../pilots/pilots.service";
import {FileModel} from "../../pages/files/file/file.model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {UploadService} from "../upload/upload.service";
import {TranslateService} from "@ngx-translate/core";
import {FilesService} from "../../pages/files/files.service";
import {DialogService} from "../dialog/dialog.service";
import { MatDialog } from '@angular/material/dialog';
import { AddModifyCollaboratorsDialogComponent } from '../add-modify-collaborators-dialog/add-modify-collaborators-dialog.component';
import { LazyLoadService } from "../helpers/lazy-load.service";
import { OLMapsService } from '../openlayers/maps.service';
import { PermissionsService } from '../permissions/permissions.service';

export class Breadcrumb {
  id?: string;
  label: string;
  link?: any;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements AfterViewInit {
  breadcrumbs: Array<Breadcrumb> = new Array<Breadcrumb>();
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() dir: string;
  isMobile = this.platform.ANDROID || this.platform.IOS;
  isSmall = false;
  isXSmall = false;
  isInspection = false;
  login: Login;
  @ViewChild('searchAutocompleteTrigger')
  searchAutocompleteTrigger: MatAutocompleteTrigger;
  @ViewChild('searchInput') searchInput: ElementRef;
  searchOpened = false;
  searchString: string;
  userRole = UserRole;
  accountType = AccountType;
  user?: User;
  relatedTarget: HTMLElement; // Safari fix for https://bugs.webkit.org/show_bug.cgi?id=66547#c10
  activeFolder: string;
  hasSubFolder: boolean;
  private googleMapsURL = `https://maps.googleapis.com/maps/api/js?key=${environment.googleMapsAPIKey}&libraries=drawing,places`;
  private placesAutocomplete: google.maps.places.Autocomplete;
  location: string=""
  private isOutCrumbs = false;
  private isSiteURL = false;

  private ngDestroy$ = new Subject();
  constructor(
      public headerService: HeaderService,
      public sidenavNavigationService: SidenavNavigationService,
      public ordersService: OrdersService,
      public permissionsService: PermissionsService,
      public pilots: PilotsService,
      public filesService: FilesService,
      private dialog: MatDialog,
      private ngZone: NgZone,
      private lazyLoadService: LazyLoadService,
      private breakpointObserver: BreakpointObserver,
      private foldersService: FoldersService,
      private loginStateService: LoginStateService,
      private platform: Platform,
      private route: ActivatedRoute,
      private router: Router,
      private sidenavDetailService: SidenavDetailService,
      private renderer: Renderer2,
      private snackBar: MatSnackBar,
      private uploadService: UploadService,
      private translate: TranslateService,
      private dialogService: DialogService,
      private olMapsService: OLMapsService,
  ) {
    // this observable is used to update the flag hasSubFolder to display "Match Data" option or not
    this.filesService.hasSubFolder$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((val)=>{
      if (val) {
        this.hasSubFolder= true;
      } else{
        this.hasSubFolder= false;
      }
    })

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ]).subscribe(() => {
      this.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
      this.isXSmall = !this.isSmall && this.breakpointObserver.isMatched(Breakpoints.XSmall);
    });

    this.loginStateService.login$.subscribe(login => {
      if (login) {
        Promise.resolve(undefined).then(() => {
          this.login = login;
        });
      }
    });

    this.loginStateService.loggedUser$.subscribe(user => {
      this.user = user;
    });

    this.router.events.subscribe(data => {      
      if (data instanceof NavigationEnd) {
        const firstChild = this.route.snapshot.firstChild;
        if(firstChild.routeConfig.path.includes('inspection')) {
          this.isInspection = true;
        } else {
          this.isInspection = false;
        }

        if( firstChild.routeConfig.path.includes('inspection3d') ) {
          this.location = 'inspection3d';
        } else if( firstChild.routeConfig.path.includes('inspection') ) {
          this.location = 'inspection';
        } else if( firstChild.routeConfig.path.includes('files') ) {
          this.location = 'files';
        } else if( firstChild.routeConfig.path.includes('maps') ) {
          this.location = 'maps';
          this.ordersService.activeOrder$.next(null)
          this.permissionsService.resetPermissions()
        }  
        
        if( firstChild.routeConfig.path.includes('users') ) {
          this.ordersService.activeOrder$.next(null)
          this.permissionsService.resetPermissions()
        } 
        
        if( firstChild.routeConfig.path.includes('portfolio') ) {
          this.isSiteURL = true
        } else { 
          this.isSiteURL = false 
        }
        this.olMapsService.location$.next(this.location)
        if ( firstChild ) {
          const title = this.headerService.title$.value || this.route.snapshot.firstChild.data.title;
          if (title) {
            this.breadcrumbs = [{ label: title }];
          }
        }

        const secondChild = this.route.snapshot.children[1];
        // open detail view if detail outlet is set
        if (secondChild && secondChild.outlet === 'detail') {
          this.sidenavDetailService.open();
        } else {
          this.sidenavDetailService.close();
        }
      }
    });

    this.headerService.title$
    .subscribe((title: string) => {
      this.breadcrumbs = [{ label: title }];
    });

    this.headerService.toggleSearch
    .subscribe((value: string) => {
      if (!value && this.searchAutocompleteTrigger) {
        this.searchAutocompleteTrigger.closePanel();
      }
      this.searchString = value || '';
      this.searchOpened = !!value;
      if (this.location=="maps") {
        if (value) {
          setTimeout(() => {
            this.loadAutoComplete();
          }, 0)
        }
      }
    });

    this.headerService.breadCrumbs$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(crumbs => {
        if (crumbs.length > 2) {
          this.breadcrumbs[0] = crumbs[0]
          this.breadcrumbs[1] = crumbs[1]
        } else if (crumbs.length > 0) {
          this.breadcrumbs = crumbs;
        }
    })

    this.foldersService.activeFolder$
    .subscribe(folder => {
      Promise.resolve().then(() => {
        if (folder) {
          if (folder._id === "my-files") { // force collaboration button to disappear
            this.ordersService.activeOrder$.next(null) 
            this.permissionsService.resetPermissions()
          } 
          this.breadcrumbs = this.isOutCrumbs ?  this.breadcrumbs : [];
          this.activeFolder=folder._id.toString();

          if (String(folder._id) !== 'my-files' && !this.isOutCrumbs) {
            this.breadcrumbs.push({
              id: String(folder._id),
              label: this.foldersService._myFiles.name,
              link: ['', { outlets: { primary: ['files', this.foldersService._myFiles._id] }}]
            });
          }

          if (folder.parents) { // Parents
            folder.parents.forEach(parent => {
              this.breadcrumbs.push({
                id: String(parent._id),
                label: parent.name,
                link: ['', { outlets: { primary: ['files', String(parent._id)] }}]
              });
            });
          }

          this.breadcrumbs.push({ // Active, last node
            id: String(folder._id),
            label: folder.name,
            link: ['', { outlets: { primary: ['files', String(folder._id)] }}]
          });

          this.isOutCrumbs = false;
        }
      });
    });
  }

  private loadAutoComplete(): void {
    if (!this.searchInput) return;
    this.lazyLoadService.loadScript(this.googleMapsURL).subscribe(_ => {
      const input = this.searchInput.nativeElement;
      const options = {
        types: ['address'],
        fields: ['formatted_address'],
      };
      this.placesAutocomplete = new google.maps.places.Autocomplete(input, options);
      this.placesAutocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = this.placesAutocomplete.getPlace();
          this.searchString = place.formatted_address;
          this.headerService.activeHeader$.value.searchQuery$.next(place.formatted_address);
        });
      });

      this.renderer.setAttribute(input, 'autocomplete', this.user ? String(this.user._id) : 'false');
    });
  }

  public ngAfterViewInit(): void {
    if (this.location=="maps") {
          this.loadAutoComplete();
    }
  }

  bentleyGetEquipment(): void {
    this.headerService.bentleyGetEquipment();
  }

  cancel(): void {
    this.headerService.cancel();
  }

  convert2JPG(): void {
    this.headerService.convert2JPG();
  }

  createZip(): void {
    this.headerService.createZip();
  }

  delete(): void {
    this.headerService.delete();
  }

  download(): void {
    this.headerService.download();
  }

  hide(value): void {
    this.headerService.hide(value);
  }

  share(): void {
    this.headerService.share();
  }

  get columns(): Array<Column> {
    return this.headerService.columns;
  }

  // Filters for orders

  get statusGroups(): Array<OrderGroup> {
    return statusGroups;
  }

  filterOrders(event: Event, column: OrderGroup): void {
    event.stopImmediatePropagation();
    this.headerService.filterOrders(column);
  }

  get filterStatus(): OrderGroup | undefined {
    return this.headerService.filterStatus;
  }

  verifyOrders(event: Event, months: number | undefined): void {
    event.stopImmediatePropagation();
    this.headerService.verifyOrders(months);
  }

  get verifyStatus(): number | undefined {
    return this.headerService.verifyStatus;
  }

  // Filters for pilots

  filterPilotTags(event: Event, tag: string | undefined): void {
    event.stopImmediatePropagation();
    this.headerService.filterPilotTags(tag);
  }

  get pilotTagFilter(): string | undefined {
    return this.headerService.pilotTagFilter;
  }

  filterTestPilot(event: Event, test: boolean | undefined) {
    event.stopImmediatePropagation();
    this.headerService.filterPilotTest(test);
  }

  get pilotTestFilter(): boolean | undefined {
    return this.headerService.pilotTestFilter;
  }

  get displayedColumns(): Array<string> {
    return this.headerService.displayedColumns;
  }

  get folded(): boolean {
    return this.sidenavNavigationService.folded$.value;
  }

  get isProduction(): boolean {
    return environment.production
  }

  get searchAutocomplete(): Array<object> {
    return this.headerService.searchAutocomplete;
  }

  get showBackButton(): boolean {
    return this.isXSmall && !this.showCancelButton && this.breadcrumbs.length > 1;
  }

  get showCancelButton(): boolean {
    return this.showContextualHeader;
  }

  get showContextualHeader(): boolean {
    return this.headerService.showContextualHeader;
  }

  get showMenuButton(): boolean {
    return !this.isXSmall || !(this.showBackButton || this.showCancelButton || this.searchOpened);
  }

  get showLogo(): boolean {
    return !this.isXSmall && this.sidenavNavigationService.opened$.value && !this.sidenavNavigationService.folded$.value;
  }

  get selectedCount(): number {
    return this.headerService.selectedCount;
  }

  get selectedConvertibleCount(): number {
    return this.headerService.selectedConvertibleCount;
  }

  get selectedRegenerableCount(): number {
    return this.headerService.selectedRegenerableCount;
  }

  get selectedZIPCount(): number {
    return this.headerService.selectedZIPCount;
  }

  get showColumnsButton(): boolean {
    return this.headerService.viewMode === 'list';
  }

  get showFilterButton(): boolean {
    return this.headerService.viewMode === 'map' && this.headerService.mapMode !== 'empty';
  }

  get showBentleyButton(): boolean {
    return isBentleyEnabled &&
      this.showContextualHeader &&
      [AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login?.accountType) &&
      this.selectedCount === 1 &&
      this.selectedZIPCount === 1;
  }

  get showCheckDataButton(): boolean {
    return this.headerService.selectedCount === 0 &&
      this.login &&
      [AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login?.accountType) &&
      this.headerService.viewMode !== 'map';
  }

  get showDeleteButton(): boolean {
    return this.showContextualHeader && this.headerService.showDeleteButton;
  }

  get showShareButton(): boolean {
    return this.showContextualHeader && this.headerService.showShareButton;
  }

  get showDownloadButton(): boolean {
    return this.showContextualHeader && this.headerService.showDownloadButton;
  }

  get showImogentButton(): boolean {
    return isImogentEnabled &&
      this.showContextualHeader &&
      this.headerService.showImogentButton &&
      [AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login?.accountType);
  }

  get showRaptorMapsButton(): boolean {
    return isRaptorMapsEnabled &&
      this.showContextualHeader &&
      this.headerService.showRaptorMapsButton &&
      [AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login?.accountType);
  }

  get showNewOrderButton(): boolean {
    return this.headerService.showNewOrderButton;
  }

  get showConvert2JPGButton(): boolean {
    return this.showContextualHeader &&
      this.headerService.showConvert2JPGButton &&
      this.selectedConvertibleCount > 0;
  }

  get showConvert2JPGTooltip(): boolean {
    return this.selectedConvertibleCount === this.selectedCount;
  }

  get showRegenerateThumbnailsButton(): boolean {
    return this.showContextualHeader &&
      this.headerService.showRegenerateThumbnailsButton &&
      this.selectedRegenerableCount > 0;
  }

  get showRegenerateThumbnailsTooltip(): boolean {
    return this.selectedRegenerableCount === this.selectedCount;
  }

  get showToggleInfoButton(): boolean {
    return this.headerService.showToggleInfoButton;
  }

  get showWatermarkButton(): boolean {
    return this.showContextualHeader &&
      this.headerService.showRegenerateThumbnailsButton &&
      this.selectedRegenerableCount > 0;
  }

  get showZipButton(): boolean {
    return this.showContextualHeader && this.headerService.showZipButton;
  }

  get showSearch(): boolean {
    return !this.showContextualHeader && !!getPathValue(this, 'headerService.activeHeader$.value.searchQuery$');
  }

  get viewMode(): 'grid' | 'list' | 'map' {
    return this.headerService.viewMode;
  }

  setViewMode(viewMode: 'grid' | 'list'): void {
    this.headerService.setViewMode(viewMode);
  }

  get mapMode(): 'empty' | 'orders' | 'pilots' {
    return this.headerService.mapMode;
  }

  setMapMode(mapMode: 'empty' | 'orders' | 'pilots'): void {
    this.headerService.setMapMode(mapMode);
  }

  checkData(): void {
    this.headerService.checkData();
  }

  async matchData(): Promise<void> {
    this.fetchingChange$.next(true);
    this.filesService.setFetchingChange(true)
    let ok = true;
    let response;
    try {
      response = await this.headerService.matchData(this.activeFolder).toPromise();
    } catch (error) {
      ok = false;
      this.filesService.setFetchingChange(false)
      this.dialogService.showDialog("Error while Matching", error.error.errors[0], null, null, false, true);

    }

    this.filesService.refreshed.emit('refreshed');
    this.fetchingChange$.next(false);

    if (ok) {
      this.filesService.setFetchingChange(false)
      this.dialogService.showDialog("Data Matched Successfully", null, null, null, false, true);
    }
  }

  goBack(): void {
    if (this.breadcrumbs.length > 1) {
      this.router.navigate(this.breadcrumbs[this.breadcrumbs.length - 2].link);
    }
  }

  regenerateThumbnails(): void {
    this.headerService.regenerateThumbnails();
  }

  reloadPage(): void {
    location.reload();
  }

  UpdateSidenavDetail(id,label,link): void {
    console.log('Header path: ', this.breadcrumbs)
    if (!id) return;
    if (this.sidenavDetailService.opened$.value || this.isSiteURL) {
     if (label != "FILES.MY_FILES") {
      if (this.isSiteURL) {
        this.router?.navigate([{ outlets: {
          primary: ['portfolio',this.breadcrumbs[1].link[1],'files', id],
          detail: null
        }}]);
      } else {
        this.router?.navigate([{ outlets: {
          primary: ['files', id],
          detail: (/*this.sidenavDetailService.opened$.value &&*/ id.length === 24) ? ['file', id] : null // This was updated to force details tab to be opened always
        }}]);
      }
     } else {
      this.router?.navigate([{ outlets: {
        primary: ['files', "my-files"],
        detail: null
      }}]);
     }
    }
  }

  search(value: string, force?: boolean): void {
    if (force || (value && this.showSearch && this.headerService.activeHeader$.value.searchQuery$.value !== value)) { // User is typing inside the search bar
      this.headerService.activeHeader$.value.searchQuery$.next(value);
    } else if (value != null) { // User initially entered some text and then removed it and left value == "".
      this.search(null)
    }
  }

  searchAutocompleteSelected(event: MatAutocompleteSelectedEvent): void {
    const value = event.option.value;
    this.search(value);
  }

  sendToImogent(): void {
    this.headerService.sendToImogent();
  }

  sendToRaptorMaps(): void {
    this.headerService.sendToRaptorMaps();
  }

  toggleColumn(event: Event, column: string): void {
    event.stopImmediatePropagation(); // Prevent from closing the menu
    this.headerService.toggleColumn(column);
  }

  toggleInfo(): void {
    this.headerService.toggleInfo();
  }

  toggleNavbar(): void {
    if (this.isMobile || this.isXSmall) {
      this.sidenavNavigationService.toggleOpen();
    } else {
      if (this.sidenavNavigationService.opened && this.sidenavNavigationService.opened$.value) {
        this.sidenavNavigationService.toggleFold();
      } else {
        this.sidenavNavigationService.toggleOpen();
      }
    }
  }

  toggleCollaborationDialog(): void {
    const dialogRef = this.dialog.open(AddModifyCollaboratorsDialogComponent, {
      width: '600px', // Set the width of the dialog
      data: { orderModel: this.ordersService.activeOrder$.value, user: this.user }, // Optional: if you need to pass data to the dialog
    });
  
    dialogRef.afterClosed().subscribe(result => {
      this.translate.get("COLLABORATION.RESPONSE").subscribe((translation) => {
        if (!result) return;
        if (result.status) {
          if (result.requestInput === result.requestOutput) {
            this.dialogService.showDialog("Collaborators Added Successfully",null,null,null,null,true);
          } else if ( result.requestOutput === 0 && result.requestInput === 1  ) {
            this.dialogService.showDialog("Information", null, translation['ERROR']['ONE_NO_USER'], null, null, true);
          } else if ( result.requestOutput === 0 && result.requestInput > 1  ) {
            this.dialogService.showDialog("Information", null, translation['ERROR']['ALL_NO_USERS'], null, null, true);
          } else if ( result.requestOutput > 0 && result.requestInput > 1  ) {
            this.dialogService.showDialog("Information", null, translation['ERROR']['MULTIPLE_NO_USERS'], null, null, true);
          }
        } else {
          this.dialogService.showDialog("Error", null, translation['ERROR'][result.errorMessage], null, null, true);
        }
      });
    });
  }

  toggleSearch(event: MouseEvent, trigger: MatAutocompleteTrigger): void {
    event.stopPropagation();
    this.searchOpened = !this.searchOpened;
    if (this.searchOpened) {
      setTimeout(() => {
        if (this.location=="maps") {
          this.loadAutoComplete();
        }
        this.searchInput.nativeElement.focus();
      }, 0);
    } else {
      this.searchString = '';
      this.search(null); // Set to pristine without triggering a query
      trigger.closePanel();

      // When search is closed then navigate to previous link
      if (this.breadcrumbs.length > 0) {
        const active = this.breadcrumbs[this.breadcrumbs.length - 1];
        if (active.link) {
          this.router.navigate(active.link);
        } else if (active.label.startsWith('USERS')) {
          this.router.navigate(['users']);
        } else if (active.label.startsWith('MAPS')) {
          this.router.navigate(['maps']);
        }
      }
    }
  }

  watermark(): void {
    this.headerService.watermark();
  }

  isCemsUser(): boolean {
    return ['peter.spruyt@ec.europa.eu', 'mck420@icloud.com'].includes(this.user?.email.toLowerCase());
  }

  @HostListener('dragenter.out-zone', ['$event', '$event.target'])
  onDragEnter(event: DragEvent, target: HTMLElement): void {
    this.onDragOver(event, target);

    if (target.nodeType === Node.ELEMENT_NODE) {
      const dropZone = target.closest('.droppable') as HTMLElement;
      if (this.platform.SAFARI) {
        this.relatedTarget = dropZone; // Safari fix that doesn't yet support event.relatedTarget
      }
      if (dropZone && dropZone.className.indexOf('dragging') === -1 && dropZone.className.indexOf('drop-zone') === -1) {
        Array.from(document.getElementsByClassName('drop-zone')).forEach((element: HTMLElement) =>
          this.renderer.removeClass(element, 'drop-zone')); // Remove highlight from other drop-zones (fix for Safari)

        this.renderer.addClass(dropZone, 'drop-zone'); // Highlight drop-zone element
      }
    }
  }

  @HostListener('dragleave.out-zone', ['$event', '$event.relatedTarget', '$event.target'])
  onDragLeave(event: DragEvent, relatedTarget: HTMLElement, target: HTMLElement): void {
    // The element the cursor just entered
    let enter = event.relatedTarget && relatedTarget.closest('mat-row[draggable], div[draggable], .droppable') as HTMLElement;
    if (this.platform.SAFARI && !enter) {
      enter = this.relatedTarget;
    }
    // The element the cursor just left
    const leave = target && target.closest('mat-row[draggable], div[draggable], .droppable') as HTMLElement;

    if (enter !== leave) { // Different elements
      // Remove highlight from other drop-zones
      Array.from(document.getElementsByClassName('drop-zone')).forEach((element: HTMLElement) => {
        if (!enter || // Leave browser
          (!enter.attributes['draggable'] && enter.className.indexOf('droppable') === -1) || // Leave drop zones
          (enter.attributes['draggable'] && enter.className.indexOf('droppable') > -1 && element !== enter) || // Not entered folder
          (leave && leave.attributes['draggable'] && leave.className.indexOf('drop-zone') > -1 && element === leave) // Leave row/thumbnail
        ) {
          this.renderer.removeClass(element, 'drop-zone');
        }
      });
    }
  }

  @HostListener('dragover.out-zone', ['$event', '$event.target'])
  onDragOver(event: DragEvent, target: HTMLElement): void {
    target = target.closest('.droppable') as HTMLElement;
    // Firefox fires ondragover events on text nodes
    if (target && target.nodeType === Node.ELEMENT_NODE) {
      event.dataTransfer.dropEffect = target.classList.contains('dragging') ? 'none' : 'copy';
      // Prevent default behavior (Prevent file from being opened)
      event.preventDefault();
      event.stopPropagation();
    }
  }

  @HostListener('drop', ['$event', '$event.target'])
  onDrop(event: DragEvent, target: HTMLElement): void {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)

    const dropZone = target.closest('.droppable') as HTMLElement; // The drop zone

    let dropID = dropZone.getAttribute('fileId');

    this.renderer.removeClass(dropZone, 'drop-zone');

    // BUG: Safari doesn't get data from Chrome and Opera, always empty
    // https://github.com/ProjectMirador/mirador/issues/1433
    const text = event.dataTransfer.getData('text/plain');
    const data = text ? JSON.parse(text) : null;
    // Move internal files
    if (data && data.fileIDs) {

      const droppable = this.isDroppable(data.fileIDs, data.folderID, dropID);
      if (droppable) { // Avoid dropping a folder inside itself or one of it's children, this would create a cyclical reference

        // Update it in the DB Files collection
        const fileUpdates: FileModel = {
          _id: data.fileIDs.join(','),
          folderID: dropID ? { $oid: dropID } : ''
        };

        const update$ = data.fileIDs.length === 1 ? this.filesService.updateOne(fileUpdates) : this.filesService.updateMany(fileUpdates);
        update$.subscribe(
          () => {
            // this.refreshed.emit('refreshed'); // update files data models
            this.foldersService.refresh(); // update folders data models
            this.translate.get('FILES.SUCCESSFULLY_MOVED').subscribe(translation => {
              this.snackBar.open(translation, '', { duration: 3000 });
            });

            // Refresh files
            this.filesService.data$.next(this.filesService.data$.value.filter(f => data.fileIDs.indexOf(f._id) === -1));
          },
          error => {
            this.translate.get(error.error.errors[0]).subscribe(translation => {
              this.snackBar.open(translation, '', { duration: 3000 });
            });
          }
        );
      }
    } else {
      // Upload multiple files or a folder
      if (event.dataTransfer.items && event.dataTransfer.items.length) {
        const files: Array<File> = [];

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          const item = event.dataTransfer.items[i];
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            const entry = item.webkitGetAsEntry();
            if (entry.isFile) {
              const file = item.getAsFile();
              files.push(file);
            } else if (entry.isDirectory) {
              // listing = scanFiles(entry); // TODO: implement folder upload
              this.dialogService.showDialog('UPLOAD.FOLDERS_NOT_SUPPORTED', null, 'UPLOAD.CREATE_NEW_FOLDERS');
            }
          }
        }
        if (files.length) {
          this.uploadService.handleFilesInput(files, dropID);
        }
      } else if (event.dataTransfer.files && event.dataTransfer.files.length) {
        // Use DataTransfer interface to access the file(s)
        this.uploadService.handleFilesInput(event.dataTransfer.files, dropID);
      }
    }
  }

  /** Check if file/folder can be moved (dropped) on the client-side */
  isDroppable(dragIDs: Array<string>, dragParentID: string, dropID: string): boolean {
    if ((!dragParentID && !dropID) || dragParentID === dropID) {
      this.translate.get('FOLDERS.CANNOT_DROP_INTO_SAME_PARENT').subscribe(translation => {
        this.snackBar.open(translation, '', { duration: 3000 });
      });

      return false;
    }

    if (dragIDs.includes(dropID)) {
      this.translate.get('FOLDERS.CANNOT_DROP_INTO_ITSELF').subscribe(translation => {
        this.snackBar.open(translation, '', { duration: 3000 });
      });

      return false;
    }

    return true;
  }

}
