<div class="container">
  <!-- <ng-container>
    <mat-form-field>
      <mat-label>Filter by name</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Portfolio Name">
    </mat-form-field>
    <div class="table-container">
      <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8 full-height-table">

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Portfolio Name </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.name }} </td>
        </ng-container>

        <ng-container matColumnDef="createdAt">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created At </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.createdAt | date:'short' }} </td>
        </ng-container>

        <ng-container matColumnDef="owner">
          <th mat-header-cell *matHeaderCellDef> owner </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.user.email }} </td>
        </ng-container>

        <ng-container matColumnDef="averageScore">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Average Score </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.stats?.averageScore }} </td>
        </ng-container>

        <ng-container matColumnDef="critical">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Critical Issues </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.stats?.sitesStats?.critical }} </td>
        </ng-container>

        <ng-container matColumnDef="important">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Important Issues </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.stats?.sitesStats?.important }} </td>
        </ng-container>

        <ng-container matColumnDef="minor">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Minor Issues </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.stats?.sitesStats?.minor }} </td>
        </ng-container>

        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Issues </th>
          <td mat-cell *matCellDef="let portfolio"> {{ portfolio.stats?.sitesStats?.total }} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="navigateToPortfolio(row._id)"></tr>
      </table>
    </div>
    <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]" showFirstLastButtons></mat-paginator>
  </ng-container> -->

  <ng-container>
    <div class="header">
      <h4 class="header-title"> {{ [accountType.ADMIN, accountType.SUPERADMIN].includes(loginStateService.loggedUser$.value?.accountType)? 'Portfolios': 'Owned Portfolios' }}</h4>
      <button *ngIf="ownedPortfolios.length > 0" mat-mini-fab [ngStyle]="{transform: 'scale(0.9)'}" color="accent" (click)="openCreatePortfolioDialog()">
        <mat-icon>add</mat-icon>
      </button>
    </div>
    <div class="card-container section-bottom-margin">
      <mat-card
        *ngFor="let portfolio of ownedPortfolios"
        class="portfolio-card"
        (click)="navigateToPortfolio(portfolio._id)"
        role="button"
        tabindex="0"
      >
        <button
          class="edit-button"
          [matMenuTriggerFor]="menu"
          mat-icon-button
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="toggleEditPortfolioDialog(portfolio)">
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button
            *ngIf="portfolio.role !== 'viewer' && portfolio.role !== 'editor'"
            mat-menu-item
            (click)="toggleCollaborationDialog(portfolio, portfolio.userId)"
          >
            <mat-icon>share</mat-icon>
            <span>Collaborate</span>
          </button>
          <button
            *ngIf="portfolio.stats.sitesStats.total === 0"
            (click)="onDelete(portfolio._id)"
            mat-menu-item
          >
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
        <div class="card-icon">
          <mat-icon svgIcon="portfolio"></mat-icon>
        </div>
        <mat-card-content>
          <h4 class="card-title">{{ portfolio.name }}</h4>
          <div class="info-row">
            <span class="info-label">Owner:</span>
            <span class="info-value">{{ portfolio.user.email }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Access Level:</span>
            <span class="info-value">{{
              "USER.ROLE." +
                ((portfolio?.accessLevel ? portfolio?.accessLevel : "owner")
                  | uppercase) | translate
            }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Average Score:</span>
            <span class="info-value">{{
              (portfolio.stats?.averageScore | number : "1.2-2") || "0"
            }}</span>
          </div>
          <div class="info-row margin-break">
            <span class="info-label">Total Sites:</span>
            <span class="info-value">{{
              portfolio.stats?.sitesStats.total || "0"
            }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Sites in Critical Condition:</span>
            <span class="info-value">{{
              portfolio.stats?.sitesStats.scores | countOf : 1
            }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Sites in Mediocre Condition:</span>
            <span class="info-value">{{
              portfolio.stats?.sitesStats.scores | countOf : 0.5
            }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Sites in Fair Condition:</span>
            <span class="info-value">{{
              portfolio.stats?.sitesStats.scores | countOf : 0
            }}</span>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card
        *ngIf="ownedPortfolios.length === 0"
        class="portfolio-card new-portfolio-card"
        (click)="openCreatePortfolioDialog()"
        role="button"
        tabindex="0"
      >
        <div class="card-icon">
          <mat-icon class="add-portfolio-icon">add</mat-icon>
        </div>
      </mat-card>
    </div>
  </ng-container>
  <ng-container *ngIf="sharedPortfolios.length > 0">
    <h3 class="section-title">Shared Portfolios</h3>
    <div class="card-container">
      <mat-card
        *ngFor="let portfolio of sharedPortfolios"
        class="portfolio-card"
        (click)="navigateToPortfolio(portfolio._id)"
        role="button"
        tabindex="0"
      >
        <button
          *ngIf="!portfolio?.accessLevel"
          class="edit-button"
          [matMenuTriggerFor]="menu"
          mat-icon-button
          (click)="$event.stopPropagation()"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>edit</mat-icon>
            <span>Edit</span>
          </button>
          <button
            *ngIf="portfolio.role !== 'viewer' && portfolio.role !== 'editor'"
            mat-menu-item
            (click)="toggleCollaborationDialog(portfolio, portfolio.userId)"
          >
            <mat-icon>share</mat-icon>
            <span>Collaborate</span>
          </button>
          <button
            *ngIf="portfolio.stats.sitesStats.total === 0"
            (click)="onDelete(portfolio._id)"
            mat-menu-item
          >
            <mat-icon color="warn">delete</mat-icon>
            <span>Delete</span>
          </button>
        </mat-menu>
        <div class="card-icon">
          <mat-icon svgIcon="portfolio"></mat-icon>
        </div>
        <mat-card-content>
          <h3 class="card-title">{{ portfolio.name }}</h3>
          <div class="info-row">
            <span class="info-label">Owner:</span>
            <span class="info-value">{{ portfolio.user.email }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Access Level:</span>
            <span class="info-value">{{
              "USER.ROLE." +
                ((portfolio?.accessLevel ? portfolio?.accessLevel : "owner")
                  | uppercase) | translate
            }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Average Score:</span>
            <span class="info-value">{{
              (portfolio.stats?.averageScore | number : "1.2-2") || "0"
            }}</span>
          </div>
          <div class="info-row margin-break">
            <span class="info-label">Total Sites:</span>
            <span class="info-value">{{
              portfolio.stats?.sitesStats.total || "0"
            }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Sites in Critical Condition:</span>
            <span class="info-value">{{
              portfolio.stats?.sitesStats.scores | countOf : 1
            }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Sites in Mediocre Condition:</span>
            <span class="info-value">{{
              portfolio.stats?.sitesStats.scores | countOf : 0.5
            }}</span>
          </div>
          <div class="info-row">
            <span class="info-label">Sites in Fair Condition:</span>
            <span class="info-value">{{
              portfolio.stats?.sitesStats.scores | countOf : 0
            }}</span>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </ng-container>

</div>
