import { Component, Input, Output, EventEmitter, OnDestroy, OnInit, QueryList, ElementRef, ViewChildren, AfterViewInit, ViewEncapsulation } from '@angular/core';
import { FileModel } from '../../file.model';
import { Login } from 'src/app/pages/login/login.model';
import { OLMapsService } from 'src/app/shared/openlayers/maps.service';
import { Chart, Tooltip } from 'chart.js';
import { skip, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { MeasurementsService } from './measurements.service';
import { FilesService } from '../../../files.service';
import { Measurement } from './measurement.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { CsvExportService } from 'src/app/shared/csv-export/csv-export.service';
Chart.register(
  ChartDataLabels,
  Tooltip
);
@Component({
  selector: 'app-measurements',
  templateUrl: './measurements.component.html',
  styleUrls: ['./measurements.component.scss'],
})
export class MeasurementsComponent implements OnDestroy {
  @Input() fileModel: FileModel;
  @Input() login: Login;
  @Input() isSiteURL: boolean;
  @Input() theme: string;

  @Output() selectMeasurement = new EventEmitter<number>();
  @Output() volumeApproachChange = new EventEmitter<{ event: any, index: number }>();
  @Output() elevationChange = new EventEmitter<{ customElevation: any, index: number }>();
  @Output() saveMeasurement = new EventEmitter<number>();
  @Output() updateMeasurement = new EventEmitter<Measurement>();
  @Output() deleteMeasurement = new EventEmitter<number>();

  isEditing: boolean = false;
  showSaveIcon: boolean[] = [];
  measurements: Measurement[]  = [];

  showVolumeTooltip: boolean = false;
  ngDestroy$ = new Subject();
  Vocab = undefined;
  Plotly = require('plotly.js-dist')

  constructor(private oLMapsService: OLMapsService,
    private translate: TranslateService,
    private measurementsService: MeasurementsService,
    private filesService: FilesService,
    private dialogService: DialogService,
    public csvExportService: CsvExportService
  ) {
    this.translate.get(['MEASURE']).subscribe(translations => {
      this.Vocab = translations
    })

    // Receiving the already saved measurements by ol-map
    this.oLMapsService.addSavedMeasurement$.pipe(
      takeUntil(this.ngDestroy$)
    ).subscribe((measurement: any) => {
      if (measurement) {
        measurement.title = measurement.title ? measurement.title : `Untitled ${measurement.type}`;
        measurement.customElevation = measurement.elevationsDifference;
        this.measurements.push({ ...measurement });
      }
    });

    this.oLMapsService.enrichMeasurement$.pipe(takeUntil(this.ngDestroy$)).subscribe((enrichedMeasurement) => {
      let correspondingMeasurementIndex = this.measurements.findIndex(measurement => measurement._id === enrichedMeasurement._id)
      let correspondingMeasurement = this.measurements.find(measurement => measurement._id === enrichedMeasurement._id)
      if (correspondingMeasurement) {
        correspondingMeasurement = {... enrichedMeasurement}
        if (correspondingMeasurement.type == 'length' && this.fileModel?.dsmFileModel ) {
          const canvas: HTMLDivElement = document.getElementById('canvas-container' + correspondingMeasurementIndex) as HTMLDivElement;
          this.createLineChart(canvas, correspondingMeasurement.pixels, correspondingMeasurement.value2d);
        } else if ((correspondingMeasurement.type == 'area' || correspondingMeasurement.type == 'volume') && this.fileModel?.dsmFileModel) {
          const canvas: HTMLDivElement = document.getElementById('canvas' + correspondingMeasurementIndex) as HTMLDivElement;
          this.createPlotlyChart(canvas,correspondingMeasurement.pixels);
        }
      }
    })

    // Receiving the newly created measurements by ol-map
    this.oLMapsService.addNewMeasurement$.pipe(takeUntil(this.ngDestroy$),skip(1)).subscribe((measurement: any)=>{
      if (measurement){
          measurement.title = measurement.title ? measurement.title : `Untitled ${this.measurements.length}`;
          measurement.customElevation = measurement.elevationsDifference
          this.measurements.push({ ...measurement })
          this.selectMeasurement.emit(this.measurements.length - 1)
          if (measurement.pixels){
            setTimeout(() => {
              if (measurement.type == 'length') {
                const canvas: HTMLDivElement = document.getElementById('canvas-container' + (this.measurements.length-1)) as HTMLDivElement;
                this.createLineChart(canvas, measurement.pixels, measurement.value2d);
              } else if (measurement.type == 'area' || measurement.type == 'volume') {
                const canvas: HTMLDivElement = document.getElementById('canvas' + (this.measurements.length-1)) as HTMLDivElement;
                this.createPlotlyChart(canvas,measurement.pixels);
              }
            }, 300);
          }
      }
    })

    this.saveMeasurement.pipe(takeUntil(this.ngDestroy$))
    .subscribe ((index) => {
      this.measurementsService.insertOne(this.constructRequest(this.measurements[index]))
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          if (response.data) {
            this.measurements[index]._id = response.data._id
            this.dialogService.showDialog('MEASURE.POPUP.MEASUREMENT_SAVED_SUCCESSFULLY', null, null,null,null,true);
          }
      });
    })

    this.updateMeasurement.pipe(takeUntil(this.ngDestroy$))
    .subscribe ((measurement) => {
      this.measurementsService.updateOne(measurement)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          this.dialogService.showDialog('MEASURE.POPUP.MEASUREMENT_UPDATED_SUCCESSFULLY', null, null,null,null,true);
      });
    })

    this.volumeApproachChange.pipe(takeUntil(this.ngDestroy$))
    .subscribe (({event, index}) => {
      if (this.measurements[index].customElevation === undefined) {
        this.measurements[index].customElevation = Number((this.measurements[index].elevationsMax-this.measurements[index].elevationsMin).toFixed(2))
      }
    })

    this.deleteMeasurement.pipe(takeUntil(this.ngDestroy$))
    .subscribe ((index) => {
      if (this.measurements[index]._id) {
        this.measurementsService.hardDeleteOne(this.measurements[index]._id).pipe(takeUntil(this.ngDestroy$)).subscribe(
          response => {
            this.dialogService.showDialog('MEASURE.POPUP.MEASUREMENT_DELETED_SUCCESSFULLY', null, null,null,null,true);
          }
        )
      }
      this.oLMapsService.deleteFeature(this.measurements[index].feature);
      this.measurements.splice(index, 1);
      if (this.measurements.length === 1) {
        this.selectMeasurement.emit(0);
      } else if (this.measurements.length > 1) {
        if (index === 0) {
          this.selectMeasurement.emit(0)
        } else {
          this.selectMeasurement.emit( index - 1 )
        }
      }
    })

    this.elevationChange.pipe(takeUntil(this.ngDestroy$))
    .subscribe (({customElevation, index}) => {
      this.measurements[index]['volumeCustomElevation'] = this.measurementsService.calculateVolumeWithCustomElevation(this.measurementsService.extractElevations(this.measurements[index].pixels),customElevation, this.measurements[index].pixelSurface, this.measurements[index].elevationsMax)

      const newElevations = this.updateElevation(this.measurementsService.extractElevations(this.measurements[index].pixels),customElevation, this.measurements[index].elevationsMax)
      const data = [{
        z: newElevations,
        type: 'surface',
        showscale: false,
      }]
      const layout = {
        autosize: false,
        width:295,
        height:300,
        coloraxis: {
          showscale: false
        },
        color:this.theme.includes('dark')?"#c9c9c9":"rgb(110, 110, 110)",
        font: {
          color: this.theme.includes('dark')?"#c9c9c9":"rgb(110, 110, 110)"
          },
        scene3d: {
          camera: {
            eye: {
              x: 1.25, y: 1.25, z: 1.25
            }
          },
          dragmode: false,
          aspectmode: 'manual',
          aspectratio: {
            x: 1,
            y: 1,
            z: 1
          },
          equalaspect: true,
          zaxis: {
            range: [0, 1],
          },
        },
        uirevision: false,
        margin: {
          l: 0,
          r: 0,
          b: 0,
          t: 0,
        },
        plot_bgcolor: 'transparent',
        paper_bgcolor: 'transparent',
      };
      const config = {
        displayModeBar: false,
      };
      const canvas: HTMLDivElement = document.getElementById('canvas'+index) as HTMLDivElement
      this.Plotly.react(canvas, data,layout, config)

    })

    this.selectMeasurement.pipe(takeUntil(this.ngDestroy$))
    .subscribe ((index) => {
      this.oLMapsService.setFocusPixel(undefined)
      if (!this.measurements[index].selected){
        this.measurements.forEach((measurement, idx) => {
          measurement.selected = (idx === index);
        });
        this.oLMapsService.setFocusFeature(this.measurements[index])
      } else{
        this.measurements[index].selected = false
      }
    })

    this.oLMapsService.mapReady$.pipe(takeUntil(this.ngDestroy$)).subscribe((mapReady) => {
      if (mapReady) {
        setTimeout(() => {
        this.measurementsService.findMany(this.fileModel._id.toString())
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(
          response => {
            if (response.data) {
              this.filesService.annotationsTabOpen$.next(true);
              response.data.forEach((measurement: Measurement) => {
                 this.oLMapsService.drawNewMeasurement(measurement);
              });
            }
        });
        }, 500);
      }

    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  public handleSelectMeasurement(index: number) {
    this.selectMeasurement.emit(index);
  }

  private createLineChart(container: HTMLDivElement, elevations, normalLength) {
    container.innerHTML = '';

    const canvas = document.createElement('canvas');
    container.appendChild(canvas);

    const ctx = canvas.getContext('2d');
    const elevationValues = this.measurementsService.extractElevationsLine(elevations);
    const normalLengthLabels = Array.from({ length: 200 }, (_, i) => (i + 1) * (normalLength / 200));
    let hoverTimer;

    try {
      const myLineChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: normalLengthLabels,
          datasets: [{
            label: this.Vocab.MEASURE['ELEVATION'],
            data: elevationValues,
            borderColor: "#2979ff",
            borderWidth: 1,
            fill: false,
            pointHoverBackgroundColor: "white"
          }]
        },
        options: {
          onHover: (e: any) => {
            clearTimeout(hoverTimer);
            const mouseX = e.x;
            const chartArea = e.chart.chartArea;
            let xIndex = Math.floor(((mouseX - chartArea.left) / (chartArea.right - chartArea.left)) * normalLengthLabels.length);
            if (xIndex == -1) {
              xIndex = 0;
            }
            hoverTimer = setTimeout(() => {
              this.oLMapsService.setFocusPixel(undefined);
            }, 1000);
            if (elevations[xIndex]?.coordinates) {
              this.oLMapsService.setFocusPixel(elevations[xIndex].coordinates);
            }
          },
          plugins: {
            datalabels: {
              display: false,
            },
            legend: {
              display: false,
            },
          },
          interaction: {
            mode: 'nearest',
            axis: 'x',
            intersect: false,
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          responsive: true,
          scales: {
            x: {
              type: 'linear',
              position: 'bottom',
              title: {
                display: true,
                text: this.Vocab?.MEASURE['2D_LENGTH'] + ' (m)',
                color: this.theme?.includes('dark') ? "white" : "rgb(110, 110, 110)"
              },
              ticks: {
                color: this.theme?.includes('dark') ? "#c9c9c9" : "rgb(150 150 150)",
              },
              grid: {
                color: this.theme?.includes('dark') ? "#666666" : "rgb(210 210 210)",
              },
              max: Math.max(...normalLengthLabels),
            },
            y: {
              title: {
                display: true,
                text: this.Vocab.MEASURE['ELEVATION'] + ' (m)',
                color: this.theme?.includes('dark') ? "white" : "rgb(110, 110, 110)"
              },
              ticks: {
                color: this.theme?.includes('dark') ? "#c9c9c9" : "rgb(150 150 150)",
              },
              grid: {
                color: this.theme?.includes('dark') ? "#666666" : "rgb(210 210 210)",
              },
            },
          },
        }
      });
    } catch (error) {
      console.error("Error creating chart:", error);
    }
  }


  private constructRequest(measurementReceived: Measurement): Measurement{
    let measurementToSend: Measurement = {}

    measurementToSend.fileID = this.fileModel._id.toString()
    if (measurementReceived.value2d) {
       measurementToSend.value2d = measurementReceived.value2d
    }
    if (measurementReceived.value3d) {
       measurementToSend.value3d = measurementReceived.value3d
    }
    if (measurementReceived.type) {
       measurementToSend.type = measurementReceived.type
    }
    if (measurementReceived.location) {
      measurementToSend.location = measurementReceived.location
    }
    return measurementToSend
  }

  private updateElevation(elevationValues, peak, elevationMax):any{
    const numRows = elevationValues.length;
    const numCols = elevationValues[0].length;

    const elevationLimit = elevationMax - peak;
    let updatedElevationValues = [];

    for (let i = 0; i < numRows; i++) {
        let updatedElevationValuesRow = [];
        for (let j = 0; j < numCols; j++) {
            const elevation = elevationValues[i][j];
            if (elevation === undefined || elevation <= elevationLimit) {
                updatedElevationValuesRow.push(undefined);
            } else {
                updatedElevationValuesRow.push(elevation);
            }
        }
        updatedElevationValues.push(updatedElevationValuesRow);
    }

    return updatedElevationValues;
  }

  private createPlotlyChart(chartDiv: HTMLDivElement, pixels) {
    const z_data = [];
    const elevations = this.measurementsService.extractElevations(pixels)

    for (let i = 0; i < 1500; i++) {
      z_data.push(this.unpack(elevations, i));
    }

    const data = [{
      z: z_data,
      type: 'surface',
      showscale: false,
    }];

    const layout = {
      autosize: false,
      width:295,
      height:300,
      coloraxis: {
        showscale: false
      },
      color:this.theme.includes('dark')?"#c9c9c9":"rgb(110, 110, 110)",
      font: {
        color: this.theme.includes('dark')?"#c9c9c9":"rgb(110, 110, 110)"
        },
      scene3d: {
        camera: {
          eye: {
            x: 1.25, y: 1.25, z: 1.25
          }
        },
        dragmode: false,
        aspectmode: 'manual',
        aspectratio: {
          x: 1,
          y: 1,
          z: 1
        },
        equalaspect: true,
        zaxis: {
          range: [0, 1],
        },
      },
      uirevision: false,
      margin: {
        l: 0,
        r: 0,
        b: 0,
        t: 0,
      },
      plot_bgcolor: 'transparent',
      paper_bgcolor: 'transparent',
    };

    const config = {
      displayModeBar: false,
    };

    this.Plotly.newPlot(chartDiv, data, layout,config)
    .then((gd) => {
      gd.on('plotly_hover', (eventData) => {
        if (pixels[eventData.points[0].x][eventData.points[0].y]?.coordinates) {
          this.oLMapsService.setFocusPixel(pixels[eventData.points[0].x][eventData.points[0].y].coordinates)
        } else {
          this.oLMapsService.setFocusPixel(undefined)
        }
      });
      gd.on('plotly_unhover', () => {
        this.oLMapsService.setFocusPixel(undefined)
      });
    });

  }

  private unpack(rows: any[], key: number): any[] {
    return rows.map(row => row[key]);
  }

  handleVolumeApproachChange(event: any, index: number) {
    this.volumeApproachChange.emit({ event, index });
  }

  handleElevationChange(customElevation: any, index: number) {
    this.elevationChange.emit({ customElevation, index });
  }

  handleSaveMeasurement(index: number) {
    this.saveMeasurement.emit(index);
  }

  handleDeleteMeasurement(index: number) {
    this.deleteMeasurement.emit(index);
  }

  onTitleChange(index: number): void {
    this.showSaveIcon[index] = true;
  }

  save(index: number): void {
    if (!this.measurements[index]._id) {
      this.saveMeasurement.emit(index);
    } else {
      this.updateMeasurement.emit(this.measurements[index]);
    }
    this.showSaveIcon[index] = false;
  }

  stopPropagation(event: Event): void {
    event.stopPropagation();
  }

  public exportCSV(): void {
    this.csvExportService.exportToCsv('Measurements_'+this.fileModel.name,this.measurements)
  }
}

