import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, NgZone, OnDestroy, OnInit, Output, ViewChild, ViewChildren } from '@angular/core';
import { Order } from "../../shared/orders/orders.model";
import { Column} from "../../shared/interfaces/column.interface";
import { MatTable, MatTableDataSource } from "@angular/material/table";
import { statusGroups, OrdersService, OrderGroup } from "../../shared/orders/orders.service";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { MatSort} from "@angular/material/sort";
import { CdkScrollable, ScrollDispatcher } from "@angular/cdk/overlay";
import { ActiveHeader} from "../../shared/header/header.interface";
import { BehaviorSubject, Subject, throwError, Observable } from "rxjs";
import { FilesService } from '../files/files.service';
import { LoginStateService } from '../login/login-state.service';
import { catchError, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { AccountType, User } from '../users/models/user.model';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';
import { Folder } from 'src/app/shared/folders/folder.model';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements AfterViewInit, OnDestroy, ActiveHeader, OnInit {

  @Output() reloadEvent = new EventEmitter<void>();
  @Output() filterEvent = new EventEmitter<void>();
  @Output() nextPage = new EventEmitter<void>();
  @ViewChild('table') table: MatTable<Element>;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  columns: Array<Column> = [
    { label: 'ID', value: '_id' },
    { label: 'NAME', value: 'name' },
  ];
  allData = new MatTableDataSource<Order & {selected: boolean}>([]);
  loadedData = new MatTableDataSource<Order & {selected: boolean}>([]);
  newDataSource: Array<Order & {selected: boolean}> = []
  displayedColumns = [];
  pageSize = 50;
  totalItems = 0;
  selectedId?: number;
  contextMenuPosition = { x: '0px', y: '0px' };
  stats: {[_: string]: number} = {};
  groupedStats = statusGroups;
  filterStatus?: OrderGroup;
  @ViewChildren(CdkScrollable) cdkScrollable;
  showNewOrderButton = true;
  ngDestroy$ = new Subject();
  searchQuery$: BehaviorSubject<string> = new BehaviorSubject(null);
  searchAutocomplete = [
    { icon: 'shop', label: 'ORDER.ID', value: 'id:' },
    { icon: 'location_on', label: 'ADDRESS', value: 'address:' },
    { icon: 'description', label: 'ORDER.PROJECT_NAME', value: 'name:' },
  ];
  theme: string;
  get totalItemsCount(): number {
    return this.allData.filteredData.length;
  }
  get loadedItemsCount(): number {
    return this.loadedData.data.length;
  }
  loggedUser: User;
  selectedStatusGroup: string = '';
  accountType = AccountType

  constructor(
    public permissionsService: PermissionsService,
    public loginStateService: LoginStateService,
    public ordersService: OrdersService,
    private filesService: FilesService,
    private dialogService: DialogService,
    private themeService: ThemeService,
    private scrollDispatcher: ScrollDispatcher,
    private ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    
    this.router.events
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(event => {
        if (event instanceof NavigationEnd) {
           this.selectedId = Number(this.route.snapshot.root.children[1]?.params["orderID"]);
        }
    });
    
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });
  }

  ngOnInit(): void {
    this.sort.sortChange
    .pipe(tap(value => {this.loadedData.data = [];this.cdr.detectChanges();},takeUntil(this.ngDestroy$))).subscribe(() => {
      this.sortData(this.sort.active, this.sort.direction).then(() => {this.emitNextPageEvent()});
    });

    this.filterEvent.pipe(
    tap(value => {this.loadedData.data = [];this.cdr.detectChanges();},takeUntil(this.ngDestroy$))).subscribe(() => {
      this.loadNextPage();
    });
     
    this.reloadEvent.pipe(
    tap(value => {this.loadedData.data = [];this.cdr.detectChanges();},takeUntil(this.ngDestroy$))).subscribe(() => {
      this.fetchAllData();
    });

    this.nextPage.pipe(
    takeUntil(this.ngDestroy$)).subscribe(() => {
      this.loadNextPage();
    }); 
  }

  ngAfterViewInit(): void {
    this.loadedData.sort = this.sort;
    this.loginStateService.loggedUser$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe((loggedUser) => {
        if (loggedUser) {
          this.loggedUser = loggedUser;
          this.emitReloadEvent()
        }
      });

    this.scrollDispatcher.scrolled()  // If the user has scrolled within 200px of the bottom
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((scrollable: CdkScrollable) => {
      const bottom = scrollable ? scrollable.measureScrollOffset('bottom') : null;
      this.ngZone.run(() => {   // Fix https://github.com/angular/components/pull/8545
        if (this.loadedData.data.length < this.allData.data.length) // If we still have items to load
          {
            this.scrolled(bottom);  // Run this in `NgZone.run`, or the table will not renderRows
          }
      });
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  private sortData(active: string, direction: string): Promise<void> {
    return new Promise((resolve) => {
      if (!active || !direction) {
        resolve();
        return;
      }

      this.allData.filteredData.sort((a, b) => {
        let value1: any = a;
        let value2: any = b;

        active.split('.').forEach(path => {
          value1 = value1?.[path];
          value2 = value2?.[path];
        });

        value1 ||= -Infinity;
        value2 ||= -Infinity;

        const sortOrder = direction === 'asc' ? 1 : -1;

        if (value1 > value2) {
          return sortOrder;
        } else if (value1 < value2) {
          return -sortOrder;
        } else {
          return 0;
        }
      });

      resolve();
    });
  }

  loadNextPage() {
    this.loadedData.data = [...this.loadedData.data, ...this.allData.filteredData.slice(this.loadedData.data.length, this.loadedData.data.length+50) ]
  }


  scrolled(bottom?: number): void {
    if (!this.allData.filteredData) { return; }
    if (bottom !== null && bottom <= 20 && this.allData.filteredData.length > this.loadedData.filteredData.length) {
        this.emitNextPageEvent()
    }
  }

  selectedRow(row) {
    console.log('selectedRow', row)
  }
    
  private emitNextPageEvent(): void {
    this.nextPage.emit();
  }

  private emitReloadEvent(): void {
    this.reloadEvent.emit();
  }

  private emitFilterEvent(): void {
    this.filterEvent.emit();
  }

  public getOrderStatusLabel(row): string {
    if (this.ordersService.getOrderStatus(row.status) === 'done' && this.loggedUser.accountType !== AccountType.PILOT) {
      return 'ORDERS.RESULTS';
    } else {
        return 'ORDERS.GROUP_' + this.ordersService.getOrderStatus(row.status).toUpperCase();
    }
  }

  disabled(row: any): boolean {
    return this.loginStateService.loggedUser$.value?.accountType === AccountType.PILOT && row.status !== 'inProgress';
  }

  private navigateToFolder(folderID: string): void {
    this.router.navigate([{
      outlets: {
        primary: ['files', folderID],
        detail: ['file', folderID]
      }
    }]);
  }

  public handleLinkClick(row: any, event: MouseEvent): void {
    event.preventDefault();  // Prevent the navigation
    event.stopImmediatePropagation(); // Stop further event handling

    if(this.permissionsService.permissions$.value?.canRead.files) { // Check if you have permissions to read files in general
      // Search for main folder: if it exists, it means the user has access
      const folderName = 'FF'+row.legacyId
      const filter = {"name": folderName};
      this.filesService.findMany(filter).subscribe(folder => {
        if (folder.data.length > 0) { // Folder exists --> user has access to see it 
            this.navigateToFolder(folder.data[0]._id);
        } else { // Folder does not exist --> user has no access to see it (there is no chance that the folder is not created because it gets created once we create the order)
          if (row.role === 'PILOT') { // The user is  the assigned pilot
            if (row.status === 'notStarted' || row.status === 'onHold') { // The order is in progress
              this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'You still do not have access to this order yet.', null, null, true);
            } else {
              this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'ORDER.NO_ACCESS_MAIN_PILOT', null, null, true);
            }
            this.showDetails(row)
          } else if (row.role === 'SECONDARY_PILOT' || row.role === 'COORDINATOR' || row.role === 'VIEWER' || row.role === 'EDITOR') { // The user is a collaborator
            this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'ORDER.NO_ACCESS_COLLABORATOR', null, null, true);
            this.showDetails(row)
          } else if (row.role === 'OWNER') { // The user is the owner client
            this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'ORDER.NO_ACCESS_MAIN_CLIENT', null, null, true);
            this.showDetails(row)
          }
        }
      });
    } else {
      this.dialogService.showDialog('FILE.SHARE.ACCESS_DENIED', null, 'You have no permissions to see the data', null, null, true);
      this.showDetails(row)
    }
  }

  public handleRowClick(row) {
    this.showDetails(row)
    if (this.disabled(row)) {
      this.dialogService.showDialog('Error', null, 'ORDER.NO_ACCESS_MAIN_PILOT', null, null, true);
    }
  }

  private newFolder(folder: Folder): Observable<any> {
    return this.filesService.insertMany([folder]).pipe(
      catchError(error => {
        console.error('Error occurred while inserting folder:', error);  // Error handling
        return throwError(error);  // Propagate the error
      }),
      takeUntil(this.ngDestroy$)
    );
  }

  private async fetchAllData() {
    let filter = null;
    let ownership = "all";
    const accountType = this.loggedUser.accountType;
    const cacheKey = 'ordersCache';
  
    if ([AccountType.ADMIN, AccountType.SUPERADMIN].includes(accountType)) {
      filter = {};
      ownership = undefined;
    }
  
    // Check if data is in cache
    const cachedData = localStorage.getItem(cacheKey);
    let parsedCachedData = null;
    if (cachedData && cachedData != "undefined") {
      parsedCachedData = JSON.parse(cachedData);
      this.handleResponse(parsedCachedData);
    }
  
    // Always make the API call to update data
    this.ordersService.findManyOrders(filter, ownership, 0, 1000000)
      .pipe(
        mergeMap((resp: any) => {
          return this.ordersService.enrichOrders(resp);
        }),
        tap((resp: any) => {
          // Check if fetched data is different from cached data
          this.ordersService.orderIds = resp.orders.map(order => order.legacyId);
          if (JSON.stringify(resp) !== JSON.stringify(parsedCachedData)) {
            localStorage.setItem(cacheKey, JSON.stringify(resp)); // Update cache with new data
            this.handleResponse(resp); // Update UI with new data
          }
        }),
        takeUntil(this.ngDestroy$))
      .subscribe((resp: any) => {
        // TODO: implement here any further process
      });
  }
  
  private handleResponse(resp: any) {
    const accountType = this.loginStateService.loggedUser$.value?.accountType;
    if (accountType === AccountType.PILOT) {
      this.displayedColumns = ['legacyId','role', 'address', 'status'];
    } else if ([AccountType.ADMIN, AccountType.SUPERADMIN].includes(accountType)) {
      this.displayedColumns = ['legacyId', 'expirationDate', 'projectName', 'address', 'status'];
    } else {
      this.displayedColumns = ['legacyId', 'projectName', 'address', 'role', 'expirationDate', 'status'];
    }
    this.allData.data = (resp.orders ?? []).map(o => ({ ...o, selected: false }));
    this.allData.sort = this.sort;
    this.totalItems = this.allData.data.length;
    this.stats = {};
    for (const statGroup of statusGroups) {
      this.stats[statGroup] = 0;
    }
    for (const stat of Object.keys(resp.stats)) {
      const statGroup = this.ordersService.getOrderGroup(stat);
      this.stats[statGroup] += resp.stats[stat];
    }
    this.loadedData.data = []
    this.emitNextPageEvent()
  } 

  // private datePassed(numberOfDays: number, date: Date): boolean {
  //   // Parse the input date string to a Date object
  //   const inputDate = new Date(date);
  
  //   // Add the specified number of days to the input date
  //   inputDate.setDate(inputDate.getDate() + numberOfDays);
  
  //   // Get the current date
  //   const currentDate = new Date();
  
  //   // Compare the resulting date with the current date
  //   return inputDate < currentDate;
  // }


  // private exipredOrdersClient(order: Order): boolean {
  //   if (this.loggedUser._id === order.clientId && (order.hasSubscription || !this.datePassed(90,order.dateSetToVerify))) {
  //     return true
  //   } else (this.loggedUser._id !== order.clientId && order.hasSubscription || !this.datePassed(90,order.dateSetToVerify)))
  //   return false 
  // }

  public selectStatusGroupForFilter(orderGroup?: OrderGroup) {
    if (orderGroup === this.selectedStatusGroup) {
      this.selectedStatusGroup = ''
    } else {
      this.selectedStatusGroup = orderGroup
    }
    this.allData.filter = this.selectedStatusGroup;
    this.emitFilterEvent()
  }
  
  public applyFilter(event?: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.allData.filter = filterValue.trim().toLowerCase();
    this.emitFilterEvent()
  }

  public showDetails(row: any) {
    this.ordersService.setActiveOrder(row)
    this.selectedId = row.legacyId;
    this.router.navigate(
      [{ outlets: { detail: row ? ['order', row.legacyId] : null }}],
    );

    //Get Permission to that order
    if (this.loggedUser.accountType !== AccountType.ADMIN && this.loggedUser.accountType !== AccountType.SUPERADMIN) {
      this.permissionsService.setPermissions(row.role.toLowerCase()=== 'secondary_pilot'? 'pilot': row.role.toLowerCase());
    }
  }

  trackByIndex = i => i; // https://angular.io/guide/template-syntax#ngfor-with-trackby

}
