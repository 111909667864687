<h2>
  {{
    (data.portfolio === undefined
      ? "PORTFOLIO.MODAL.CREATE_PORTFOLIO"
      : "PORTFOLIO.MODAL.UPDATE_PORTFOLIO"
    ) | translate
  }}:
</h2>
<form [formGroup]="form" (submit)="submit()">
  <mat-form-field>
    <mat-label>{{ "PORTFOLIO.MODAL.PORTFOLIO_NAME" | translate }}</mat-label>
    <input formControlName="name" matInput required />
    <mat-error *ngIf="form.get('name')?.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="data.isAdmin">
    <mat-label>Select a user</mat-label>
    <input
      matInput
      class="user-input"
      formControlName="userEmail"
      [matAutocomplete]="auto"
      (keydown)="handleKeydown($event)"
      placeholder="Enter full name or email "
      required
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      (optionSelected)="addUserFromAutoComplete($event.option.value)">
      <mat-option *ngFor="let user of usersAutocompleteOptions" [value]="user">
        {{ user.email }}
        <span class="secondary-option-text">| {{ user.name }} {{ user.surname }}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-error *ngIf="form.get('userEmail')?.hasError('required')">
      This field is required
    </mat-error>
  </mat-form-field>

  <div class="controls">
    <button type="button" (click)="close()" mat-stroked-button color="accent">
      {{ "CANCEL" | translate }}
    </button>
    <button
      type="submit"
      mat-raised-button
      color="accent"
      [disabled]="form.invalid">
      {{ "SAVE" | translate }}
    </button>
  </div>
</form>
