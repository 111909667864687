
<div *ngIf="!collaborations || collaborations?.length === 0" class="empty-collaboration-container">
  <span class="empty-collaboration-text">{{ 'COLLABORATION.TITLE.NO_COLLABORATORS' | translate }}</span>
</div>
<div *ngFor="let collaboration of collaborations" class="collaboration-item" [class.dark]="theme && theme.includes('dark')">
  <div class="row">
    <div class="avatar">
      <mat-icon
      *ngIf="!collaboration.subject.avatarIconURL"
      class="avatar-icon">account_circle</mat-icon>

      <img
        *ngIf="collaboration.subject.avatarIconURL"
        alt="{{ collaboration.subject.name }}"
        class="avatar-img"
        draggable="false"
        src="{{ collaboration.subject.avatarIconURL }}">
    </div>

    <div class="infos">
      <div class="primary-line">
        <span>{{ collaboration.subject.name }} {{ collaboration.subject.surname }}</span>
        <mat-icon class=" mat-icon role-icon" svgIcon="{{getIcon(collaboration.options.role)}}"></mat-icon>
      </div>
      <div class="secondary-line">
        <span>{{ 'COLLABORATION.SIDEBAR.INVITED_BY' | translate }}: {{ collaboration.sharedBy.label }}</span>
        <!-- TODO: Put this back once we fix the issue with the removal of expired collaborations -->
        <!-- <span>{{ 'COLLABORATION.SIDEBAR.EXPIRES_AT' | translate }}: {{ collaboration.expiresAt | date }}</span> -->
      </div>
    </div>

    <div class="dropdown">
      <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="onDelete(collaboration._id)">
          <mat-icon>delete</mat-icon>
          <span>{{ 'COLLABORATION.SIDEBAR.REMOVE_ACCESS' | translate }}</span>
        </button>
        <button mat-menu-item disabled *ngIf="false">
          <mat-icon>edit</mat-icon>
          <span>{{ 'COLLABORATION.SIDEBAR.EDIT_ACCESS' | translate }}</span>
        </button>
      </mat-menu>
    </div>
  </div>
</div>
