import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from './shared/helpers/auth.guard';
import { CanDeactivateGuard } from './shared/can-deactivate/can-deactivate.guard';
import { FaqComponent } from './pages/faq/faq.component';
import { FileComponent } from './pages/files/file/file.component';
import { FilesComponent } from './pages/files/files.component';
import { FilesTemplateComponent } from "./pages/files-template/files-template.component";
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
import { HomeComponent } from './pages/home/home.component';
import { InspectionComponent } from './pages/files/inspection/inspection.component';
import { LoginComponent } from './pages/login/login.component';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { PdfEditorComponent } from './pages/pdf-editor/pdf-editor.component';
import { PdfEditorSidebarComponent } from './pages/pdf-editor/sidebar/pdf-editor-sidebar.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { SharedComponent } from './pages/shared/shared.component';
import { TasklistComponent } from './pages/tasklist/tasklist.component';
import { UserComponent } from './pages/users/user/user.component';
import { UsersComponent } from './pages/users/users.component';
import { ViewerComponent } from './pages/viewer/viewer.component';
import { ViewerSidebarComponent } from './pages/viewer/sidebar/viewer-sidebar.component';
import { OrderComponent } from "./pages/orders/order/order.component";
import { OrdersComponent } from "./pages/orders/orders.component";
import { PilotComponent } from "./pages/pilots/pilot/pilot.component";
import { Inspection3DComponent } from './pages/files/inspection-3d/inspection-3d.component';
import {PortfolioComponent} from "./pages/portfolio/portfolio.component";
import {SiteComponent} from "./pages/portfolio/site/site.component";
import { GasoidcAuthenticationComponent } from './pages/gasoidc-authentication/gasoidc-authentication.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { OlMapComparisonComponent } from './pages/ol-map-comparison/ol-map-comparison.component';
import { PortfoliosComponent } from './pages/portfolios/portfolios.component';

const routes: Routes = [
  { // Root redirect to home
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  { // Home page
    path: 'home',
    data: { title: 'HOME' },
    component: HomeComponent
  },
  { // Shared page
    path: 'shared/:fileID',
    data: { realFullScreen: true },
    component: SharedComponent
  },
  { // Login page
    path: 'login',
    data: { title: 'LOGIN' },
    component: LoginComponent
  },
  { // Registration page
    path: 'register',
    data: { title: 'REGISTER' },
    component: RegistrationComponent
  },
  { // Gasoidc external auth handler 
    path: 'gasoidc-authentication',
    data: { title: 'EXTERNAL_AUTHENTICATION' },
    component: GasoidcAuthenticationComponent
  },
  { // Forgot password
    path: 'forgot-password',
    data: { title: 'FORGOT_PASSWORD' },
    component: ForgotPasswordComponent
  },
  { // Reset password with a token
    path: 'reset-password/:token',
    data: { title: 'RESET_PASSWORD' },
    component: ResetPasswordComponent
  },
  { // My Files list
    path: 'files',
    redirectTo: 'files/my-files',
    pathMatch: 'full'
  },
  { // Tasks page
    path: 'tasks',
    data: { title: 'TASKS.TASKS' },
    component: TasksComponent
  },
  { // Inspection View
    path: 'inspection/:folderID',
    canActivate: [AuthGuard],
    component: InspectionComponent
  },
  { // Inspection View
    path: 'inspection3d/:folderID',
    canActivate: [AuthGuard],
    component: Inspection3DComponent
  },
  { // Folder id
    path: 'files/:folderID',
    canActivate: [AuthGuard],
    component: FilesComponent
  },
  { // Selected files details
    path: 'files/:selectedCount',
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: FileComponent,
    outlet: 'detail'
  },
  { // Single file details
    path: 'file/:fileID',
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: FileComponent,
    outlet: 'detail'
  },
  { // Files template of customers for pilots
    path: 'files-template',
    data: { title: 'FILES_TEMPLATE.TITLE' },
    component: FilesTemplateComponent,
  },
  { // Tasklist
    path: 'tasklist',
    data: { title: 'TASKLIST.TITLE' },
    canActivate: [AuthGuard],
    component: TasklistComponent
  },
  { // PDFeditor
    path: 'PDFeditor/:folderID',
    canActivate: [AuthGuard],
    component: PdfEditorComponent
  },
  { // viewer View
    path: 'viewer/:folderID',
    canActivate: [AuthGuard],
    component: ViewerComponent
  },
  { // PDF-Editor-Sidebar
    path: 'PDFeditor',
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: PdfEditorSidebarComponent,
    outlet: 'detail'
  },
  { // Maps
    path: 'maps',
    data: { title: 'MAPS.TITLE', fullScreen: true },
    canActivate: [AuthGuard],
    loadChildren: () => import('./pages/maps/maps.module').then(m => m.MapsModule)
  },
  { // Ol-Map-Comparison
    path: 'sites/:siteId/comparison',
    data: { title: 'Orthomosaic timeline', fullScreen: true },
    canActivate: [AuthGuard],
    component: OlMapComparisonComponent
  },
  { // Users list
    path: 'users',
    data: { title: 'USERS.TITLE' },
    children: [
      {
        path: '**', // Match all child paths
        canActivate: [AuthGuard],
        component: UsersComponent
      }
    ]
  },
  { // Create new user
    path: 'user/create',
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: UserComponent,
    outlet: 'detail'
  },
  { // User details
    path: 'user/:userID',
    data: { title: 'USER.TITLE' },
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: UserComponent
  },
  { // Orders list
    path: 'orders',
    data: { title: 'ORDERS.TITLE' },
    children: [
      {
        path: '**', // Match all child paths
        canActivate: [AuthGuard],
        component: OrdersComponent
      }
    ]
  },
  { // Order details
    path: 'order/:orderID',
    data: { title: 'ORDER' },
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: OrderComponent,
    outlet: 'detail'
  },
  { // Pilot details
    path: 'pilot/:pilotID',
    data: { title: 'PILOT' },
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: PilotComponent,
    outlet: 'detail'
  },
  { // Single file details
    path: 'viewer',
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: ViewerSidebarComponent,
    outlet: 'detail'
  },
  { // Posts list
    path: 'blog',
    data: { title: 'POSTS.TITLE' },
    loadChildren: () => import('./pages/posts/posts.module').then(m => m.PostsModule)
  },
  { // Post details
    path: 'post/:postID',
    loadChildren: () => import('./pages/posts/post/post.module').then(m => m.PostModule)
  },
  { // Frequently asked questions
    path: 'faq',
    data: { title: 'FAQ.TITLE' },
    component: FaqComponent
  },
  { // multiple portfolio details
    path: 'portfolios',
    data: { title: 'Portfolios Manager' },
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: PortfoliosComponent,
  },
  { // Single portfolio details
    path: 'portfolios/:portfolioID',
    data: { title: 'PORTFOLIO.TITLE' },
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: PortfolioComponent,
  },
  { // Single site details
    path: 'portfolios/:portfolioID/sites/:siteID',
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
    component: SiteComponent,
  },
  { // Inspection View from portfolio
    path: 'portfolio/:siteID/inspection/:folderID',
    canActivate: [AuthGuard],
    component: InspectionComponent
  },
  { // Folder View from portfolio
    path: 'portfolio/:siteID/files/:folderID',
    canActivate: [AuthGuard],
    component: FilesComponent
  },
  { // PDFGeneration View from portfolio
    path: 'portfolio/PDFeditor/:folderID',
    canActivate: [AuthGuard],
    component: PdfEditorComponent
  },
  { // 404 Not found primary
    path: '**',
    component: NotFoundComponent
  },
  { // 404 Not found detail
    path: '**',
    component: NotFoundComponent,
    outlet: 'detail'
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})

export class AppRoutingModule { }
