import { BehaviorSubject, Observable } from "rxjs";
import { Injectable, NgZone } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";

import { environment } from "./../../../environments/environment";
import { FilesService } from "./../../pages/files/files.service";
import { Login } from "../../pages/login/login.model";
import { QueueItem } from "./../../shared/upload/upload.model";
import { SseData, SseMessage } from "./sse.model";
import { UploadService } from "./../upload/upload.service";
import { DialogService } from "../dialog/dialog.service";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class SseServiceService {
  data$: BehaviorSubject<SseData> = new BehaviorSubject(null);
  queueItems?: Array<QueueItem> = [];

  constructor(
    private _zone: NgZone,
    private filesService: FilesService,
    private router: Router,
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private uploadService: UploadService,
  ) {}

  connectSSE(login: Login): void {
    this.data$.next({"pending":[], "failed":[], "running":[], "success":[]});
    this.getServerSentEvent(
      environment.apiHost + "events?jwt=" + login.token
    ).subscribe((data: any) => {

      //console.log('--------- NEW EVENT --------')
      
      const tmpDataArray = [];
      const sseDataObjects = JSON.parse(data.data);
      for (const k in sseDataObjects) {
        tmpDataArray.push(sseDataObjects[k]);
      }

      const sseSorted = this.sortSseData({
        pending: tmpDataArray.filter((s) => s.status === "pending"),
        running: tmpDataArray.filter((s) => s.status === "running"),
        success: tmpDataArray.filter((s) => s.status === "success"),
        failed: tmpDataArray.filter((s) => s.status === "failure")
      } as SseData);
      if (
        sseSorted.pending.length !== this.data$.value.pending.length ||
        sseSorted.success.length !== this.data$.value.success.length ||
        sseSorted.failed.length !== this.data$.value.failed.length
      ) {
        // Add to queue
        sseSorted.pending.forEach((message) => {
          if ( this.getMessageFromItemsList(message).length === 0 ) { // Item does not exist
            this.addItemToSideBarList(message)
          }
        });
        // Remove from queue
        sseSorted.success.forEach((message) => {
          const mIndex = this.queueItems.findIndex((item) => {
            return item.key === message.requestId;
          });
          if (mIndex !== -1) {
            this.queueItems.splice(mIndex, 1);
            this.uploadService.queueItems$.next(this.queueItems);
            this.translate.get("INFO").subscribe((translation) => {
              const snackBarRef = this.snackBar.open(
                `${message.target.name}`,
                message.name === "generate-pdf"? 'Open in Folder':translation,
                { duration: 30000 }
              );
              if (message.name === "generate-pdf") {
                this.filesService.findOne(message.target.objectId.toString())
                .subscribe( (response) => {
                  if (response){
                    snackBarRef.onAction().subscribe(() => {
                      this.router.navigate(
                        ['',
                          {
                            outlets: {primary: ['files', response.data.folderID ]},
                          },
                        ],
                      );
                      snackBarRef.dismiss();
                    });
                  }
                })
              } else {
                snackBarRef.onAction().subscribe(() => {
                  this.router.navigate(
                    [
                      {
                        outlets: { detail: ["file", message.target.objectId] },
                      },
                    ],
                    { queryParamsHandling: "merge" }
                  );
                  snackBarRef.dismiss();
                });
              }  
            });
            this.filesService.refreshed.emit("refreshed");
          }
        });

      } 

      // Starting queue item
      sseSorted.running.forEach((message) => {            
        const changedItem = this.getMessageFromItemsList(message)
        if ( changedItem.length === 1) {
          console.log('updating a existing item progress')
          changedItem[0].progress = message.progress
          changedItem[0].progressPercentage = message.progress * 100
          changedItem[0].startProgress();
        } else {
          console.log('adding new item')
          this.addItemToSideBarList(message)
        }
      });

      // Remove canceled items 
      this.queueItems = this.queueItems.filter((queueItem) => {
        console.log('queueItem: ', queueItem);
    
        // Exclude items uploading file items
        if (queueItem.name === 'upload-file') {
            return true;
        }
    
        // Check if the item is still running or pending 
        const stillRunning = sseSorted.running.find(
            (runningItem) => runningItem.requestId === queueItem.key
        );
        const stillPending = sseSorted.pending.find(
            (pendingItem) => pendingItem.requestId === queueItem.key
        );
    
        return !!stillRunning || !!stillPending;
    });
    
    this.uploadService.queueItems$.next(this.queueItems);
    this.data$.next(sseSorted);

    });
  }

  sortSseData(input: SseData): SseData {
    return {
      pending: input.pending.sort((a, b) =>
        a.timestamp > b.timestamp ? -1 : 1
      ),
      running: input.running.sort((a, b) =>
        a.timestamp > b.timestamp ? -1 : 1
      ),
      success: input.success.sort((a, b) =>
        a.timestamp > b.timestamp ? -1 : 1
      ),
      failed: input.failed.sort((a, b) => 
        a.timestamp > b.timestamp ? -1 : 1
      )
    };
  }

  getServerSentEvent(url: string) {
    return new Observable((observer) => {
      const eventSource = this.getEventSource(url);

      eventSource.onmessage = (event) => {
        this._zone.run(() => {
          observer.next(event);
        });
      };

      eventSource.onerror = (error) => {
        this._zone.run(() => {
          observer.error(error);
        });
      };
    });
  }

  getEventSource(url: string): EventSource {
    return new EventSource(url);
  }

  // ----------------- Helpers ---------------
  addItemToSideBarList(message: SseMessage) {
    let icon = 'question_mark';
    switch (message.name) {
      case 'convert':
        icon = 'photo_library'
        break;
      case 'watermark':
        icon = 'branding_watermark'
        break;
      case 'zip':
        icon = 'archive'
        break;
      case 'unzip':
        icon = 'archive'
        break;
      case 'generate-pdf':
        icon = 'picture_as_pdf'
        break;
      case 'mail':
        icon = 'mail'
        break;
    }
    const queueItem = new QueueItem(
      message.requestId,
      message.target.name,
      icon,
      message.name,
      message.progress
    );

    if (message.name !== 'mail') {
      this.queueItems.push(queueItem);
      this.uploadService.queueItems$.next(this.queueItems);
      queueItem.startProgress()
    }
  }

  getMessageFromItemsList(message: SseMessage): QueueItem[] {
    return this.queueItems.filter((item) => {
      return item.key === message.requestId;
    })
  }
}
