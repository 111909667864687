import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { BehaviorSubject, Subject } from 'rxjs';
import { Component, Inject, Input, OnDestroy, ElementRef, ViewChild, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { takeUntil } from 'rxjs/operators';

import { FileModel } from '../../file.model';
import { FilesSelectDialogComponent } from './../../../../../shared/files-select-dialog/files-select-dialog.component';
import { FilesService } from '../../../files.service';
import { KrpanoService } from '../../../../../shared/pano/krpano.service';
import { Login } from '../../../../login/login.model';
import { PermissionRole } from '../../permission.model';
import { pois, poisCluster } from '../../../../../shared/pano/pois';
import { SidebarService } from '../sidebar.service';
import { ThemeService } from '../../../../../shared/theme/theme.service'
import { AccountType, UserRole } from '../../../../users/models/user.model';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';

@Component({
  selector: 'app-sidebar-annotations-pano',
  templateUrl: './annotations-pano.component.html',
  styleUrls: ['./annotations-pano.component.scss']
})
export class AnnotationsPanoComponent implements OnChanges, OnDestroy {
  @ViewChild('annotationsBody') annotationsBody: ElementRef;
  color = '#536262';
  colorsUsed = [];
  colorsProposals = ['#536262', '#fd261c', '#fece2f', '#38d23c', '#2979ff'];
  colorShowPicker = false;
  customForm = false;
  customPoiActive = false;
  activePanel: string;
  fetchingChange$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  @Input() fileModel: FileModel;
  @Input() view: string;
  @Input() login: Login;
  imageURLView: string;
  permissionRole = PermissionRole;
  formAnnotationPano: FormGroup;
  formOpen = false;
  panoClickTimer: number;
  pois = pois;
  poisTag = '';
  userRole = UserRole;
  accountType = AccountType
  poisCustom = [];
  poisCluster = poisCluster;
  scrolled = true;
  theme: string;

  private ngDestroy$ = new Subject();


  get imageID(): AbstractControl { return this.formAnnotationPano.get('imageID'); }
  get type(): string { return this.formAnnotationPano.get('type').value; }

  constructor(
    private dialog: MatDialog,
    @Inject(FormBuilder) private formBuilder: FormBuilder,
    public filesService: FilesService,
    private krpanoService: KrpanoService,
    private sidebarService: SidebarService,
    private snackBar: MatSnackBar,
    private themeService: ThemeService,
    private translate: TranslateService,
    public permissionsService: PermissionsService
  ) {
    this.formAnnotationPano = this.formBuilder.group({
      alpha : new FormControl({ value: '' }),
      icon: new FormControl({ value: '' }),
      index: new FormControl({ value: '' }),
      isCheckedOpenURL: new FormControl({ value: '' }),
      isCheckedImageURL: new FormControl({ value: '' }),
      isCheckedCustomMarker: new FormControl({ value: ''}),
      name: new FormControl({ value: '' }, [
        Validators.maxLength(256)
      ]),
      openURL: new FormControl({ value: '' }),
      imageID: new FormControl({ value: '' }),
      scale : new FormControl({ value: '' }),
      tooltip: new FormControl({ value: '' }, [
        Validators.maxLength(256)
      ]),
      type : new FormControl({ value: '' })
    })


    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    window.krpanoFunction = (action: string, value?: string) => {
      if (action === 'addHotspot') {
        const pos = this.krpanoService.mouseDownGet();
        this.newAnnotation(pos.h, pos.v);
      }
      if (action === 'click') {
        if ((Date.now() - this.panoClickTimer) <= 400) {
          this.edit(this.fileModel.pano.hotspots.indexOf(this.fileModel.pano.hotspots.find(x => x.name === value)));
        }
        this.panoClickTimer = Date.now();
      }
      if (action == 'stopDraw') {
        this.krpanoService.polygonStop();
      }
    };
  }

  ngOnChanges(): void {
    this.setFile();
  }

  abort(): void {
    this.customPoiActive = false;
    this.colorShowPicker = false;
    this.formOpen = false;
    if (this.formAnnotationPano.value.index === -1) {
      this.krpanoService.hotspotRemove(this.formAnnotationPano.value.name);
    } else {
      const tmpHotspot = this.fileModel.pano.hotspots[this.formAnnotationPano.value.index]

      if(this.formAnnotationPano.value.type === 'poi') {
        this.krpanoService.hotspotMove(tmpHotspot.name, false);
      }
      let tmpAlpha: number;
      let tmpOpenURL: string;
      let tmpImageID: string;
      let tmpScale: number;
      let tmpShowURL: boolean;
      let tmpShowImage: boolean;
      let tmpTooltip: string;
      if (this.formAnnotationPano.value.type === 'poi') {
        (tmpHotspot.alpha !== undefined) ? tmpAlpha = tmpHotspot.alpha : tmpAlpha = 1;
      } else if (this.formAnnotationPano.value.type === 'polygon') {
        (tmpHotspot.alpha !== undefined) ? tmpAlpha = tmpHotspot.alpha : tmpAlpha = 0.5;
      }
      (tmpHotspot.scale) ? tmpScale = tmpHotspot.scale : tmpScale = 1;
      (tmpHotspot.openURL) ? tmpShowURL = true : tmpShowURL = false;
      (tmpHotspot.imageID) ? tmpShowImage = true : tmpShowImage = false;
      (tmpHotspot.openURL) ? tmpOpenURL = tmpHotspot.openURL : tmpOpenURL = '';
      (tmpHotspot.imageID) ? tmpImageID = tmpHotspot.imageID.toString() : tmpImageID = '';
      (tmpHotspot.tooltip) ? tmpTooltip = tmpHotspot.tooltip : tmpTooltip = '';
      if (this.formAnnotationPano.value.type === 'poi') {
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'alpha', String(tmpAlpha));
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'ath', String(tmpHotspot.ath));
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'atv', String(tmpHotspot.atv));
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'scale', String(tmpScale));
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'tooltip', tmpTooltip);
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'url', tmpHotspot.icon, this.poisTag, tmpHotspot.color);
      } else if (this.formAnnotationPano.value.type === 'polygon') {
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'bordercolor', tmpHotspot.color.replace('#','0x'));
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'fillalpha', String(tmpAlpha));
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'fillcolor', tmpHotspot.color.replace('#','0x'));
        this.krpanoService.hotspotUpdate(tmpHotspot.name, 'tooltip', tmpTooltip);
      }
    }
    setTimeout(() => {
      this.scrollTop();
      this.resetForm();
     }, 1);
  }

  checkImageID(): void {
    if(!this.isCheckedImageURL()) {
      this.selectFileDialog();
    } else {
      this.imageID.setValue("");
      this.imageURLView = null;

      this.krpanoService.krpano.set(`hotspot[${this.formAnnotationPano.value.name}].imageURL`, null);
    }
  }

  checkSubscription(): boolean {
    if (!this.login) {
        console.error('Login service is not initialized.');
        return false;
    }

    if (!this.fileModel) {
        console.error('fileModel is not initialized.');
        return false;
    }

    const hasSubscription = this.login.checkSubscription();

    return hasSubscription;
  }


  colorsList(): void {
    this.colorsUsed = [];
    if(this.fileModel.pano && this.fileModel.pano.hotspots) {
      this.fileModel.pano.hotspots.forEach((hotspot) => {
        if (
            hotspot.color &&
            hotspot.color.length > 0 &&
            this.colorsProposals.indexOf(hotspot.color) === -1 &&
            this.colorsUsed.indexOf(hotspot.color) === -1
        ) {
          this.colorsUsed.push(hotspot.color);
        }
      });
    }
  }

  colorTogglePicker(): void {
    this.colorShowPicker = !this.colorShowPicker;
  }

  colorUpdate(newColor: string): void {
    this.color = newColor;
    if (this.type === 'poi') {
      this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'url', this.formAnnotationPano.value.icon, this.poisTag, newColor);
    }
    else if (this.type === 'polygon') {
      this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'bordercolor', newColor.replace('#','0x'));
      this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'fillcolor', newColor.replace('#','0x'));
    }
  }

  delete(i: number): void {
    if (this.krpanoService && this.view) {
      this.krpanoService.hotspotRemove(this.fileModel.pano.hotspots[i].name);
    }
    this.fileModel.pano.hotspots.splice(i, 1)

    this.setUpdate()
  }

  edit(i: number, lookTo = false): void {
    const item = this.fileModel.pano.hotspots[i];
    if(item.type === 'poi' || this.checkSubscription()) {
      this.scrollDetector()
      this.formOpen = true;

      if (lookTo) {
        this.krpanoService.hotspotLookTo(item.name);
      }
      if(item.type === 'poi') {
        this.krpanoService.hotspotMove(item.name, true);
      }
      // declare tmp Variables
      let tmpAlpha: number;
      let tmpcustomColor: boolean;
      let tmpcustomMarker: boolean;
      let tmpIcon: string;
      let tmpImageID: string;
      let tmpOpenURL: string;
      let tmpScale: number;
      let tmpShowImage: boolean;
      let tmpShowURL: boolean;
      let tmpTooltip: string;
      if(item.type === 'poi') {
        (item.alpha !== undefined) ? tmpAlpha = item.alpha : tmpAlpha = 1;
      } else if(item.type === 'polygon') {
        (item.alpha !== undefined) ? tmpAlpha = item.alpha : tmpAlpha = 0.5;
      }
      (item.scale) ? tmpScale = item.scale : tmpScale = 1;
      (item.openURL) ? tmpShowURL = true : tmpShowURL = false;
      (item.openURL) ? tmpOpenURL = item.openURL : tmpOpenURL = '';
      (item.imageID) ? tmpShowImage = true : tmpShowImage = false;
      (item.imageID) ? tmpImageID = item.imageID.toString() : tmpImageID = '';
      (item.tooltip) ? tmpTooltip = item.tooltip : tmpTooltip = '';
      (item.icon) ? tmpIcon = item.icon : tmpIcon = '';
      (item.icon) ? tmpcustomMarker = item.icon.includes(':'): tmpcustomMarker = false;

      (item.color) ? tmpcustomColor = true : tmpcustomColor = false;
      if(tmpcustomColor) {
        this.color = item.color;
      }

      if(this.poisCustom.includes(item.icon)) {
        this.customPoiActive = true;
        this.krpanoService.updateSelector(this.customPoiActive && this.customForm);
      }

      this.formAnnotationPano.setValue({
        isCheckedOpenURL: tmpShowURL,
        isCheckedImageURL: tmpShowImage,
        isCheckedCustomMarker: tmpcustomMarker,
        alpha: tmpAlpha,
        name: item.name,
        openURL: tmpOpenURL,
        imageID: tmpImageID,
        icon: tmpIcon,
        scale: tmpScale,
        index: i,
        tooltip: tmpTooltip,
        type: item.type
      });

      if(this.imageID.value) {
        this.loadImage(this.imageID.value);
      } else {
        this.imageURLView = null;
      }

      this.openCluster();
      setTimeout(() => { this.scrollTop(); }, 1);
    }
  }

  getChanges(): any {
    const pano = {};
    if (
      this.fileModel.pano.hotspots.length > 0 ||
      this.fileModel.pano.view ||
      this.fileModel.pano.customXML ||
      this.fileModel.pano.title ||
      this.fileModel.pano.littlePlanet !== undefined
    ) {
      if (this.fileModel.pano.customXML) { Object.assign(pano, { customXML: this.fileModel.pano.customXML }); }
      if (this.fileModel.pano.littlePlanet !== undefined) {
        if (!this.fileModel.pano.littlePlanet) {
          Object.assign(pano, { littlePlanet: false });
        }
      }
      if (this.fileModel.pano.title) { Object.assign(pano, { title: this.fileModel.pano.title }); }
      if (this.fileModel.pano.view) { Object.assign(pano, { view: this.fileModel.pano.view }); }
      if (this.fileModel.pano.hotspots.length > 0) {
        Object.assign(pano, {
          hotspots: this.fileModel.pano.hotspots.map((newHotspot: any) => {
            const hotspot = {};  // we use type: any to avoid adding null fields
            hotspot['alpha'] = Number(newHotspot.alpha) as number;
            hotspot['ath'] = Number(newHotspot.ath) as number;
            hotspot['atv'] = Number(newHotspot.atv) as number;
            hotspot['icon'] = newHotspot.icon as string;
            hotspot['name'] = newHotspot.name as string;
            hotspot['points'] = newHotspot.points;

            if (newHotspot.color !== this.colorsProposals[0]) {
              hotspot['color'] = newHotspot.color;
            }
            if (newHotspot.openURL && newHotspot.openURL.length > 0) {
              hotspot['openURL'] = newHotspot.openURL as string;
            }
            if (newHotspot.imageID && newHotspot.imageID.length > 0) {
              hotspot['imageID'] = {$oid: newHotspot.imageID}
            }
            // Fallback for old imageURL without imageID
            if (newHotspot.imageURL && newHotspot.imageURL.length > 0 && newHotspot.imageID === null) {
              hotspot['imageURL'] = newHotspot.imageURL as string;
            }
            if (newHotspot.scale !== 1) {
              hotspot['scale'] = Number(newHotspot.scale) as number;
            }
            if (newHotspot.tooltip && newHotspot.tooltip.length > 0) {
              hotspot['tooltip'] = newHotspot.tooltip as string;
            }
            hotspot['type'] = newHotspot.type as string;

            return hotspot;
          })
        });
      }

      return pano;
    } else {

      return '';
    }
  }

  getIconURL(item: string): string {
    if (item.includes(':')) {
      item = item.split(':')[1]
    }

    if (item in this.pois) {
      return this.pois[item].icon
    } else {
      return 'https://fairfleet.com/a/Panorama/png/icon_' + item + '.png'
    }
  }

  isCheckedImageURL(): boolean { return this.formAnnotationPano.get('isCheckedImageURL').value; }
  isCheckedOpenURL(): boolean { return this.formAnnotationPano.get('isCheckedOpenURL').value; }
  isCheckedCustomMarker(): boolean { return this.formAnnotationPano.get('isCheckedCustomMarker').value; }
  isCustomMarker(): boolean {
    let icon = this.formAnnotationPano.value.icon
    if (icon.includes(':')) {
      icon = icon.split(':')[1]
    }
    if (icon in this.pois) {
      return true
    }
    return false
  }

  loadImage(imageID: string): void {
    const usedFileIDs = {}
    usedFileIDs[imageID] = this.formAnnotationPano.value.name

    this.filesService.findOne(imageID)
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(
      response => {

        if (this.fileModel.public && !response.data.public) {
          this.filesService.makePublic(response.data._id,true).pipe(takeUntil(this.ngDestroy$)).subscribe( (response) => {
          })
        }
        this.krpanoService.krpano.set(`hotspot[${usedFileIDs[response.data._id]}].imageURL`, response.data.webViewLink);
        this.imageID.setValue(response.data._id)
        this.imageURLView = response.data.webViewLink;
      }
    );
  }

  lookTo(name): void {
    if (this.krpanoService && this.view) {
      this.krpanoService.hotspotLookTo(name);
    }
  }

  newPolygon(): void {
    this.translate.get('SIDEBAR.ANNOTATIONS_PANO.POLYGON_FINISH_HINT').subscribe(translation => {
      this.snackBar.open(`${translation}`, '', { duration: 5000 });
    });
    this.color = '#2979ff'
    const hsName = `hs_${Date.now()}`;
    this.krpanoService.polygonStart(hsName);

    this.formOpen = true;
    this.formAnnotationPano.setValue({
      alpha: 0.5,
      icon: 'marker',
      index: -1,
      isCheckedOpenURL: false,
      isCheckedImageURL: false,
      isCheckedCustomMarker: this.poisTag !== '',
      name: hsName,
      openURL: '',
      imageID: '',
      scale: 1,
      tooltip: '',
      type: 'polygon'
    });
  }

  newAnnotation(h?: number, v?: number): void {
    this.color = '#536262'
    this.scrollDetector()
    const hsName = `hs_${Date.now()}`;
    this.formOpen = true;
    this.imageURLView = null;
    let tmpIcon = 'marker'
    if (this.poisTag !== '') {
      tmpIcon = this.poisTag + ':' + tmpIcon
    }
    this.formAnnotationPano.setValue({
      alpha: 1,
      icon: tmpIcon,
      index: -1,
      isCheckedOpenURL: false,
      isCheckedImageURL: false,
      isCheckedCustomMarker: this.poisTag !== '',
      name: hsName,
      openURL: '',
      imageID: '',
      scale: 1,
      tooltip: '',
      type: 'poi'
    });
    if (h === undefined && v === undefined) {
      this.krpanoService.hotspotAdd(this.formAnnotationPano.value.name, this.formAnnotationPano.value.icon, this.poisTag, this.color);
    } else {
      this.krpanoService.hotspotAdd(this.formAnnotationPano.value.name, this.formAnnotationPano.value.icon, this.poisTag, this.color, h, v);
    }
    this.openCluster();
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  noAnnotation(type: string): boolean{
    if (
      this.fileModel &&
      this.fileModel.pano &&
      this.fileModel.pano.hotspots &&
      this.fileModel.pano.hotspots.length > 0 &&
      (
        type === '' ||
        this.fileModel.pano.hotspots.filter(x => x.type == type).length > 0
        )
      ) {
      return false
    }
    return true
  }

  openCluster(): void {
    //open accordion cluster
    this.activePanel = 'custom'
    this.poisCluster.forEach(cluster => {
      let icon = this.formAnnotationPano.value.icon

      if (icon.includes(':')) {
        icon = icon.split(':')[1]
      }
      if (cluster.list.includes(icon)) {
        this.activePanel = cluster.name;
      }
    });
  }

  resetForm(): void {
    this.formOpen = false;
    this.customPoiActive = false;
    this.imageURLView = null;
    this.formAnnotationPano.reset({
      alpha: 1.0,
      isCheckedOpenURL: false,
      isCheckedImageURL: false,
      openURL: '',
      imageID: '',
      scale: 1.0,
      tooltip: '',
      icon: 'marker'
    });
  }

  save(): void {
    this.customPoiActive = false;
    this.colorShowPicker = false;
    this.formOpen = false;

    if(this.formAnnotationPano.value.type === 'poi') {
      this.krpanoService.hotspotMove(this.formAnnotationPano.value.name, false);
    }

    const pos = this.krpanoService.hotspotGetPosition(this.formAnnotationPano.value.name);

    let hotspotValues = null;
    if (this.type === 'poi') {
      hotspotValues = {
        alpha: Number(this.formAnnotationPano.value.alpha),
        ath: Number(pos.ath),
        atv: Number(pos.atv),
        color: this.color,
        icon: this.formAnnotationPano.value.icon,
        name: this.formAnnotationPano.value.name,
        openURL: this.formAnnotationPano.value.openURL,
        imageID: this.formAnnotationPano.value.imageID,
        scale: Number(this.formAnnotationPano.value.scale),
        tooltip: this.formAnnotationPano.value.tooltip,
        type: this.formAnnotationPano.value.type
      };
    } else if (this.type === 'polygon') {
      const points = this.krpanoService.polygonGetPoints(this.formAnnotationPano.value.name)

      hotspotValues = {
        alpha: Number(this.formAnnotationPano.value.alpha),
        color: this.color,
        points: points,
        name: this.formAnnotationPano.value.name,
        openURL: this.formAnnotationPano.value.openURL,
        //imageID: this.formAnnotationPano.value.imageID,
        tooltip: this.formAnnotationPano.value.tooltip,
        type: this.formAnnotationPano.value.type
      };
    }
    // Added for loop to reduce the number of mongo entries
    Object.keys(hotspotValues).forEach(key => {
      if (hotspotValues[key] === '' || hotspotValues[key] === 1 || hotspotValues[key] === null) {
        if (key !== 'alpha') {
          delete hotspotValues[key];
        }
      }
    });
    if (this.formAnnotationPano.value.index === -1) {
      if (!this.fileModel.pano) {
        this.fileModel.pano = {}
      }
      if (!this.fileModel.pano.hotspots) {
        this.fileModel.pano.hotspots = []
      }
      this.fileModel.pano.hotspots.push(hotspotValues)
    } else {
      this.fileModel.pano.hotspots[this.formAnnotationPano.value.index] = hotspotValues
    }
    this.color = this.colorsProposals[0];

    this.setUpdate()
    this.colorsList();
    setTimeout(() => {
      this.scrollTop();
      this.resetForm();
    }, 1);
  }

  scrollTop(): void {
    this.annotationsBody.nativeElement.scrollTop = 0
  }

  polygonFillColor(annotation): string {
    let fill = 0.5;
    if (annotation.alpha !== undefined) {
      fill = annotation.alpha
    }
    if(annotation.color) {
      return `rgba(${this.hexToRGB(annotation.color,0)}, ${this.hexToRGB(annotation.color,1)}, ${this.hexToRGB(annotation.color,2)}, ${fill})`;
    }
    return '#53626280';
  }
  polygonBorderColor(annotation): string {
    if(annotation.color) {
      return annotation.color;
    }
    return '#536262';
  }

  hexToRGB(hex, place){
    hex = hex.replace('#', '')
    var aRgbHex = hex.match(/.{1,2}/g);

    return parseInt(aRgbHex[place], 16)
}



  scrollDetector(): void {
    setTimeout(() => {
      if (
          this.annotationsBody.nativeElement.scrollHeight <= this.annotationsBody.nativeElement.clientHeight
      ) {
        this.scrolled = true;
      } else {
        if( this.annotationsBody.nativeElement.scrollTop > 10 ) {
          this.scrolled = true;
        } else {
          this.scrolled = false;
        }
      }
    }, 250);
  }

  selectFileDialog(): void {
    const dialogRef = this.dialog.open(FilesSelectDialogComponent, {
      width: '720px',
      data: {
        'folderID': this.fileModel.folderID,
        'acceptMimeType': ['image/jpeg', 'application/vnd.folder'],
        //'acceptTag': ['other'],
        'uploadAcceptType': ['image/jpeg'],
        'allowUpload': true,
        'fakeFolder': 'Images for panorama',
      }
    });
    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined && dialog !== '') {
        this.loadImage(dialog.id)
      } else {
        if (!this.imageURLView) {
          this.formAnnotationPano.get('isCheckedImageURL').setValue(false);
        }
      }
    });
  }

  setFile(): void {
    this.poisCustom = [];
    this.colorsList();
    this.poisTag = '';
    if (this.fileModel.tags) {
      this.fileModel.tags.forEach(tag => {
        if (tag.toLowerCase().startsWith('pois:')) {
          this.poisTag = tag.replace('pois:','')
        }
      });
      this.fileModel.tags.forEach(tag => {
        if (tag.endsWith(':customForm')){
          this.customForm = true;
          tag = tag.replace(':customForm','')
        }
        if (tag.toLowerCase().startsWith('poi:')) {
          if(tag.includes('[') && tag.includes(']')) {
            const minMax = tag.split('[')[1].split(']')[0].split('-');
            const name = tag.replace('poi:','').split('[')[0];
            for (let i = Number(minMax[0]); i <= Number(minMax[1]); i++) {
              if(!this.poisCustom.includes(name + '_' + i)) {
                this.poisCustom.push(name + '_' + i);
              }
            }
          }
          else if (!this.poisCustom.includes(tag.replace('poi:',''))){
            this.poisCustom.push(tag.replace('poi:',''))
          }
        }
      });
    }
    this.resetForm();
  }

  setUpdate(): void {
    const fileUpdates: FileModel = {};
    if (this.fileModel && this.fileModel._id) { Object.assign(fileUpdates, { _id: this.fileModel._id }); }
    Object.assign(fileUpdates, { pano: this.getChanges() });

    this.sidebarService.updateOne(fileUpdates, this.fileModel);
  }

  toggleCustomMarker(): void {
    const icon = this.formAnnotationPano.value.icon
    if (icon.includes(':')) {
      const customIcon = icon.split(':')
      this.updateIcon(customIcon[1])
    } else {
      this.updateIcon(icon)
    }
  }

  updateImageURL(event: any): void {
    this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'imageID', event.target.value);
  }

  updateIcon(key: string): void {
    this.customPoiActive = false;
    this.krpanoService.updateSelector(this.customPoiActive && this.customForm);
    if (this.isCheckedCustomMarker()) {
      key = this.poisTag + ':' + key;
    }
    this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'url', key, this.poisTag, this.color);
    this.formAnnotationPano.controls.icon.setValue(key);
  }

  updateIconCustom(key: string): void {
    this.customPoiActive = true;
    this.krpanoService.updateSelector(this.customPoiActive && this.customForm);
    if (this.poisTag !== '') {
      key = this.poisTag + ':' + key;
    }
    this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'url', 'custom:'+key, this.poisTag, this.color);
    this.formAnnotationPano.controls.icon.setValue(key);
  }

  updateScale(event: any): void {
    this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'scale', event.value);
  }

  updateTooltip(event: any): void {
    this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'tooltip', event.target.value);
  }

  updateTransparency(event: any): void {
    if (this.type === 'poi') {
      this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'alpha', event.value);
    } else if (this.type === 'polygon') {
      this.krpanoService.hotspotUpdate(this.formAnnotationPano.value.name, 'fillalpha', event.value);
    }
  }
}
