import { Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { AccountType, User } from 'src/app/pages/users/models/user.model';
import { UsersService } from 'src/app/pages/users/users.service';
import { debounceTime, distinctUntilChanged, switchMap, takeUntil } from 'rxjs/operators';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { LoginStateService } from 'src/app/pages/login/login-state.service';
import { ThemeService } from '../theme/theme.service';
import { OrdersService } from '../orders/orders.service';
import { PortfolioService } from '../portfolio/portfolio.service';
import { CollaborationsService } from 'src/app/pages/files/file/sidebar/collaborations/collaborations.service';
import { Collaboration } from 'src/app/pages/files/file/sidebar/collaborations/collaboration.model';
import { customEmailValidator, userSearchFilter } from '../helpers/data-helpers';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-add-modify-collaborators-dialog',
  templateUrl: './add-modify-collaborators-dialog.component.html',
  styleUrls: ['./add-modify-collaborators-dialog.component.scss']
})
export class AddModifyCollaboratorsDialogComponent implements OnInit, OnDestroy {
  private ngDestroy$ = new Subject<void>();
  action = 'INVITE'; // This should be set based on the trigger
  resource = { 
    "order": undefined, // { type: 'Order', name: '{{Order_id}}', id: '111111111111111'},
    "site": undefined, // { type: 'Site', name: '{{Site_Name}}', id: '222222222222222' },
    "portfolio": undefined // { type: 'Portfolio', name: '{{Portfolio_Name}}', id: '333333333333333'}
  };
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  roles = [
    { value: 'viewer', label: 'Viewer', hint:'Read Only Permissions' },
    { value: 'editor', label: 'Editor', hint:'Read, Edit, and (Delete annotations and measurements only) Permissions' },
    //{ value: 'coordinator', label: 'Coordinator', hint:'Read, Edit, Share and (Delete annotations and measurements only) Permissions' }
  ];
  collaborationResourceOptions = [];
  users$: BehaviorSubject<string> = new BehaviorSubject("");
  selectedResource:any;
  selectedRole: any;
  usersAutocompleteOptions: Array<User> = [];
  selectedUsers = []
  restriction = false
  theme: string;
  langTranslations: any = undefined;
  public get userInput(): AbstractControl { return this.userForm.get('userInput'); }
  public get expirationDate(): AbstractControl { return this.userForm.get('expirationDate'); }
  public get notifyCollaborators(): AbstractControl { return this.userForm.get('notifyCollaborators'); }
  userForm: FormGroup;
  accountType = AccountType

  get translatedTitle(): string {
    const actionKey = this.action === "INVITE" ? "INVITE" : "MODIFY";
    const collaboratorKey = this.selectedUsers?.length <= 1 ? "COLLABORATOR" : "COLLABORATORS";
    const resourceKey = this.selectedResource?.value?.toUpperCase() || "";
  
    return `COLLABORATION.POPUP.TITLE.${actionKey}_${collaboratorKey}_${resourceKey}`;
  }

  get notificationKey(): string {
    const collaboratorKey = this.selectedUsers?.length <= 1 ? 'COLLABORATOR' : 'COLLABORATORS';
    return `COLLABORATION.POPUP.NOTIFY_${collaboratorKey}`;
  }

  get informationKey(): string {
    const userKey = this.selectedUsers?.length <= 1 ? 'USER' : 'USERS';
    const roleKey = this.selectedRole?.value?.toUpperCase() || '';
    const resourceKey = this.selectedResource?.value?.toUpperCase() || '';
  
    return `COLLABORATION.POPUP.INFORMATION.${userKey}_${roleKey}_${resourceKey}`;
  }

  get actionKey(): string {
    const actionType = this.action === 'INVITE' ? 'INVITE' : 'MODIFY';
    const collaboratorKey = this.selectedUsers?.length <= 1 ? 'COLLABORATOR' : 'COLLABORATORS';
  
    return `COLLABORATION.ACTION.${actionType}_${collaboratorKey}`;
  }




  constructor(
    public dialogRef: MatDialogRef<AddModifyCollaboratorsDialogComponent>,
    private usersService: UsersService, 
    public loginStateService: LoginStateService,
    private themeService: ThemeService,
    private ordersService: OrdersService,
    private portfoliosService: PortfolioService,
    private collaborationsService: CollaborationsService,
    private fb: FormBuilder,
    private translate: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: any
    ) 
  {
    this.userForm = this.fb.group({
      userInput: new FormControl('', [customEmailValidator]),
      expirationDate: new FormControl('', [Validators.required, this.expirationDateValidator]),
      notifyCollaborators: new FormControl(false)
    });

    this.translate.get(['COLLABORATION']).subscribe((translation) => {
      this.langTranslations = translation
    })
  }

  ngOnInit(): void {
    this.initPossibleResources().then(() => {
      this.enablePossibleResourcesOption();
    })
    this.selectRole(this.roles[0]) 
    
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    // Searching Users is only allowed for Admins and SuperAdmins
    if (this.loginStateService.loggedUser$.value?.accountType === 'admin' || this.loginStateService.loggedUser$.value?.accountType === 'superAdmin') {
      this.userForm.get('userInput').valueChanges
        .pipe(
          takeUntil(this.ngDestroy$),
          debounceTime(300),
          distinctUntilChanged(),
          switchMap((value) => {
            const pageSize = 10
            const cursor = 0
            const filter = userSearchFilter("query:" + value)
            const fields = { avatarIconURL: 1, email: 1, name: 1, surname: 1, organization: 1 };
            return this.usersService.findMany(filter, null, cursor, pageSize, fields)
          })
        )
        .subscribe(users => {
          this.usersAutocompleteOptions = users.data;
        });
    }
  }

  // Custom validator for expiration date
  private expirationDateValidator(control: AbstractControl) {
    const selectedDate = new Date(control.value);
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    
    return selectedDate >= tomorrow ? null : { invalidDate: 'The expiration date must be at least tomorrow.' };
  }

  private initPossibleResources(): Promise<void> {
    return new Promise((resolve) => {
      if (this.ordersService.activeOrder$.value) {
        this.resource.order = {
          "type": 'Order', 
          "name": "FF" + this.ordersService.activeOrder$.value.legacyId, 
          "id": this.ordersService.activeOrder$.value._id
        };
        // Check if siteId exists
        if (this.ordersService.activeOrder$.value.siteId) {
          this.portfoliosService.getSiteById(this.ordersService.activeOrder$.value.siteId as string).subscribe(
            (site) => {
              if (site.data) {
                this.resource.site = {"type": 'Site', "name": site.data.name, "id": site.data._id};
                // Check if portfolioId exists
                if (site.data.portfolioId) {
                  this.portfoliosService.getPortfolioById(site.data.portfolioId as string).subscribe(
                    (portfolio) => {
                      if (portfolio) {
                        this.resource.portfolio = {"type": 'Portfolio', "name": portfolio.data.name, "id": portfolio.data._id};
                        resolve(); // Resolve the promise here if everything is loaded
                      } else {
                        resolve(); // Resolve if no portfolioId found on mongo even though the site was linked to a portfolio
                      }
                    },
                    error => {resolve();}
                  );
                } else {
                  resolve(); // Resolve if site is not linked to a portfolio
                }
              } else {
                resolve();  // Resolve if no site is found in mongo even though the order was linked to a site
              }
            }
          );
        } else {
          resolve(); // Resolve if no siteId
        }
      } else {
        console.log(this.data)
        resolve(); // Resolve if no activeOrder$
      }

      if (this.data.portfolio) {
        this.resource.portfolio = {"type": 'Portfolio', "name": this.data.portfolio.name, "id":this.data.portfolio._id};
        resolve();
      }

      if (this.data.site) {
        this.resource.portfolio = {"type": 'Site', "name": this.data.site.name, "id": this.data.site._id};
        resolve();
      }
    });
  }

  private enablePossibleResourcesOption() {
    if (this.resource.order) {
      this.collaborationResourceOptions.push({ value: 'order', label: 'Entire Order', hint: 'Access to all folders and files.' })
    }
    if (this.resource.site) {
      this.collaborationResourceOptions.push({ value: 'site', label: 'Entire Site', hint: 'Access to all orders, folders, and files.' })
    }
    if (this.resource.portfolio) {
      this.collaborationResourceOptions.push({ value: 'portfolio', label: 'Entire Portfolio', hint: 'Access to all sites, orders, folders, and files.' })
    }

    this.selectResource (this.collaborationResourceOptions[0])
  }

  removeUser(user){
    const index = this.selectedUsers.findIndex(selectedUser => selectedUser.email === user.email);
    if (index > -1) {
      this.selectedUsers.splice(index, 1);
    }
  }

  closeUserAutocompletePanel() {
    if (this.autocompleteTrigger) {
      this.autocompleteTrigger.closePanel();
    }
  }

  correctInput():boolean {
    const list = Boolean(this.selectedUsers?.length !== 0)
    const input = Boolean(this.userInput.value)
    const error = Boolean(this.userInput.errors)

    return (this.selectedUsers?.length === 0 && (!this.userInput.value || Boolean(this.userInput.errors)))
  }

  addUser(email): void {
    if (email && !this.userInput.errors) {
      this.selectedUsers.push({email:email})
      this.usersClear();
    }
  }

  userChange(value: string): void {
    const userInputControl = this.userForm.get('userInput');
    const loggedUserEmail = this.loginStateService.loggedUser$.value.email;
    const selectedUsers = this.selectedUsers;

    if (!userInputControl.valid) {
      userInputControl.setErrors({'invalid': this.langTranslations['COLLABORATION']['HINT_INPUT']['INVALID_EMAIL_FORMAT']});
    } else if (this.userInput.value === loggedUserEmail) {
      userInputControl.setErrors({'invalid': this.langTranslations['COLLABORATION']['HINT_INPUT']['YOUR_EMAIL']} );
    } else if (selectedUsers.some(selectedUser => selectedUser.email === this.userInput.value)) {
      userInputControl.setErrors({'invalid': this.langTranslations['COLLABORATION']['HINT_INPUT']['EMAIL_ADDED']})
    } else {
      userInputControl.setErrors(null);
    }
  }

  usersClear(): void {
    this.userInput.setValue('')
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
  }

  selectRole(role: any) {
    if ( role.value!== 'coordinator' ) {
      this.restriction = false
    }
    this.selectedRole = role; // Set the selected role
  }

  selectResource(resource: any) {
    this.selectedResource = resource; // Set the selected role
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  handleKeydown(event: KeyboardEvent): void {
    if ([AccountType.ADMIN,AccountType.SUPERADMIN].includes(this.loginStateService.loggedUser$.value?.accountType)) {
      if (event.key === 'Enter' || event.key === ',') {
        event.preventDefault(); // Prevent the default action for Enter and comma keys
        this.addUser((event.target as HTMLInputElement).value);
      }
    } else {
      if (event.key === 'Enter' || event.key === ',' || event.key === ' ' ) {
        event.preventDefault(); // Prevent the default action for Enter and comma keys
        this.addUser((event.target as HTMLInputElement).value);
      }
    }
  }

  addUserFromAutoComplete(value) {
  }

  addCollaborators(): void { 
    let userEmails = []
    if (this.selectedUsers.length > 0) {
      this.selectedUsers.forEach((user)=> {
        userEmails.push(user.email)
      })
    } else {
      userEmails.push(this.userInput.value)
    }
    
    const collaborationRequest = {
      resource: {
        type: this.selectedResource.value,
        id: this.resource[this.selectedResource.value].id
      },
      emails: userEmails,
      options: {
        role: this.selectedRole.value,
        restrictInheritance: this.restriction
      },
      expiresAt: this.expirationDate.value? this.expirationDate.value.toISOString() : this.getExpirationDate(7,this.ordersService.activeOrder$.value?.dateSetToVerify),
      notifyCollaborators: this.notifyCollaborators.value
    }

    this.collaborationsService.insert(collaborationRequest).pipe(takeUntil(this.ngDestroy$)).subscribe(
      (response: any) => {
        this.collaborationsService.pushNewCollaborations(response.data || [] as Collaboration[])
        const result = {"status": true,"requestInput":userEmails.length,"requestOutput":response.data?.length || 0}
        this.dialogRef.close(result);
      },
      (error) => {
        const result = {"status": false, "errorMessage":error.error.errors[0]}
        this.dialogRef.close(result);
      }
    )
  }

  getExpirationDate(days: number, dateSetToVerify: Date): string {
    const dateToVerify = new Date(dateSetToVerify);
    const today = new Date();
    const todayPlusDays = new Date(today);
    todayPlusDays.setDate(todayPlusDays.getDate() + days);


    if (!dateSetToVerify) {
      return todayPlusDays.toISOString();
    } else {
      const orderExpirationDate = new Date(dateToVerify);
      orderExpirationDate.setDate(orderExpirationDate.getDate() + 90);
      const timeDiff = orderExpirationDate.getTime() - today.getTime();
      const daysLeftInOrder = Math.ceil(timeDiff / (1000 * 3600 * 24));
      let ownerOfResource: User;
      const loggedUser = this.loginStateService.loggedUser$.value;
      const resource = this.ordersService.activeOrder$.value

      if ([AccountType.ADMIN, AccountType.SUPERADMIN].includes(loggedUser.accountType)) {
        this.usersService.findOne(resource?._id).pipe(takeUntil(this.ngDestroy$)).subscribe(
          (response: any) => {
            ownerOfResource = response.data;
            if (ownerOfResource.subscription?.length > 0) {
              return todayPlusDays.toISOString();
            } else {
              return orderExpirationDate.toISOString();
            }
          }
        )
      } else if (loggedUser.accountType === AccountType.CLIENT) {
        if (resource.clientId === loggedUser._id) { // I am the Owner
          if (daysLeftInOrder > days || loggedUser.subscription?.length > 0) { 
            return todayPlusDays.toISOString();
          } else {
            return orderExpirationDate.toISOString();
          }
        } else { // I am a Coordinator
          // TODO: implement logic for Coordinator to give access based on the collaboration.expirationDate he has with the owner of the resource
        }
      } 
    }
  }

}