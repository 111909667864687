import { Component, OnInit, Renderer2, ViewEncapsulation, OnDestroy, ViewChild } from '@angular/core';
import { Feature, Map } from 'ol';
import { OlMapComparisonService } from './ol-map-comparison.service';
import { OLMapsService } from 'src/app/shared/openlayers/maps.service';
import { OLLayerService } from 'src/app/shared/openlayers/layer.service';
import WebGLTileLayer from 'ol/layer/WebGLTile';
import { ThemeService } from 'src/app/shared/theme/theme.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Style } from 'ol/style';
import { Geometry, LineString, Polygon } from 'ol/geom';
import { Draw, Modify } from 'ol/interaction.js';
import { DEFAULT_ACTIVE_BLUE_STYLE, DEFAULT_LIGHT_BLUE_STYLE, HIGHLIGHT_ACTIVE_BLUE_STYLE, HIGHLIGHT_LIGHT_BLUE_STYLE, VERTEX_STYLE } from 'src/app/shared/openlayers/openlayers-constants';
import Collection from 'ol/Collection.js';
import { SidenavNavigationService } from 'src/app/shared/sidenav/sidenav-navigation/sidenav-navigation.service';
import { MatSidenav } from '@angular/material/sidenav';
import { Measurement, VolumeApproach } from '../files/file/sidebar/measurements/measurement.model';
import { MeasurementsService } from '../files/file/sidebar/measurements/measurements.service';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartOptions } from 'chart.js';
import { easeOut } from 'ol/easing';
import { getLength, getArea } from 'ol/sphere';
import { formatDate } from 'src/app/shared/helpers/data-helpers';
import { containsCoordinate } from 'ol/extent';
import { DialogService } from 'src/app/shared/dialog/dialog.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-ol-map-comparison',
  templateUrl: './ol-map-comparison.component.html',
  styleUrls: ['./ol-map-comparison.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class OlMapComparisonComponent implements OnInit, OnDestroy {

  public orders: any[] = [];
  public selectedLeftOrder: any;
  public selectedRightOrder: any;
  public showSaveIcon = false
  public showVolumeTooltip: boolean = false;
  public clickedFeature: Feature | null = null;
  public theme: string;
  public isDrawAreaActive = false;
  public isDrawLengthActive = false;
  public isDrawVolumeActive = false;
  public myLineChart: Chart | null = null;

  private map: Map;
  private leftOrthoLayer: WebGLTileLayer;
  private leftDSMLayer: WebGLTileLayer;
  private rightOrthoLayer: WebGLTileLayer;
  private rightDSMLayer: WebGLTileLayer;
  private ngDestroy$ = new Subject();
  private measurementLayer: VectorLayer<any>;
  private measurementSource: VectorSource;
  private modifyInteraction: Modify;
  private hoveredFeature: Feature | null = null;
  private Vocab = undefined;
  private isMapMoving: boolean = false;
  private drawingStarted = false

  @ViewChild('sidenav') sidenav: MatSidenav;

  private swipeLineMouseDownListener: () => void;
  private mapElementMouseMoveListener: () => void;
  private documentMouseUpListener: () => void;

  constructor(
    private olMapComparisonService: OlMapComparisonService,
    private oLMapsService: OLMapsService,
    private translate: TranslateService,
    private oLLayerService: OLLayerService,
    private renderer: Renderer2,
    private themeService: ThemeService,
    private sidenavNavigationService: SidenavNavigationService,
    private measurementsService: MeasurementsService,
    private dialogService: DialogService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
      const root = document.documentElement;
      if (theme.includes('dark')) {
        root.classList.add('dark-theme');
      } else {
        root.classList.remove('dark-theme');
      }
    });

    this.translate.get(['MEASURE']).subscribe(translations => {
      this.Vocab = translations
    })

    this.router.events
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (data instanceof NavigationEnd) {
        const siteId = this.route.snapshot.parent.children[0].params.siteId;
        this.olMapComparisonService.getOrthos(siteId).subscribe((response: any) => {
          this.orders = response.data;
          this.initMap();

          this.initializeSwipe();

          this.map.on('click', this.onMapClick.bind(this));
          this.map.on('pointermove', this.onPointerMove.bind(this));
        });
      }
    })
  }

  ngOnInit(): void {
    this.sidenavNavigationService.close();
  }

  ngOnDestroy(): void {
    this.swipeLineMouseDownListener();
    this.mapElementMouseMoveListener();
    this.documentMouseUpListener();

    this.ngDestroy$.next();
    this.ngDestroy$.complete();

    this.disableModification();

    this.map.un('click', this.onMapClick);
    this.map.un('pointermove', this.onPointerMove);

    if (this.map) {
      this.map.getLayers().forEach(layer => this.map.removeLayer(layer));
      this.map.getInteractions().forEach(interaction => this.map.removeInteraction(interaction));
      this.map.setTarget(null);
    }

    this.oLMapsService.mapReady$.next(undefined);

    if (this.measurementLayer) {
      this.map.removeLayer(this.measurementLayer);
      this.measurementLayer.getSource().clear();
      this.measurementLayer = null;
    }

    this.map = null;
    this.measurementSource = null;
    this.leftOrthoLayer = null;
    this.leftDSMLayer = null;
    this.rightOrthoLayer = null;
    this.rightDSMLayer = null;
    this.modifyInteraction = null;
    this.clickedFeature = null;
    this.hoveredFeature = null;
  }

  // --------------------- Map and Layers ------------------
  private initMap(): void {
    this.map = this.oLMapsService.renderMap({ target: document.querySelector('#map') });
    this.updateSize();

    if ( this.orders[0] ) {
      this.leftDSMLayer = this.oLLayerService.addDSMWebGLTileLayer(this.map, this.orders[0].orthos[0]?.dsmFileModel, 'left DSM layer');
      this.leftDSMLayer.setOpacity(0);
      this.leftOrthoLayer = this.oLLayerService.addOrthoWebGLTileLayer(this.map, this.orders[0].orthos[0], 'left ortho layer');
      this.selectedLeftOrder = this.orders[0];
      this.leftOrthoLayer.getSource().getView().then((viewOptions) => this.map.getView().fit(viewOptions.extent));
    }

    if ( this.orders[1] ) {
      this.rightDSMLayer = this.oLLayerService.addDSMWebGLTileLayer(this.map, this.orders[1].orthos[0]?.dsmFileModel, 'right DSM layer');
      this.rightOrthoLayer = this.oLLayerService.addOrthoWebGLTileLayer(this.map, this.orders[1].orthos[0], 'right ortho layer');
      this.rightDSMLayer.setOpacity(0);
      this.selectedRightOrder = this.orders[1];
    }


    this.initMeasurementLayer()
    if (this.orders[1].orthos[0].measurements) {
      this.addMeasurementsToLayer( this.orders[1].legacyId, this.orders[1].orthos[0].measurements, DEFAULT_LIGHT_BLUE_STYLE)
    }
    if ( this.orders[0].orthos[0].measurements?.length > 0 ) {
      this.addMeasurementsToLayer( this.orders[0].legacyId, this.orders[0].orthos[0].measurements, DEFAULT_ACTIVE_BLUE_STYLE)
    }

    this.onMapMoving()
  }

  private initMeasurementLayer(): void {
    this.measurementSource = new VectorSource();
    this.measurementLayer = new VectorLayer({
      source: this.measurementSource,
      className: 'measurements',
      zIndex:2
    });
    this.map.addLayer(this.measurementLayer);
  }

  private addMeasurementsToLayer(legacyId: string ,measurements: any[], featureStyle: Style): void {
    if (measurements) {
      measurements.forEach(measurement => {
        this.AddOneMeasurementToLayer(legacyId ,measurement, featureStyle)
      });
    }
  }

  private AddOneMeasurementToLayer(legacyId: string ,measurement: any, featureStyle: Style) {
    let feature: Feature;
    const coords = measurement.location.coordinates[0];
    if (measurement.location.type === 'Polygon'){
      const polygon = new Polygon([coords]);
      feature = new Feature({
        geometry: polygon,
        name: `${legacyId} - ${measurement._id}`,
      });
    } else if ( measurement.location.type === 'LineString' ) {
      const lineString = new LineString(measurement.location.coordinates);
      feature = new Feature({
        geometry: lineString,
        name: `${legacyId} - ${measurement._id}`,
      });
    }

    feature.setStyle(featureStyle);
    feature.set('legacyId', legacyId)
    feature.set('measurement', measurement)
    feature.set('saved', true)

    this.measurementSource.addFeature(feature);
  }

  private applySwipeAfterMove(): void {
    const swipeLine = document.querySelector('.swipe-line') as HTMLElement;
    const mapRect = document.getElementById('map').getBoundingClientRect();
    const swipePosition = swipeLine.offsetLeft;
    const swipeRatio = swipePosition / mapRect.width;
    this.updateSwipeLayers(swipeRatio);
  }

  private updateSwipeLayers(swipeRatio: number): void {
    const mapSize = this.map.getSize();
    if (!mapSize) return;

    const mapExtent = this.map.getView().calculateExtent(mapSize);
    const leftExtent = [...mapExtent];
    const rightExtent = [...mapExtent];

    leftExtent[2] = leftExtent[0] + (mapExtent[2] - mapExtent[0]) * swipeRatio;
    rightExtent[0] = leftExtent[2];

    this.leftOrthoLayer.setExtent(leftExtent);
    this.rightOrthoLayer.setExtent(rightExtent);

    this.map.render();
  }

  private disableModification(): void {
    if (this.modifyInteraction) {
      this.map.removeInteraction(this.modifyInteraction);
      this.modifyInteraction = null;
    }
  }

  private highlightFeature(feature: Feature): void {
    if (feature.get('legacyId') === this.selectedLeftOrder.legacyId) {
      feature.setStyle([HIGHLIGHT_ACTIVE_BLUE_STYLE, VERTEX_STYLE]);
    } else if (feature.get('legacyId') === this.selectedRightOrder.legacyId) {
      feature.setStyle([HIGHLIGHT_LIGHT_BLUE_STYLE, VERTEX_STYLE]);
    } else {
      feature.setStyle([HIGHLIGHT_ACTIVE_BLUE_STYLE, VERTEX_STYLE]);
    }
  }

  private clearHighlight(): void {
    if (this.clickedFeature) {
      if (this.clickedFeature.get('legacyId') === this.selectedLeftOrder.legacyId) {
        this.clickedFeature.setStyle(DEFAULT_ACTIVE_BLUE_STYLE);
      } else if (this.clickedFeature.get('legacyId') === this.selectedRightOrder.legacyId) {
        this.clickedFeature.setStyle(DEFAULT_LIGHT_BLUE_STYLE);
      }
      this.clickedFeature = null;
    }
  }

  private applyHoverStyle(feature: Feature): void {
    if (feature !== this.clickedFeature) {
      if (feature.get('legacyId') === this.selectedLeftOrder.legacyId) {
        feature.setStyle(HIGHLIGHT_ACTIVE_BLUE_STYLE);
      } else if (feature.get('legacyId') === this.selectedRightOrder.legacyId) {
        feature.setStyle(HIGHLIGHT_LIGHT_BLUE_STYLE);
      } else {
        feature.setStyle(HIGHLIGHT_ACTIVE_BLUE_STYLE);
      }
    }
  }

  private restoreStyle(feature: Feature): void {
    if (feature.get('legacyId') === this.selectedLeftOrder.legacyId) {
      feature.setStyle(DEFAULT_ACTIVE_BLUE_STYLE);
    } else if (feature.get('legacyId') === this.selectedRightOrder.legacyId) {
      feature.setStyle(DEFAULT_LIGHT_BLUE_STYLE);
    }
  }

  private updateSize(): void {
    this.map.updateSize();
    setTimeout(() => {
      this.map.updateSize();
    }, 500);
  }

  private initializeSwipe(): void {
    const swipeLine = document.querySelector('.swipe-line') as HTMLElement;
    const mapElement = document.getElementById('map');
    let isDragging = false;

    this.swipeLineMouseDownListener = this.renderer.listen(swipeLine, 'mousedown', () => {
      isDragging = true;
    });

    this.mapElementMouseMoveListener = this.renderer.listen(mapElement, 'mousemove', (event: MouseEvent) => {
      if (isDragging) {
        const mapRect = mapElement.getBoundingClientRect();
        const x = event.clientX - mapRect.left;
        if (x >= 0 && x <= mapRect.width) {
          swipeLine.style.left = `${x}px`;
          this.updateSwipeLayers(x / mapRect.width);
        }
      }
    });

    this.documentMouseUpListener = this.renderer.listen(document, 'mouseup', () => {
      isDragging = false;
    });
  }

  private enableModification(feature: Feature): void {
    if (this.modifyInteraction) {
      this.map.removeInteraction(this.modifyInteraction);
    }

    this.modifyInteraction = new Modify({
      features: new Collection([feature]),
      style: new Style({})
    });

    this.map.addInteraction(this.modifyInteraction);

    this.modifyInteraction.on('modifyend', (event) => {
      const modifiedFeature = event.features.getArray()[0];
      if (modifiedFeature) {
        this.map.getView().fit(modifiedFeature.getGeometry().getExtent(),{padding:[60, 60, 60, 60],maxZoom:50,duration:500,easing:easeOut})
        modifiedFeature.get('measurement').right = {}
        modifiedFeature.get('measurement').left = {}
        modifiedFeature.set('saved',false)
        this.updateMeasurement(
          modifiedFeature.get('measurement'),
          modifiedFeature.getGeometry()
        );
      }
    });
  }

  private resetBrightness() {
    this.leftOrthoLayer.setStyle({exposure: 0});
    this.rightOrthoLayer.setStyle({exposure: 0});
  }

  // --------------------- Handlers and Listeners ------------------
  public onVolumeApproachChange(event: any): void {
  }

  private onMapMoving(): void {
    this.map.on('movestart', () => {
      this.isMapMoving = true;
    });

    this.map.on('pointermove', () => {
      if (this.isMapMoving) {
        this.applySwipeAfterMove();
      }
    });

    this.map.on('postrender', () => {
      if (this.isMapMoving) {
        this.applySwipeAfterMove();
      }
    });

    this.map.on('moveend', () => {
      this.isMapMoving = false;
      this.applySwipeAfterMove();
    });
  }

  public onElevationChange(customElevation: any): void {
    this.clickedFeature.get('measurement')['right']['volumeCustomElevation'] = undefined
    this.clickedFeature.get('measurement')['left']['volumeCustomElevation'] = undefined

    const pixelsLeftDSM = this.oLLayerService.extractInteriorPixelsOfExtent(this.map, this.clickedFeature.getGeometry() as Polygon, this.leftDSMLayer);
    const pixelsRightDSM = this.oLLayerService.extractInteriorPixelsOfExtent(this.map, this.clickedFeature.getGeometry() as Polygon, this.rightDSMLayer);
    const elevationValuesRight = this.oLLayerService.extractElevations(pixelsRightDSM);
    const elevationValuesLeft = this.oLLayerService.extractElevations(pixelsLeftDSM);
    this.clickedFeature.get('measurement').pixelSurface = this.measurementsService.getPixelSurface(pixelsLeftDSM, this.clickedFeature.get('measurement').value2d);
    this.clickedFeature.get('measurement')['right']['volumeCustomElevation'] = this.measurementsService.calculateVolumeWithCustomElevation(elevationValuesRight, customElevation, this.clickedFeature.get('measurement').pixelSurface, this.clickedFeature.get('measurement')['right']['elevationsMax']);
    this.clickedFeature.get('measurement')['left']['volumeCustomElevation'] = this.measurementsService.calculateVolumeWithCustomElevation(elevationValuesLeft, customElevation, this.clickedFeature.get('measurement').pixelSurface, this.clickedFeature.get('measurement')['right']['elevationsMax']);
  }

  public onTitleChange(): void {
    this.showSaveIcon = true;
  }

  private onPointerMove(event: any): void {
    if (!this.isDrawAreaActive && !this.isDrawLengthActive && !this.isDrawVolumeActive) {
      const pixel = this.map.getEventPixel(event.originalEvent);
      const features = this.map.getFeaturesAtPixel(pixel);

      if (features && features.length > 0) {
        const feature = features[0] as Feature<Geometry>;
        this.map.getTargetElement().style.cursor = 'pointer';
        if (feature instanceof Feature && feature !== this.clickedFeature && this.hoveredFeature !== feature) {
          if (this.hoveredFeature && this.hoveredFeature !== this.clickedFeature) {
            this.restoreStyle(this.hoveredFeature);
          }
          this.hoveredFeature = feature;
          this.applyHoverStyle(feature);
        }
      } else {
        if (this.hoveredFeature && this.hoveredFeature !== this.clickedFeature) {
          this.restoreStyle(this.hoveredFeature);
          this.hoveredFeature = null;
        }
        this.map.getTargetElement().style.cursor = '';
      }
    }

    if ((this.isDrawAreaActive || this.isDrawLengthActive || this.isDrawVolumeActive) && !this.drawingStarted ) {
      const mapElement = document.getElementById('map');
      const mapRect = mapElement.getBoundingClientRect();
      const swipeLine = document.querySelector('.swipe-line') as HTMLElement;
      const swipePosition = swipeLine.offsetLeft;

      const isHoveringLeft = event.originalEvent.clientX < mapRect.left + swipePosition;
      const isHoveringRight = event.originalEvent.clientX >= mapRect.left + swipePosition;

      if (isHoveringLeft) {
        this.leftOrthoLayer.setStyle({exposure: 0});
        this.rightOrthoLayer.setStyle({exposure: -0.4});
      } else if (isHoveringRight) {
        this.leftOrthoLayer.setStyle({exposure: -0.4});
        this.rightOrthoLayer.setStyle({exposure: 0});
      }
    }

  }

  private onMapClick(event: any): void {
    if (!this.isDrawAreaActive && !this.isDrawLengthActive && !this.isDrawVolumeActive) { //
      const pixel = event.pixel;
      const features = this.map.getFeaturesAtPixel(pixel);

      if (features && features.length > 0) {
        const newClickedFeature = features[0] as Feature;

        if (this.hoveredFeature && this.hoveredFeature !== newClickedFeature) {
          this.restoreStyle(this.hoveredFeature);
          this.hoveredFeature = null;
        }

        this.FocusOnFeatureAndMeasure(newClickedFeature)

      } else {
        this.unselectMeasurement()
      }
    }
  }

  public onLeftOrderChange(order: any): void {
    this.removeMeasurementsByLegacyId(this.selectedLeftOrder?.legacyId)
    this.oLLayerService.updateDSMWebGLTileLayerSource(this.leftDSMLayer, order.orthos[0]?.dsmFileModel.webViewLink)
    this.oLLayerService.updateWebGLTileLayerSource(this.leftOrthoLayer, order.orthos[0].webViewLink)
    this.selectedLeftOrder = order;
    this.addMeasurementsToLayer(order.legacyId, order.orthos[0].measurements, DEFAULT_ACTIVE_BLUE_STYLE);
    if (this.clickedFeature) {
      this.clickedFeature.get('measurement')['left'] = undefined
    }
    this.removeChart()

    // TODO: Investigate why the 'postrender' listener does not trigger as expected.
    // Using setTimeout as a workaround to ensure the layer is rendered before proceeding.
    this.leftDSMLayer.once('postrender', () => {
      setTimeout(() => {
        if (this.clickedFeature) {
          this.map.getView().fit(this.clickedFeature.getGeometry().getExtent(),{padding:[60, 60, 60, 60],maxZoom:50,duration:500,easing:easeOut})
          this.updateMeasurement(
            this.clickedFeature.get('measurement'),
            this.clickedFeature.getGeometry()
          );
        } else {
          this.unselectMeasurement()
        }
        this.applySwipeAfterMove();
      }, 1000);
    });
  }

  public onRightOrderChange(order: any): void {
    this.removeMeasurementsByLegacyId(this.selectedRightOrder?.legacyId)
    this.oLLayerService.updateDSMWebGLTileLayerSource(this.rightDSMLayer, order.orthos[0]?.dsmFileModel.webViewLink)
    this.oLLayerService.updateWebGLTileLayerSource(this.rightOrthoLayer, order.orthos[0].webViewLink)
    this.selectedRightOrder = order;
    this.addMeasurementsToLayer(order.legacyId, order.orthos[0].measurements, DEFAULT_LIGHT_BLUE_STYLE);
    if (this.clickedFeature) {
      this.clickedFeature.get('measurement')['right'] = undefined
    }
    this.removeChart()
    // TODO: Investigate why the 'postrender' listener does not trigger as expected.
    // Using setTimeout as a workaround to ensure the layer is rendered before proceeding.
    this.rightDSMLayer.once('sourceready', () => {
        setTimeout(() => {
          this.applySwipeAfterMove();
          if (this.clickedFeature) {
            this.map.getView().fit(this.clickedFeature.getGeometry().getExtent(), {padding:[60, 60, 60, 60] ,maxZoom:50, duration:500, easing:easeOut})
            this.updateMeasurement(
              this.clickedFeature.get('measurement'),
              this.clickedFeature.getGeometry()
            );
          }
      }, 1000);
    });
  }

  //------------------------ Measurements Helpers --------------------------
  private enrichVolumeMeasurement(measurement, geometry: Geometry): void {
    measurement.title = measurement.title ? measurement.title : 'Untitled';
    const poly = geometry as Polygon;
    measurement.value2d= getArea(poly)
    measurement.selectedVolumeApproach = VolumeApproach.VolumeRealLowestPoint;

    setTimeout(() => { // Might be Heavy Calculation
      const pixelsLeftDSM = this.oLLayerService.extractInteriorPixelsOfExtent(this.map, poly, this.leftDSMLayer);
      const pixelsRightDSM = this.oLLayerService.extractInteriorPixelsOfExtent(this.map, poly, this.rightDSMLayer);
      const elevationValuesRight = this.oLLayerService.extractElevations(pixelsRightDSM);
      const elevationValuesLeft = this.oLLayerService.extractElevations(pixelsLeftDSM);
      measurement.pixelSurface = this.measurementsService.getPixelSurface(pixelsLeftDSM, measurement.value2d);

      const { min, max } = this.measurementsService.getMinMaxValues(elevationValuesRight);
      measurement['right'] = {};
      measurement['right']['elevationsMax'] = max;
      measurement['right']['elevationsMin'] = min;
      measurement['right']['customElevation'] = measurement['right']['elevationsMax'] - measurement['right']['elevationsMin'];
      measurement['right']['baseBordersAverageElevation'] = this.measurementsService.getBordersElevationAverage(elevationValuesRight);
      measurement['right']['volumeAverageLowestPoint'] = this.measurementsService.calculateVolumeAverageLowestPoint(elevationValuesRight, measurement.pixelSurface, measurement['right']['baseBordersAverageElevation']);
      measurement['right']['volumeRealLowestPoint'] = this.measurementsService.calculateVolumeRealLowestPoint(elevationValuesRight, measurement.pixelSurface, measurement['right']['elevationsMin']);
      measurement['right']['volumeAverageInterpolation'] = this.measurementsService.calculateVolumeContourAverageInterpolation(elevationValuesRight, measurement.pixelSurface, measurement['right']['baseBordersAverageElevation']);
      measurement['right']['volumeLinearInterpolation'] = this.measurementsService.calculateVolumeContourLinearInterpolation(elevationValuesRight, measurement.pixelSurface);
      measurement['right']['volumeCustomElevation'] = this.measurementsService.calculateVolumeWithCustomElevation(elevationValuesRight, measurement['right']['customElevation'], measurement.pixelSurface, measurement['right']['elevationsMax']);
      measurement['right']['volumeCut'] = this.measurementsService.calculateCutVolume(elevationValuesRight, measurement.pixelSurface, measurement['right']['baseBordersAverageElevation']);
      measurement['right']['volumeFill'] = this.measurementsService.calculateFillVolume(elevationValuesRight, measurement.pixelSurface, measurement['right']['baseBordersAverageElevation']);

      const { min: minLeft, max: maxLeft } = this.measurementsService.getMinMaxValues(elevationValuesLeft);
      measurement['left'] = {};
      measurement['left']['elevationsMax'] = maxLeft;
      measurement['left']['elevationsMin'] = minLeft;
      measurement['left']['customElevation'] = measurement['left']['elevationsMax'] - measurement['left']['elevationsMin'];
      measurement['left']['baseBordersAverageElevation'] = this.measurementsService.getBordersElevationAverage(elevationValuesLeft);
      measurement['left']['volumeAverageLowestPoint'] = this.measurementsService.calculateVolumeAverageLowestPoint(elevationValuesLeft, measurement.pixelSurface, measurement['left']['baseBordersAverageElevation']);
      measurement['left']['volumeRealLowestPoint'] = this.measurementsService.calculateVolumeRealLowestPoint(elevationValuesLeft, measurement.pixelSurface, measurement['left']['elevationsMin']);
      measurement['left']['volumeAverageInterpolation'] = this.measurementsService.calculateVolumeContourAverageInterpolation(elevationValuesLeft, measurement.pixelSurface, measurement['left']['baseBordersAverageElevation']);
      measurement['left']['volumeLinearInterpolation'] = this.measurementsService.calculateVolumeContourLinearInterpolation(elevationValuesLeft, measurement.pixelSurface);
      measurement['left']['volumeCustomElevation'] = this.measurementsService.calculateVolumeWithCustomElevation(elevationValuesLeft,  measurement['left']['customElevation'], measurement.pixelSurface, measurement['left']['elevationsMax']);
      measurement['left']['volumeCut'] = this.measurementsService.calculateCutVolume(elevationValuesLeft, measurement.pixelSurface, measurement['left']['baseBordersAverageElevation']);
      measurement['left']['volumeFill'] = this.measurementsService.calculateFillVolume(elevationValuesLeft, measurement.pixelSurface, measurement['left']['baseBordersAverageElevation']);
    }, 1000);
  }

  private enrichAreaMeasurement(measurement, geometry: Geometry): void {
    const poly = geometry as Polygon;
    measurement.title = measurement.title ? measurement.title : 'Untitled';
    measurement.value2d = getArea(poly);

    setTimeout(() => { // Might be Heavy Calculation
      let pixelsLeftDSM = this.oLLayerService.extractInteriorPixelsOfExtent(this.map, poly, this.leftDSMLayer);
      let pixelsRightDSM = this.oLLayerService.extractInteriorPixelsOfExtent(this.map, poly, this.rightDSMLayer);
      const elevationValuesRight = this.oLLayerService.extractElevations(pixelsRightDSM);
      const elevationValuesLeft = this.oLLayerService.extractElevations(pixelsLeftDSM);
      measurement.pixelSurface = this.measurementsService.getPixelSurface(pixelsLeftDSM, measurement.value2d);

      const { min, max } = this.measurementsService.getMinMaxValues(elevationValuesRight);
      measurement['right'] = {};
      measurement['right']['elevationsMax'] = max;
      measurement['right']['elevationsMin'] = min;
      measurement['right']['customElevation'] = measurement['right']['elevationsMax'] - measurement['right']['elevationsMin'];
      measurement['right']['value3d'] = this.measurementsService.calculateSurfaceArea(measurement.value2d, elevationValuesRight);

      const { min: minLeft, max: maxLeft } = this.measurementsService.getMinMaxValues(elevationValuesLeft);
      measurement['left'] = {};
      measurement['left']['elevationsMax'] = maxLeft;
      measurement['left']['elevationsMin'] = minLeft;
      measurement['left']['customElevation'] = measurement['left']['elevationsMax'] - measurement['left']['elevationsMin'];
      measurement['left']['value3d'] = this.measurementsService.calculateSurfaceArea(measurement.value2d, elevationValuesLeft);
    }, 1000);
  }

  private enrichLengthMeasurement(measurement, geometry: Geometry): void {
    const lineString = geometry as LineString;
    measurement.title = measurement.title ? measurement.title : 'Untitled';
    measurement.value2d = getLength(lineString);

    setTimeout(() => { // Might be Heavy Calculation
      let interiorPixels = [];
      const numPoints = 200;
      for (let i = 0; i < numPoints; i++) {
        const fraction = i / (numPoints - 1);
        const coordinate = lineString.getCoordinateAt(fraction);
        interiorPixels.push(coordinate);
      }
      const pixelsLeftDSM = [...this.oLLayerService.gatherAllPixels(this.map, interiorPixels, this.leftDSMLayer)];
      const elevationValuesLeft: number[] = this.oLLayerService.extractElevationsLine(pixelsLeftDSM);
      const pixelsRightDSM = [...this.oLLayerService.gatherAllPixels(this.map, interiorPixels, this.rightDSMLayer)];
      const elevationValuesRight: number[] = this.oLLayerService.extractElevationsLine(pixelsRightDSM);
      measurement['chartData'] = { pixelsLeftDSM, pixelsRightDSM, value2d: measurement.value2d };

      measurement['left'] = {};
      measurement['left']['elevationsMax'] = Math.max(...elevationValuesLeft);
      measurement['left']['elevationsMin'] = Math.min(...elevationValuesLeft);
      measurement['left']['value3d'] = this.measurementsService.calculateRealLength(measurement.value2d, elevationValuesLeft);
      measurement['left']['slope'] = this.measurementsService.getSlope(elevationValuesLeft[0], elevationValuesLeft[elevationValuesLeft.length - 1], measurement.value2d);

      measurement['right'] = {};
      measurement['right']['elevationsMax'] = Math.max(...elevationValuesRight);
      measurement['right']['elevationsMin'] = Math.min(...elevationValuesRight);
      measurement['right']['value3d'] = this.measurementsService.calculateRealLength(measurement.value2d, elevationValuesRight);
      measurement['right']['slope'] = this.measurementsService.getSlope(elevationValuesRight[0], elevationValuesRight[elevationValuesRight.length - 1], measurement.value2d);

      this.renderLengthChart(measurement.chartData);

    }, 1000);
  }

  private enrichMeasurement(measurement, geometry: Geometry): void {
    this.removeChart()
    if (measurement.type === 'volume') {
      if (!measurement.right) {
        this.enrichVolumeMeasurement(measurement, geometry)
      }
    }

    if (measurement.type === 'area') {
      if (!measurement.right) {
        this.enrichAreaMeasurement(measurement, geometry)
      }
    }

    if (measurement.type === 'length') {
      if (!measurement.right) {
        this.enrichLengthMeasurement(measurement, geometry)
      } else {
        setTimeout(() => {
          this.renderLengthChart(measurement.chartData);
        }, 200);
      }
    }
  }

  private updateMeasurement(measurement, geometry: Geometry): void {
    if (measurement.type === 'volume') {
      this.enrichVolumeMeasurement(measurement, geometry)
    }

    if (measurement.type === 'area') {
      this.enrichAreaMeasurement(measurement, geometry)
    }

    if (measurement.type === 'length') {
      this.enrichLengthMeasurement(measurement, geometry)
    }
  }

  private removeMeasurementsByLegacyId(legacyId: number): void {
    const featuresToRemove = this.measurementSource.getFeatures().filter(
      feature => feature.get('legacyId') === legacyId
    );
    featuresToRemove.forEach(feature => {
      this.measurementSource.removeFeature(feature)
      if (this.clickedFeature?.get('name') === feature.get('name')) {
        this.unselectMeasurement()
      }
    });
  }

  //------------------------ HTML Direct Actions ---------------------------
  public unselectMeasurement() {
    this.clearHighlight();
    this.disableModification();
    this.clickedFeature = null;
    this.sidenav.close();
    this.myLineChart = undefined
  }

  public measureArea() {
    this.isDrawAreaActive = !this.isDrawAreaActive;
    this.isDrawLengthActive = false;
    this.isDrawVolumeActive = false;

    this.unselectMeasurement()

    if (this.isDrawAreaActive) {
      const drawInteraction = new Draw({
        source: this.measurementSource,
        type: 'Polygon'
      });

      drawInteraction.on('drawstart', (event) => {
        this.drawingStarted = true
        const coordinate = (event.feature.getGeometry() as Polygon).getFirstCoordinate();
        const leftLayerExtent = this.leftOrthoLayer.getExtent();
        const rightLayerExtent = this.rightOrthoLayer.getExtent();

        if (containsCoordinate(leftLayerExtent, coordinate)) {
          event.feature.set('legacyId', this.selectedLeftOrder.legacyId);
        } else if (containsCoordinate(rightLayerExtent, coordinate)) {
          event.feature.set('legacyId', this.selectedRightOrder.legacyId);
        } else {
        }
      });

      this.map.addInteraction(drawInteraction);

      drawInteraction.on('drawend', (event) => {
        this.drawingStarted = false
        this.resetBrightness()
        event.feature.set('measurement', { title: 'Untitled Area', type: 'area' });
        event.feature.set('saved',false)
        const newFeature = event.feature;

        this.map.removeInteraction(drawInteraction);

        setTimeout(() => {
          this.isDrawAreaActive = false;
          this.FocusOnFeatureAndMeasure(newFeature);
        }, 100);
      });
    } else {
      this.removeExistingDrawInteraction()
      this.resetBrightness()
    }
  }

  public measureLength() {
    this.isDrawLengthActive = !this.isDrawLengthActive;
    this.isDrawAreaActive = false;
    this.isDrawVolumeActive = false;

    this.unselectMeasurement()

    if (this.isDrawLengthActive) {
      const drawInteraction = new Draw({
        source: this.measurementSource,
        type: 'LineString',
        maxPoints: 2
      });

      drawInteraction.on('drawstart', (event) => {
        this.drawingStarted = true
        const coordinate = (event.feature.getGeometry() as LineString).getFirstCoordinate();
        const leftLayerExtent = this.leftOrthoLayer.getExtent();
        const rightLayerExtent = this.rightOrthoLayer.getExtent();

        if (containsCoordinate(leftLayerExtent, coordinate)) {
          event.feature.set('legacyId', this.selectedLeftOrder.legacyId);
        } else if (containsCoordinate(rightLayerExtent, coordinate)) {
          event.feature.set('legacyId', this.selectedRightOrder.legacyId);
        } else {
        }
      });

      this.map.addInteraction(drawInteraction);

      drawInteraction.on('drawend', (event) => {
        this.drawingStarted = false
        this.resetBrightness()

        event.feature.set('measurement', { title: 'Untitled Length', type: 'length' })
        event.feature.set('saved',false)
        const newFeature = event.feature;

        this.map.removeInteraction(drawInteraction);

        setTimeout(() => {
          this.isDrawLengthActive = false;
          this.FocusOnFeatureAndMeasure(newFeature)
        }, 100);

      });
    }  else {
      this.removeExistingDrawInteraction()
      this.resetBrightness()
    }
  }

  public measureVolume() {
    this.isDrawVolumeActive = !this.isDrawVolumeActive;
    this.isDrawAreaActive = false;
    this.isDrawLengthActive = false;

    this.unselectMeasurement()

    if (this.isDrawVolumeActive) {
      const drawInteraction = new Draw({
        source: this.measurementSource,
        type: 'Polygon'
      });

      drawInteraction.on('drawstart', (event) => {
        this.drawingStarted = true
        const coordinate = (event.feature.getGeometry() as Polygon).getFirstCoordinate();
        const leftLayerExtent = this.leftOrthoLayer.getExtent();
        const rightLayerExtent = this.rightOrthoLayer.getExtent();

        if (containsCoordinate(leftLayerExtent, coordinate)) {
          event.feature.set('legacyId', this.selectedLeftOrder.legacyId);
        } else if (containsCoordinate(rightLayerExtent, coordinate)) {
          event.feature.set('legacyId', this.selectedRightOrder.legacyId);
        } else {
        }
      });

      this.map.addInteraction(drawInteraction);

      drawInteraction.on('drawend', (event) => {
        this.drawingStarted = false
        this.resetBrightness()

        event.feature.set('measurement', { title: 'Untitled Volume', type: 'volume' });
        event.feature.set('saved',false)
        const newFeature = event.feature;

        this.map.removeInteraction(drawInteraction);

        setTimeout(() => {
          this.isDrawVolumeActive = false;
          this.FocusOnFeatureAndMeasure(newFeature);
        }, 100);
      });
    } else {
      this.removeExistingDrawInteraction()
      this.resetBrightness()
    }
  }

  private removeExistingDrawInteraction(): void {
    const interactions = this.map.getInteractions().getArray();
    interactions.forEach((interaction) => {
      if (interaction instanceof Draw) {
        interaction.setActive(false);
        this.map.removeInteraction(interaction);
      }
    });
  }

  private FocusOnFeatureAndMeasure(feature: Feature) {
    this.map.getView().fit(feature.getGeometry().getExtent(),{padding:[60, 60, 60, 60],maxZoom:50,duration:500,easing:easeOut})

    if (this.clickedFeature === feature) {
      return;
    }

    this.sidenav.open();

    if (this.clickedFeature) {
      this.clearHighlight();
    }

    this.clickedFeature = feature;
    this.enableModification(this.clickedFeature);
    this.highlightFeature(this.clickedFeature);

    this.enrichMeasurement(this.clickedFeature.get('measurement'), this.clickedFeature.getGeometry())
  }

  public resetView(): void {
    this.leftOrthoLayer.getSource().getView().then((viewOptions) => {
      const extent = viewOptions.extent;
      this.map.getView().fit(extent, {
          duration: 1000,
          easing: easeOut
      });
  });
  }

  public saveMeasurement(feature: Feature) {
    if (feature?.get('measurement')._id) {
      const measurementToBeSaved = this.constructRequest(feature,
        feature?.get('legacyId') === this.selectedLeftOrder.legacyId ? feature.get('measurement').left : feature.get('measurement').right,
        feature?.get('legacyId') === this.selectedLeftOrder.legacyId ? this.selectedLeftOrder.orthos[0]._id : this.selectedRightOrder.orthos[0]._id)
      this.measurementsService.updateOne(measurementToBeSaved)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          if (response.data) {
            feature.set('saved', true)
            if (feature?.get('legacyId') === this.selectedLeftOrder.legacyId) {
              const measurements = this.selectedLeftOrder.orthos[0].measurements;
              const index = measurements.findIndex(measurement => measurement._id === measurementToBeSaved._id);

              if (index !== -1) {
                measurements[index] = measurementToBeSaved;
              } else {
                measurements.push(measurementToBeSaved);
              }
            } else {
              const measurements = this.selectedRightOrder.orthos[0].measurements;
              const index = measurements.findIndex(measurement => measurement._id === measurementToBeSaved._id);

              if (index !== -1) {
                measurements[index] = measurementToBeSaved;
              } else {
                measurements.push(measurementToBeSaved);
              }
            }
            this.dialogService.showDialog('MEASURE.POPUP.MEASUREMENT_UPDATED_SUCCESSFULLY', null, null,null,null,true);
          }
      });
    } else {
      const measurementToBeSaved = this.constructRequest(feature,
        feature?.get('legacyId') === this.selectedLeftOrder.legacyId ? feature.get('measurement').left : feature.get('measurement').right,
        feature?.get('legacyId') === this.selectedLeftOrder.legacyId ? this.selectedLeftOrder.orthos[0]._id : this.selectedRightOrder.orthos[0]._id)
      this.measurementsService.insertOne(measurementToBeSaved)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          if (response.data) {
            feature.get('measurement')._id = response.data._id
            feature.set('saved', true)
            feature?.get('legacyId') === this.selectedLeftOrder.legacyId
              ? (this.selectedLeftOrder.orthos[0].measurements ||= []).push(measurementToBeSaved)
              : (this.selectedRightOrder.orthos[0].measurements ||= []).push(measurementToBeSaved);
            this.dialogService.showDialog('MEASURE.POPUP.MEASUREMENT_SAVED_SUCCESSFULLY', null, null,null,null,true);
          }
      });
    }
  }

  public deleteMeasurement(feature: Feature): void{
    if (feature?.get('measurement')._id) {
      this.measurementsService.hardDeleteOne(feature?.get('measurement')._id).pipe(takeUntil(this.ngDestroy$)).subscribe(
        response => {

          if (feature?.get('legacyId') === this.selectedLeftOrder.legacyId) {
            const measurements = this.selectedLeftOrder.orthos[0].measurements;
            const index = measurements.findIndex(measurement => measurement._id === feature?.get('measurement')._id);

            if (index !== -1) {
              measurements.splice(index, 1);
            }
          } else {
            const measurements = this.selectedRightOrder.orthos[0].measurements;
            const index = measurements.findIndex(measurement => measurement._id === feature?.get('measurement')._id);

            if (index !== -1) {
              measurements.splice(index, 1);
            }
          }
          this.dialogService.showDialog('MEASURE.POPUP.MEASUREMENT_DELETED_SUCCESSFULLY', null, null,null,null,true);
        }
      )
    }
    this.unselectMeasurement()
    this.measurementSource.removeFeature(feature)
  }

  private constructRequest(feature: Feature, measurement, fileId): Measurement{

    let measurementToSend: Measurement = {}

    measurementToSend.fileID = fileId

    if (feature?.get('measurement')._id) {
      measurementToSend._id = feature?.get('measurement')._id
    }
    if (feature?.get('measurement').value2d) {
       measurementToSend.value2d = feature?.get('measurement').value2d
    }
    if (measurement.value3d) {
       measurementToSend.value3d = measurement.value3d
    }
    if (feature?.get('measurement').type) {
       measurementToSend.type = feature?.get('measurement').type
    }

    measurementToSend.location = this.oLLayerService.setLocation(feature)

    return measurementToSend
  }

  // --------------------------- Chart JS ------------------------------

  private renderLengthChart(chartData): void {
    const canvas: HTMLCanvasElement = document.getElementById('canvas-container') as HTMLCanvasElement;
    this.createOrUpdateMultiLineChart(canvas, chartData.pixelsLeftDSM, chartData.pixelsRightDSM, chartData.value2d);
  }

  private removeChart(): void {
    if (this.myLineChart) {
      this.myLineChart.destroy();
      this.myLineChart = null;
    }
  }

  private createOrUpdateMultiLineChart(canvas: HTMLCanvasElement, elevationLeft, elevationRight, normalLength): void {
    const ctx: CanvasRenderingContext2D = canvas.getContext('2d');
    const elevationLeftValues = this.oLLayerService.extractElevationsLine(elevationLeft);
    const elevationRightValues = this.oLLayerService.extractElevationsLine(elevationRight);
    const normalLengthLabels = Array.from({ length: 200 }, (_, i) => (i + 1) * (normalLength / 200));

    const data = {
      labels: normalLengthLabels,
      datasets: [
        {
          label: formatDate(this.selectedLeftOrder.flightDateEnd),
          data: elevationLeftValues,
          borderColor: "rgb(41, 121, 255)",
          borderWidth: 1,
          fill: false,
          pointHoverBackgroundColor: "white"
        },
        {
          label: formatDate(this.selectedRightOrder.flightDateEnd),
          data: elevationRightValues,
          borderColor: "rgb(84, 219, 253)",
          borderWidth: 1,
          fill: false,
          pointHoverBackgroundColor: "white"
        }
      ]
    };

    const options: ChartOptions = {
      plugins: {
        datalabels: {
          display: false,
        },
        legend: {
          display: true,
          labels: {
            color: this.theme?.includes('dark') ? "white" : "rgb(110, 110, 110)",
            font: {
              size: 12,
            }
          }
        },
      },
      interaction: {
        mode: 'nearest',
        axis: 'x',
        intersect: false,
      },
      elements: {
        point: {
          radius: 0,
        },
      },
      responsive: true,
      scales: {
        x: {
          type: 'linear',
          position: 'bottom',
          title: {
            display: true,
            text: this.Vocab?.MEASURE['2D_LENGTH'] + ' (m)',
            color: this.theme?.includes('dark') ? "white" : "rgb(110, 110, 110)"
          },
          ticks: {
            color: this.theme?.includes('dark') ? "#c9c9c9" : "rgb(150 150 150)",
          },
          grid: {
            color: this.theme?.includes('dark') ? "#666666" : "rgb(210 210 210)",
          },
          max: Math.max(...normalLengthLabels),
        },
        y: {
          title: {
            display: true,
            text: this.Vocab.MEASURE['ELEVATION'] + ' (m)',
            color: this.theme?.includes('dark') ? "white" : "rgb(110, 110, 110)"
          },
          ticks: {
            color: this.theme?.includes('dark') ? "#c9c9c9" : "rgb(150 150 150)",
          },
          grid: {
            color: this.theme?.includes('dark') ? "#666666" : "rgb(210 210 210)",
          },
        },
      },
    };

    try {
      if (this.myLineChart) {
        this.myLineChart.data = data;
        this.myLineChart.options = options;
        this.myLineChart.update();
      } else {
        this.myLineChart = new Chart(ctx, {
          type: 'line',
          data: data,
          options: options
        });
      }
    } catch (error) {
      console.error("Chart creation or update failed", error);
    }
  }
}
