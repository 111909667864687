import { Component, AfterViewInit, ElementRef, ViewChild, HostListener, Compiler, Injector, ViewContainerRef, OnDestroy } from '@angular/core';
import { FilesService } from './../files.service';
import { ResizeEvent } from 'angular-resizable-element';
import { combineLatest, Observable, Subject } from 'rxjs';
import { map, take, takeUntil} from 'rxjs/operators';

import { OlImageComponent } from './../../../shared/ol-image/ol-image.component';
import { FilesComponent } from '../files.component';
import { ActivatedRoute, Router } from '@angular/router';
import { FileModel } from './../file/file.model';
import { SidenavNavigationService } from '../../../shared/sidenav/sidenav-navigation/sidenav-navigation.service'
import { ThemeService } from './../../../shared/theme/theme.service';
import { searchToFilter } from 'src/app/shared/helpers/data-helpers';
import { ViewerService } from './../../viewer/viewer.service';

import { TranslateService } from '@ngx-translate/core';
import { InspectionService } from './inspection.service';
import { Portfolio, Site } from "../../../shared/portfolio/portfolio.model";
import { PortfolioService } from "../../../shared/portfolio/portfolio.service";
import { HeaderService } from "../../../shared/header/header.service";
import { LoginStateService } from "../../login/login-state.service";

@Component({
  selector: 'app-inspection',
  templateUrl: './inspection.component.html',
  styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent implements AfterViewInit, OnDestroy {
  antennaInspection = false;
  border = 25;
  height = 57;
  has3dView = false;
  horizontal = 250;
  horizontalLeft = 250;
  inspectionArea = {height: 0,width: 0}
  inspectionFileModel: FileModel;
  olAntenna: any;
  viewer: any;
  viewer3dFileModel: FileModel;
  selectedFileDisplayedName: string;
  selectedFileFullName: string;
  selectedMapView = '2d'
  showFilesSwitcher = false;
  showFilter = false;
  showMap = true;
  theme: string;
  usedFeatures: {[key: string]: any[];}  = {};
  vertical = 500;
  private requested= false;

  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('containerMap', { read: ViewContainerRef }) containerMap: ViewContainerRef;
  @ViewChild('containerAntenna', { read: ViewContainerRef }) containerAntenna: ViewContainerRef;
  @ViewChild(OlImageComponent, {static : false}) olImage: OlImageComponent;

  private ngDestroy$ = new Subject();
  private pageSize = 50;

  constructor(
    public filesService: FilesService,
    public sidenavNavigationService: SidenavNavigationService,
    private compiler: Compiler,
    private injector: Injector,
    private inspectionService: InspectionService,
    private route: ActivatedRoute,
    private router: Router,
    private themeService: ThemeService,
    private translate: TranslateService,
    private viewerService: ViewerService,
    private readonly portfolioService: PortfolioService,
    private readonly headerService: HeaderService,
    private readonly loginService: LoginStateService
  ) {
    this.route.params
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(params => {
      const folderID = params['folderID']
      if(folderID) {
        const filter =  { folderID: {$oid: folderID }} ;
        this.loadFilesForMap(filter);
        this.filesService.findOne(folderID) // TODO: it is uselessly called multiple times, since it is subscribed to the router
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(
          response => {
            if(response && response.data) {
            this.viewerService.viewerFileModel$.next(response.data);
            this.inspectionFileModel = response.data
              const firstChild = this.route.snapshot.parent.children[0];
              if (firstChild.routeConfig.path.includes('portfolio')) {
                this.getSite()
                  .pipe(take(1))
                  .subscribe(([site, portfolio]) => {
                    this.updateNavigation(portfolio, site)
                  })
              }

            if(this.inspectionFileModel.inspection && this.inspectionFileModel.inspection.tiles3d !== undefined) {
              this.has3dView = true;
            } else {
              this.has3dView = false;
            }
            this.loadMap();

            if (response.data.viewer?.imagesFolder) {
              let search = '';
              search = search.trim();
              const filter = searchToFilter(search);
              if (!Object.keys(filter).length) {
                // Set folderID from the route ID
                filter['folderID'] = { $oid: response.data.viewer?.imagesFolder }
              }
              this.loadFilesForMap(filter);
            }
          }
        });
      }
    });

    this.inspectionService.selectedFile$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (Object.keys(data).length !== 0) {
        if(data.name.length > 25) {
          const matches = /_(\d+)(?!.*\d).*/.exec(data.name);
          if (matches) {
            this.selectedFileDisplayedName = "..."+matches[0];
            this.selectedFileFullName = data.name;

            return
          }
          const parts = data.name.split('_');
          this.selectedFileDisplayedName = "..." + parts[parts.length-1];
          this.selectedFileFullName = data.name;

          return
        }
        this.selectedFileDisplayedName = data.name;
        this.selectedFileFullName = "";

      }
    });

    this.sidenavNavigationService.opened$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      setTimeout(() => {
        this.updateInspectionArea();
      }, 500);
    });

    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });
  }

  private loadFilesForMap(filter: object): void {
    const sort: object = { modified: -1 };
    const fields: object = {"childrenCount":0,"path":0}
    this.filesService.findMany(filter, sort, 0, 200000, fields)
      .pipe( takeUntil(this.ngDestroy$))
      .subscribe(
        responses => {
          if (responses && responses.data?.length > 0) {
            this.filesService?.currentFiles$?.next(responses.data);
          }
      });
  }

  filesViewChange(files: FilesComponent): void {
    if (files.viewMode === 'grid'){
      files.setViewMode('list')
    } else {
      files.setViewMode('grid')
    }
  }

  horizontalAntenna(): number {
    if (this.antennaInspection) {
      return this.horizontal-this.horizontalLeft
    } else {
      return 0;
    }
  }

  horizontalFiles(): number {
    if(this.antennaInspection) {
      return this.horizontal + this.border;
    } else {
      return this.horizontalLeft;
    }
  }

  loadAntenna(): void {
    // Dynamic import, activate code splitting and on demand loading of feature module
    import('../../olmap/ol-map.module').then(({ OlMapModule }) => {
      // Compile the module
      if(this.containerAntenna) {
        this.compiler.compileModuleAsync(OlMapModule).then(moduleFactory => {
          // Create a moduleRef, resolve an entry component, create the component
          const moduleRef = moduleFactory.create(this.injector);
          const componentFactory = moduleRef.instance.resolveComponentAntenna();
          const { instance } = this.containerAntenna.createComponent(componentFactory);
          this.olAntenna = instance;
        });
      }
    });
  }

  loadMap(): void {
    if(this.inspectionFileModel && !this.inspectionFileModel.tags?.includes('viewer') && this.viewer === undefined) {
      // Dynamic import, activate code splitting and on demand loading of feature module
      import('../../olmap/ol-map.module').then(({ OlMapModule }) => {
        // Compile the module
        if(this.containerMap) {
          this.compiler.compileModuleAsync(OlMapModule).then(moduleFactory => {
            // Create a moduleRef, resolve an entry component, create the component
            const moduleRef = moduleFactory.create(this.injector);
            const componentFactory = moduleRef.instance.resolveComponentMap();
            const { instance } = this.containerMap.createComponent(componentFactory);
            this.viewer = instance;
          });
        }
      });
    }
  }

  loadViewer3d(): void {
    if(this.inspectionFileModel && !this.inspectionFileModel.tags?.includes('viewer') && this.viewer === undefined) {
      this.filesService.findOne(this.inspectionFileModel.inspection.tiles3d)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(
        response => {
          if(response.data) {
            this.viewer3dFileModel = response.data;
          }
        });
      // Dynamic import, activate code splitting and on demand loading of feature module
      import('../../viewer/viewer3d/viewer3d.module').then(({ Viewer3dModule }) => {
        // Compile the module
        if(this.containerMap) {
          this.compiler.compileModuleAsync(Viewer3dModule).then(moduleFactory => {
            // Create a moduleRef, resolve an entry component, create the component
            const moduleRef = moduleFactory.create(this.injector);
            const componentFactory = moduleRef.instance.resolveComponentView3d();
            const { instance } = this.containerMap.createComponent(componentFactory);
            this.viewer = instance;

            this.viewer3dLoadModel();
          });
        }
      });
    }
  }

  viewer3dLoadModel(): void {
    if (!this.viewer.checkViewInit() && this.viewer3dFileModel) {
      setTimeout(() => {
        this.viewer3dLoadModel();
      }, 500);
    } else {
      this.viewer.toggleMap();
      if(this.inspectionFileModel.inspection && this.inspectionFileModel.inspection.imageCones) {
        if(this.inspectionFileModel.inspection.imageCones.position.z) {
          this.viewer.setImageConesProperties("z", this.inspectionFileModel.inspection.imageCones.position.z)
        }
        if(this.inspectionFileModel.inspection.imageCones.scale) {
          this.viewer.setImageConesProperties("scale", this.inspectionFileModel.inspection.imageCones.scale)
        }
      }
      this.viewer.setViewer3dFileModel(this.viewer3dFileModel);

      let search = '';
      search = search.trim();
      const filter = searchToFilter(search);
      if (!Object.keys(filter).length) {
        // Set folderID from the route ID
        filter['folderID'] = { $oid: this.inspectionFileModel._id }
      }
      this.loadFilesForMap(filter);
    }
  }

  mapViewerChange(value: string): void {
    this.viewer = undefined;
    this.containerMap.remove();
    if(value === '3d') {
      this.loadViewer3d();
    } else {
      this.loadMap();
    }
  }

  ngAfterViewInit(): void {
    this.loadMap();
    this.loadAntenna();
    setTimeout(() => {
      this.sidenavNavigationService.fold();
      this.updateInspectionArea();
    }, 10);
  }

  public ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
    const firstChild = this.route.snapshot.parent.children[0];

    if (firstChild.routeConfig.path.includes('portfolio')) {
      this.headerService.breadCrumbs$.next([]);
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.updateInspectionArea();
  }

  resizingHorizontal(event: ResizeEvent): void {
    const value = event.rectangle.width;
    if (value + this.horizontalLeft < this.horizontalLeft + (this.border / 2)) {
      this.horizontal = this.horizontalLeft;
    } else if (value + this.horizontalLeft > (this.inspectionArea.width - this.border - (this.border / 2))){
      this.horizontal = this.inspectionArea.width - this.border;
    } else {
      this.horizontal = value + this.horizontalLeft;
    }

    this.viewerService.action$.next('updateSize');
    if(this.viewer) {
      this.viewer.updateSize();
    }
    if(this.olAntenna) {
      this.olAntenna.updateSize();
    }
    this.olImage.updateSize();
  }

  resizingHorizontalLeft(event: ResizeEvent): void {

    const value = event.rectangle.width;
    if (this.antennaInspection) {
      if (value < this.border + (this.border / 2)) {
        this.horizontalLeft = this.border;
      } else if (value > (this.horizontal - this.border - (this.border / 2))){
        this.horizontalLeft = this.horizontal;
      } else {
        this.horizontalLeft = value;
      }
    } else {
      if (value < this.border + (this.border / 2)) {
        this.horizontalLeft = this.border;
      } else if (value > (this.inspectionArea.width - this.border - (this.border / 2))){
        this.horizontalLeft = this.inspectionArea.width;
      } else {
        this.horizontalLeft = value;
      }
    }

    this.viewerService.action$.next('updateSize');
    if(this.viewer) {
      this.viewer.updateSize();
    }
    if(this.olAntenna) {
      this.olAntenna.updateSize();
    }
    this.olImage.updateSize();
  }

  resizingVertical(event: ResizeEvent): void {
    const value = event.rectangle.height;
    if (value < (this.border / 2)) {
      this.vertical = 0;
    } else if (value > (this.inspectionArea.height - this.border - (this.border / 2))){
      this.vertical = this.inspectionArea.height - this.border;
    } else {
      this.vertical = value;
    }

    this.viewerService.action$.next('updateSize');
    if(this.viewer) {
      this.viewer.updateSize();
    }
    if(this.olAntenna) {
      this.olAntenna.updateSize();
    }
    this.olImage.updateSize();
  }

  public getSite(): Observable<[Site, Portfolio]> {
    const site = this.route.snapshot.params.siteID;
    const portfolio = this.route.snapshot.params.portfolioID;
    return combineLatest(
      this.portfolioService.getSiteById(site)
        .pipe(map(resp => resp.data )),
      this.portfolioService.getPortfolioByUser(this.loginService.loggedUser$.value._id.toString())
        .pipe(map(resp => resp.data[0] )),
    )
  }

  public updateNavigation(portfolio: Portfolio, site: Site): void {
    this.headerService.breadCrumbs$.next([{
      label: portfolio?.name,
      link: ['portfolios', portfolio._id]
    }, {
      label: site.name,
      link: ['portfolios', portfolio._id, 'sites', site._id]
    }, {
      label: this.inspectionFileModel.name,
      link: ['portfolios', portfolio._id, 'sites', site._id, 'files', this.inspectionFileModel._id]
    }])
  }

  updateInspectionArea(): void {
    // timeout is needed to avoid wrong height when fullscreen
    setTimeout(() => {
      this.inspectionArea.width = this.wrapper.nativeElement.offsetWidth;
      this.inspectionArea.height = this.wrapper.nativeElement.offsetHeight;
      this.vertical = ((this.inspectionArea.height - (this.border)) / 3 * 2);
      this.horizontal = (this.inspectionArea.width / 3) * 2 - (this.border / 2);
      this.horizontalLeft = (this.inspectionArea.width / 3) - (this.border / 2);

      this.viewerService.action$.next('updateSize');
      if(this.viewer) {
        this.viewer.updateSize();
      }
      if(this.olAntenna) {
        this.olAntenna.updateSize();
      }
      this.olImage.updateSize();
    }, 250);
  }
}
