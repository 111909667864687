import { BentleyDialogComponent } from './../../shared/bentley-dialog/bentley-dialog.component';
import { ActivatedRoute, NavigationEnd, NavigationStart, Params, Router } from '@angular/router';
import { AfterViewInit, Component, ElementRef, EventEmitter, HostBinding, HostListener, Inject, Input, NgZone,
  OnDestroy, PLATFORM_ID, QueryList, Renderer2, ViewChild, ViewChildren, ViewContainerRef, ChangeDetectorRef, OnInit } from '@angular/core';
  import {BehaviorSubject, combineLatest, EMPTY, fromEvent, merge, Observable, Subject, Subscription, zip} from 'rxjs';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CdkScrollable, ScrollDispatcher, ViewportRuler } from '@angular/cdk/overlay';
import {debounceTime, skipWhile, takeUntil, skip, switchMap, map, delay, take, tap, filter, mergeMap} from 'rxjs/operators';
import { Direction } from '@angular/cdk/bidi';
import { isPlatformBrowser } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTable } from '@angular/material/table';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';

import { ActiveHeader } from './../../shared/header/header.interface';
import { AnnotationsService } from './file/sidebar/annotations/annotations.service';
import { Column } from './../../shared/interfaces/column.interface';
import { DialogService } from './../../shared/dialog/dialog.service';
import { environment } from './../../../environments/environment';
import { FileModel, ZipRequest } from './file/file.model';
import { FilesService, FilterFileData } from './files.service';
import { Folder } from './../../shared/folders/folder.model';
import { FolderDialogComponent } from './../../shared/folder-dialog/folder-dialog.component';
import { SendToImogentDialogComponent } from './../../shared/send-to-imogent-dialog/send-to-imogent-dialog.component';
import { DataCheckComponent } from './../../shared/data-check/data-check.component';
import { SendToRaptorMapsDialogComponent } from './../../shared/send-to-raptor-maps-dialog/send-to-raptor-maps-dialog.component';
import { FoldersService } from './../../shared/folders/folders.service';
import { HeaderService } from './../../shared/header/header.service';
import { InspectionService } from './inspection/inspection.service';
import { LanguageService } from './../../shared/i18n/language.service';
import { LambdaRequestBody, LambdaService } from './../../shared/helpers/lambda.service';
import { Login } from './../login/login.model';
import { LoginStateService } from './../login/login-state.service';
import { QueueItem } from './../../shared/upload/upload.model';
import { OrdersService } from './../../shared/orders/orders.service';
import { Permission, PermissionRole } from './file/permission.model';
import { popAnimation } from './../../shared/animations/pop.animation';
import { prefix } from './../../config';
import { searchToFilter } from './../../shared/helpers/data-helpers';
import { SidenavDetailService } from './../../shared/sidenav/sidenav-detail/sidenav-detail.service';
import { SidenavNavigationService } from './../../shared/sidenav/sidenav-navigation/sidenav-navigation.service';
import { StorageService } from './../../shared/helpers/storage.service';
import { UploadService } from './../../shared/upload/upload.service';
import { UserRole, AccountType } from '../users/models/user.model';
import { ThemeService } from './../../shared/theme/theme.service';
import {DragAndDropEvent} from "ol/interaction/DragAndDrop";
import {ShareLinkComponent} from "../../shared/share-link/share-link.component";
import { Portfolio, Site } from 'src/app/shared/portfolio/portfolio.model';
import { PortfolioService } from 'src/app/shared/portfolio/portfolio.service';
import { PermissionsService } from 'src/app/shared/permissions/permissions.service';

interface Sort {
  active: string,
  direction: string
}

@Component({
  selector: 'app-files',
  templateUrl: './files.component.html',
  styleUrls: ['./files.component.scss'],
  animations: [popAnimation]
})
export class FilesComponent implements AfterViewInit, OnDestroy, ActiveHeader {
  activeDetailID = '';
  activeFolderID$: BehaviorSubject<string> = new BehaviorSubject(null); // null means pristine, untouched
  @ViewChild('cards') cards: ElementRef;
  cardsInRow = 4;
  columns: Array<Column> = [
    { label: 'AVATAR_CHECKBOX', mayNotBeHidden: true, value: 'avatar-checkbox' },
    { label: 'ID', value: '_id' },
    { label: 'NAME', value: 'name' },
    { label: 'ORDER.ADDRESS/PROJECT_NAME', value: 'orderAddress' },
    { label: 'FILE.TYPE', value: 'mimeType' },
    { label: 'hidden', value: 'hidden' },
    { label: 'FILE.SIZE', value: 'size' },
    { label: 'FILE.WIDTH', value: 'width' },
    { label: 'FILE.HEIGHT', value: 'height' },
    { label: 'FILE.DURATION', value: 'duration' },
    { label: 'ORDER.ID', value: 'orderID' },
    { label: 'FILE.PATTERN', value: 'pattern' },
    { label: 'FILE.TAKEN', value: 'taken' },
    { label: 'CREATED', value: 'created' },
    { label: 'MODIFIED', value: 'modified' },
    { label: 'MORE', mayNotBeHidden: true, value: 'more' }
  ];
  contextMenuPosition = { x: '0px', y: '0px' };
  contextMenuTrigger: MatMenuTrigger;
  dataSource: Array<FileModel> = [];
  dataSourceCards: Array<FileModel> = [];
  dataSourceNoCards: Array<FileModel> = [];
  dataSourceBeforeFilter: Array<FileModel> = [];
  @Input() dir: Direction;
  displayableTypes = [
    'image/jpg',
    'image/png',
    'image/png; charset=utf-8',
    'image/jpeg',
    'image/jpeg; charset=utf-8'
  ];
  displayedColumns = ['avatar-checkbox', 'name', 'hidden', 'orderAddress', 'size', 'orderID', 'modified', 'more'];
  displayedColumnsInspection = ['avatar-checkbox', 'name', 'inspection.fileID', 'annotations.stateDimension', 'taken', 'more'];
  @ViewChild('fabMenu') fabMenu: MatMenu;
  folderModel: FileModel;
  fetching = true; // Initiate as true, so that the empty-status doesn't show
  gridViewedFolderNames = [
    'Aerial photo',
    'Luftaufnahmen Foto'
  ]
  hideTable = false;
  isMobile = this.platform.ANDROID || this.platform.IOS;
  isSmall = false;
  isXSmall = false;

  // pagination-related
  lastIndex = -1;
  lastIndexID = '';
  lastSelected = 0;
  pageSize = 50;
  lastCountItemReceived = 50;
  totalItems = 0;
  totalLimit = 10000; // When totalItems reaches this totalLimit, then we add the + sign, eg: "1 - 50 of 10000+"
  sumSize = 0;
  totalPages = 0
  cursor = 0;

  login: Login;
  location = 'files';
  locationURL = 'files';
  private isSiteURL = false;
  @ViewChildren('matRow', { read: ViewContainerRef }) matRows: QueryList<ViewContainerRef>;
  @ViewChildren('matThumbnail', { read: ViewContainerRef }) matThumbnails: QueryList<ViewContainerRef>;
  @ViewChild('menu') menu: MatMenu;
  @ViewChildren(MatMenuTrigger) menuTriggers: QueryList<MatMenuTrigger>;
  navigationStart: NavigationStart;
  nextPage: EventEmitter<any> = new EventEmitter();
  ngDestroy$ = new Subject();
  
  permissionRole = PermissionRole;
  queueItems?: Array<QueueItem> = [];
  refreshed: EventEmitter<any> = new EventEmitter();
  relatedTarget: HTMLElement; // Safari fix for https://bugs.webkit.org/show_bug.cgi?id=66547#c10
  searchAutocomplete = [
    { icon: 'shop', label: 'ORDER.ID', value: 'orderID:' },
    { icon: 'folder', label: 'FILES.TYPES.FOLDERS', value: 'type:folder ' }, // + space for next input
    { icon: 'image', label: 'FILES.TYPES.IMAGES', value: 'type:image ' },
    { icon: 'videocam', label: 'FILES.TYPES.VIDEOS', value: 'type:video ' },
    { icon: 'archive', label: 'FILES.TYPES.ZIPS', value: 'type:zip ' },
    {
      label: 'FILE.TAGS',
      options: [
        { icon: 'photo', label: 'photo_raw', value: 'tags:photo_raw ' },
        { icon: 'photo_filter', label: 'photo_edited', value: 'tags:photo_edited ' },
        { icon: 'movie', label: 'video_raw', value: 'tags:video_raw ' },
        { icon: 'movie_filter', label: 'video_edited', value: 'tags:video_edited ' },
        { icon: 'panorama', label: '360_single_image', value: 'tags:360_single_image ' },
        { icon: '360', label: '360_stitched', value: 'tags:360_stitched ' },
        { icon: 'satellite', label: 'orthomosaic', value: 'tags:orthomosaic ' },
        { icon: 'landscape', label: 'elevation_model', value: 'tags:elevation_model ' },
        { icon: 'grain', label: 'point_cloud', value: 'tags:point_cloud ' },
        { icon: '3d_rotation', label: '3D_mesh', value: 'tags:3D_mesh ' },
        { icon: 'tonality', label: 'thermal_image', value: 'tags:thermal_image ' },
        { icon: 'assessment', label: 'report', value: 'tags:report ' },
        { icon: 'insert_drive_file', label: 'project_file', value: 'tags:project_file ' },
        { icon: 'more_horiz', label: 'other', value: 'tags:other ' }
      ]
    },
    { icon: 'group', label: 'FILES.SHARED_FILES', value: 'shared:true' }
  ];
  searchQuery$: BehaviorSubject<string> = new BehaviorSubject(null); // null means pristine, untouched
  selectedConvertibleCount = 0;
  selectedCount = 0;
  selectedOwner = false;
  selectedRegenerableCount = 0;
  selectedZIPCount = 0;
  @ViewChild(MatSort) sort: MatSort;
  subscription: Subscription;
  @ViewChild('table') table: MatTable<Element>;
  theme: string;
  userRole = UserRole;
  accountType = AccountType
  viewMode: 'grid' | 'list' = 'list';
  viewModeTmp = false;
  @ViewChild('wrapperFiles') wrapperFiles: ElementRef;
  zipTypes = [
    'application/x-zip-compressed',
    'application/zip'
  ];
  matchDataUpdate:boolean;
  private allFiles: FileModel[] = []; // used to store the files
  private filteredFiles: FileModel[] = []; // used to store the filtered files
  public isFilterListening = false; // used to determine if the filter is listening to the searchQuery$ or not
  private isPageLoaded = false; // used to determine if the pagination's page is loaded or not (to avoid loading the same page twice)
  private currentSort: Sort = {} as Sort; // used to store the current sort of the table
  siteId = undefined
  portfolioId = undefined
  @ViewChildren('lazyImage') lazyImages: QueryList<ElementRef>;

  get getDisplayedColumns(): string[] {
    if (this.location === 'inspection' || this.location === 'inspection3d') {
      return this.displayedColumnsInspection;
    } else {
      return this.displayedColumns;
    }
  }

  /** Check if logged user has can edit the activeFolder */
  get canEditActiveFolder(): boolean {
    return this.foldersService.canEditActiveFolder;
  }

  /** Whether some, but not all elements are selected. */
  get isAnySelected(): boolean {
    return this.selectedCount > 0 && this.selectedCount !== this.dataSource.length;
  }

  /** Whether the number of selected elements matches the loaded items. */
  get isLoadedSelected(): boolean {
    return this.selectedCount > 0 && this.selectedCount >= this.dataSource.length;
  }

  /** Whether the number of selected elements matches the total items. */
  get isTotalSelected(): boolean {
    return this.selectedCount > 0 && this.selectedCount >= this.totalItems;
  }

  get showFAB(): boolean {
    // Hide the fab button if some rows are selected
    // Show the fab button, but only if sidenavDetail is closed
    return this.canEditActiveFolder && !(this.sidenavDetailService.opened$.value || this.selectedCount);
  }

  constructor(
      public filesService: FilesService,
      private annotationsService: AnnotationsService,
      private breakpointObserver: BreakpointObserver,
      private dialog: MatDialog,
      private dialogService: DialogService,
      private foldersService: FoldersService,
      private headerService: HeaderService,
      private inspectionService: InspectionService,
      private lambdaService: LambdaService,
      private languageService: LanguageService,
      public loginStateService: LoginStateService,
      private ngZone: NgZone,
      private ordersService: OrdersService,
      private platform: Platform,
      @Inject(PLATFORM_ID) private platformId: Object,
      private renderer: Renderer2,
      private route: ActivatedRoute,
      private router: Router,
      private scrollDispatcher: ScrollDispatcher,
      private sidenavDetailService: SidenavDetailService,
      private sidenavNavigationService: SidenavNavigationService,
      private snackBar: MatSnackBar,
      private storageService: StorageService,
      private themeService: ThemeService,
      private translate: TranslateService,
      private uploadService: UploadService,
      private viewportRuler: ViewportRuler,
      private cdr: ChangeDetectorRef,
      private portfolioService:PortfolioService,
      public permissionsService: PermissionsService
  ) {
    this.themeService.changed$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(theme => {
      this.theme = theme;
    });

    this.filesService.fetchingChange$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((state)=> this.fetching=state)

    this.annotationsService.annotations$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((annotations) => {
      if (this.dataSourceBeforeFilter && this.lastIndexID) {
        const records = {};
        annotations.forEach(other => {
          if (!records[other.fileID]) {
            const link = this.dataSourceBeforeFilter.find(file => file._id === other.fileID);
            if (!link) return;
            link.annotations = [other];
            records[other.fileID] = link;
          } else {
            records[other.fileID].annotations.push(other);
          }
        })
        //update file for filtering
        this.isFilterListening = false
        this.getFilter();
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0); // On mobile portrait the list is not scrolled at the top, so we force it here
      setTimeout(() => window.scrollTo(0, 0), 300); // Scroll again after the browser toolbars are finished animating
    }

    this.breakpointObserver.observe([
      Breakpoints.XSmall,
      Breakpoints.Small,
      Breakpoints.Medium
    ])
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.isSmall = this.breakpointObserver.isMatched(Breakpoints.Small);
      this.isXSmall = !this.isSmall && this.breakpointObserver.isMatched(Breakpoints.XSmall);

      // On small devices show only the basic columns, store the previous columns to restore them later
      if (this.isXSmall) {
        const columns = ['avatar-checkbox', 'name', 'modified', 'more'];
        if (JSON.stringify(this.displayedColumns) !== JSON.stringify(columns)) {
          this.storageService.setItem('files-displayed-columns', this.displayedColumns);
        }
        this.displayedColumns = columns;
      } else {
        const columns = this.storageService.getItem('files-displayed-columns') as Array<string>;
        if (columns && columns.length > 1) {
          this.displayedColumns = this.columns.filter(c => c.mayNotBeHidden || columns.some(v => c.value === v)).map(c => c.value);
        }
      }
    });

    this.headerService.activeHeader$.next(this);
    this.headerService.toggleSearch.emit(false); // Close search

    this.languageService.dir$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(dir => this.dir = dir);

    this.loginStateService.login$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(login => {
      if (login) {
        Promise.resolve(undefined).then(() => {
          this.login = login;

          // show tags for admins only
          if ([AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login.accountType)) {
            this.displayedColumns.splice(4, 0, 'tags');
            this.columns.splice(9, 0, { label: 'FILE.TAGS', value: 'tags' },)
          }
        });
      }
    });

    this.router.events
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(data => {
      if (data instanceof NavigationStart) {
        this.navigationStart = data;
      }
      if (data instanceof NavigationEnd) {
        const firstChild = this.route.snapshot.parent.children[0];
        const secondChild = this.route.snapshot.parent.children[1];
        this.siteId = this.route.snapshot.params.siteID;
        this.portfolioId = this.route.snapshot.params.portfolioID;
        const newDetailID = secondChild && secondChild.outlet === 'detail' ? secondChild.params['fileID'] : '';

        if(firstChild.routeConfig.path.includes('portfolio')) {
          this.isSiteURL = true
        } else { this.isSiteURL = false }

        if(firstChild.routeConfig.path.includes('inspection3d')) {
          this.pageSize = 2000;
          this.location = 'inspection';
          this.locationURL = 'inspection3d';
        }  else if(firstChild.routeConfig.path.includes('inspection')) {
          this.pageSize = 50;
          this.location = 'inspection';
          this.locationURL = 'inspection';
        }
        else {
          this.pageSize = 50;
          this.location = 'files';
          this.locationURL = 'files';
        }
        if (firstChild && firstChild.outlet === 'primary') {

          // Get orderID from &orderID query parameter
          const orderID = Number(firstChild.queryParams['orderID']);
          if (orderID) {
            if (this.subscription) {
              this.subscription.unsubscribe();
            }

            // Search if a folder exists with the orderID
            const filter = { $and: [{ isFolder: true }, { name: `${prefix}${orderID}` }] };
            this.filesService.findMany(filter, null, 0, 1, null).subscribe(
            response => {
              if (response.data && response.data.length > 0 ) { // Folder exists
                this.navigateToFolder(response.data[0]._id);
              } else { // Folder doesn't exist
                const folder: Folder = {
                  name: `${prefix}${orderID}`,
                  orderID,
                  pattern: `${prefix}{orderID}_{yyyy-mm-dd}_{name}`
                }; // Create new orderID folder
                this.newFolder(folder);
              }
            },
            error => {
              this.dialogService.showDialog('FILES.GETTING_FAILED', error.status, error.url, error.error);
            });

          } else if (firstChild.params['folderID'] === 'search') { // Search was changed
            const query = firstChild.queryParams['query'];
            if (query && query !== this.searchQuery$.value && // If search query has changed and
              (!this.navigationStart || this.navigationStart.navigationTrigger === 'popstate')) { // first or history navigation
              if (this.subscription) {
                this.subscription.unsubscribe();
              }
              this.searchQuery$.next(query);
              this.headerService.toggleSearch.emit(query);
            }
          } else if (this.activeFolderID$.value !== firstChild.params['folderID']) { // Folder was changed

            this.lastIndex = -1; // Reset lastIndex
            this.lastIndexID = '';
            this.lastSelected = 0; // Reset lastSelected
            if (this.searchQuery$.value) { // Close the search if set
              this.searchQuery$.next(null); // Don't trigger a 300ms search query, the activeFolderID$ will do it below
              this.headerService.toggleSearch.emit(false);
            }
            // Set next activeFolderID$ from files/:folderID parameter
            this.activeFolderID$.next(firstChild.params['folderID']);
            if (firstChild.params['folderID'].length === 24) {
              this.filesService.findOne(firstChild.params['folderID'])
              .pipe(takeUntil(this.ngDestroy$),debounceTime(10))
              .subscribe(
              response => {
                if (response){
                  this.folderModel = response.data;
                  this.inspectionService.setInspectionFile(this.folderModel);
                  // This will fix the breadcrumbs inside
                  if (firstChild.routeConfig.path.includes('portfolio')) {
                    this.getSite()
                      .pipe(take(1))
                      .subscribe(([site, portfolio]) => {
                        this.updateNavigation(portfolio, site)
                      })
                  }
                  if (this.gridViewedFolderNames.includes(this.folderModel.name)) {
                    if (this.viewMode !== 'grid') {
                    this.viewMode = 'grid';
                    this.viewModeTmp = true;
                  }
                  } else if(this.viewModeTmp) {
                      this.viewMode = 'list';
                      this.viewModeTmp = false;
                }

                }
              });
            }
          } else if (this.searchQuery$.value && this.activeDetailID === newDetailID) { // Navigated back from search
            this.searchQuery$.next(null); // Don't trigger a 300ms search query, we do it manually below
            this.headerService.toggleSearch.emit(false);
            this.refreshed.emit('refreshed');
          }
        }

        // Set activeDetailID from file/:fileID parameter
        if (this.activeDetailID !== newDetailID) { // Detail was changed
          this.activeDetailID = newDetailID;
          this.lastIndexID = newDetailID;
          this.sendSelectedToInspect();
          this.refreshIndex();
        }
      }
    });

    // Set the fetching status before the 300ms delay, so that the empty-status doesn't show
    this.searchQuery$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.fetching = true;
      if (this.location === 'files') {
        this.isPageLoaded = false;
      }
    });

    this.scrollDispatcher.scrolled()  // If the user has scrolled within 200px of the bottom
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe((scrollable: CdkScrollable) => {
      const bottom = scrollable ? scrollable.measureScrollOffset('bottom') : null;
      this.ngZone.run(() => {   // Fix https://github.com/angular/components/pull/8545
        this.scrolled(bottom);  // Run this in `NgZone.run`, or the table will not renderRows
      });
    });

    this.sidenavDetailService.opened$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
     // this.scrolled(null);
      this.updateCardsWidth();
    });

    this.sidenavDetailService.resized$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.updateCardsWidth();
    });

    this.sidenavNavigationService.opened$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.updateCardsWidth();
    });

    this.sidenavNavigationService.resized$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.updateCardsWidth();
    });

    this.uploadService.queueItems$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(queueItems => {
      this.queueItems = queueItems;
    });

    this.viewportRuler.change() // If screen size has changed
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(() => {
      this.scrolled(null);
    });
  }

  @HostBinding('class.droppable') get droppable(): boolean { return this.canEditActiveFolder && !this.fetching; }
  @HostBinding('attr.tabindex') get tabindex(): string { return '0'; }
  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    if (this.fetching){
      $event.returnValue = "Please wait a moment. The page is still loading...";
    }
  }

  updateValue(){
    if (this.matchDataUpdate){
      this.matchDataUpdate=false;
    }else{
      this.matchDataUpdate=true;
    }
  }

  cancel(): void {
    this.lastIndex = -1; // Reset lastIndex
    this.lastIndexID = '';
    this.lastSelected = 0; // Reset lastSelected
    this.toggleSelectAll(false); // Cancel selection
  }

  public getSite(): Observable<[Site, Portfolio]> {
    const site = this.route.snapshot.params.siteID;
    const portfolio = this.route.snapshot.params.portfolioID;
    return combineLatest(
      this.portfolioService.getSiteById(site)
        .pipe(map(resp => resp.data )),
      this.portfolioService.getPortfolioByUser(this.loginStateService.loggedUser$.value._id.toString())
        .pipe(map(resp => resp.data[0] )),
    )
  }

  public updateNavigation(portfolio: Portfolio, site: Site): void {
    this.headerService.breadCrumbs$.next([{
      label: portfolio?.name,
      link: ['portfolios', portfolio._id]
    }, {
      label: site.name,
      link: ['portfolios', portfolio._id, 'sites' ,site._id]
    }, {
      label: this.folderModel.name,
      link: ['portfolios', portfolio._id, 'sites', site._id,'files', this.folderModel._id]
    }])
  }

  convert2JPG(): void {
    const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];
    const filtered = selected.filter(item => {
      if (item.width && (!this.displayableTypes.includes(item.mimeType))) {
        return item;
      }
    });

    const fileIDs = filtered.map(file => ({ _id: { $oid: String(file._id) }}));

    this.filesService.convert2JPG(fileIDs).subscribe()
  }

  /** Updates the selectedCount & selectedOwner to be used more efficiently in templates and returns the count. */
  countSelectedRows(): number {
    let count = 0;
    let convertibleCount = 0;
    let regenerableCount = 0;
    let zipCount = 0;
    let owner = false;

    if (this.dataSource) {
      this.dataSource.forEach((item: FileModel) => {
        if (item.selected) {
          count++;
          if (item.width && (!this.displayableTypes.includes(item.mimeType))) {
            convertibleCount++;
          }
          if (item.width && (this.displayableTypes.includes(item.mimeType))) {
            regenerableCount++;
          }
          if (this.zipTypes.includes(item.mimeType)) {
            zipCount++;
          }

          // Check if logged user is the owner of some of the selected items
          // This will show a delete button in the contextual header
          if (!owner &&
              ((this.login && this.login.accountType === AccountType.SUPERADMIN))) {
            owner = true;
          }
        }
      });
    }

    // Angular executes template expressions after every change detection cycle
    // Here we cache values for Quick execution, as their computation is expensive
    // Use these values in the angular templates
    this.selectedCount = count;
    this.selectedConvertibleCount = convertibleCount;
    this.selectedRegenerableCount = regenerableCount;
    this.selectedZIPCount = zipCount;
    this.selectedOwner = owner;

    return count;
  }

  createZip(): void {
    const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];
    const fileIDs = selected.map(file => ({ $oid: String(file._id) }));
    const folderID = selected[0]['folderID'];
    const orderID = selected[0]['orderID'];
    const name = selected.length === 1 ? `${selected[0]['name']}_` : '';
    const destKey = `${orderID ? `${prefix}${orderID}_` : name}${new Date().toISOString().substr(0, 10)}_archive.zip`;

    const zipRequest: ZipRequest = {
      bucket: environment.storage,
      destKey,
      fileIDs,
      folderID: folderID ? { $oid: selected[0]['folderID'] } : null,
      orderID
    };
    this.filesService.createZip(zipRequest)
    .subscribe(() => {},
    error => {
      this.uploadService.queueItems$.next(this.queueItems);
      this.dialogService.showDialog('FILES.CREATE_ZIP', error.status, error.url, error.error);
    });

    this.refreshed.emit('refreshed'); // Refresh the list to prevent the user to click the create Zip button multiple times
  }

  checkData(): void {
    const dialogRef = this.dialog.open(DataCheckComponent, {
      width: '90%',
      direction: this.dir,
      data: {
        folderModel: this.folderModel
      }
    });
  }

  delete(fileID?: string): void {
    if (fileID || this.selectedCount > 0) {
      this.translate.get([
        this.selectedCount > 1 ? 'FILES.DELETE' : 'FILE.DELETE',
        'ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'
      ]).subscribe(translations => {

        const dialogRef = this.dialogService.showDialog(null, null,
          this.selectedCount > 1 ? translations['FILES.DELETE'] : translations['FILE.DELETE'],
          translations['ARE_YOU_SURE_YOU_WANT_TO_CONTINUE'], true) as Observable<boolean>;

        dialogRef.subscribe(confirm => {
          if (confirm) {
            const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];
            const filter = { _id : (fileID ? { $oid: fileID } : { $in: selected.map(f => ({ $oid: f._id })) }) };

            this.fetching = true;
            this.filesService.deleteMany(filter).subscribe(
              () => {
                // If detail is open and the activeDetailID is the removed file or multiple details were shown
                if (this.sidenavDetailService.opened$.value && ((fileID && this.activeDetailID === fileID) || selected.length > 1)) {
                  // Then show the details of the parent folder of the removed files
                  // If we are in the root folder then close the detail bar
                  this.router.navigate([{ outlets: {
                    detail: this.activeFolderID$.value && this.activeFolderID$.value.length === 24 ?
                    ['file', this.activeFolderID$.value] : null
                  }}], { queryParamsHandling: 'merge' });
                }
                this.refreshed.emit('refreshed');
                this.foldersService.refresh();
              },
              error => {
                this.fetching = false;
                this.dialogService.showDialog('FILES.DELETE_FAILED', error.status, error.url, error.error);
              }
            );
          }
        });
      });
    }
  }

  download(file = null): void {
    this.filesService.download(this.renderer, file, this.login.token);

    // Deselect all to prevent the user to click the download button multiple times
    setTimeout(() => {
      this.toggleSelectAll(false);
    }, 1);
  }

  share(): void {
    const files = this.dataSource.filter(f => f.selected);
    if (files.length !== 0) {
      const first = files[0];
      const projectWithAddress = this.ordersService.getAddress(first.orderID, true).split(' | ');
      let [project, address] = ['', ''];
      if (projectWithAddress.length === 2) {
        address = projectWithAddress[0];
        project = projectWithAddress[1];
      }
      this.dialog.open(ShareLinkComponent, {
        direction: this.dir,
        width: '500px',
        maxWidth: '100%',
        data: {
          files: files.map(f => f._id),
          orderId: first.orderID,
          project,
          address,
        }
      });
    }
  }

  formatAnnotations(severity: string, row: any): number {
    if (row.annotationStats.total>0) {
      return row.annotationStats[severity]
    }
    return 0
  }

  formatPermissions(permissions: Array<Permission>): string {
    return permissions ? String(permissions.length) : null;
  }

  handleFilesInput(files: FileList): void {
    this.uploadService.handleFilesInput(files, this.activeFolderID$.value);
  }

  handleFolderInput(files: FileList): void {
    this.uploadService.handleFilesInput(files, this.activeFolderID$.value);
  }

  /** Check if file/folder can be moved (dropped) on the client-side */
  isDroppable(dragIDs: Array<string>, dragParentID: string, dropID: string): boolean {
    if ((!dragParentID && !dropID) || dragParentID === dropID) {
      this.translate.get('FOLDERS.CANNOT_DROP_INTO_SAME_PARENT').subscribe(translation => {
        this.snackBar.open(translation, '', { duration: 3000 });
      });

      return false;
    }

    if (dragIDs.includes(dropID)) {
      this.translate.get('FOLDERS.CANNOT_DROP_INTO_ITSELF').subscribe(translation => {
        this.snackBar.open(translation, '', { duration: 3000 });
      });

      return false;
    }

    return true;
  }

  navigateToFolder(folderID: string): void {
    this.lastIndex = -1; // Reset lastIndex
    this.lastIndexID = '';
    this.lastSelected = 0; // Reset lastSelected

    if (this.searchQuery$.value) { // Close the search if set
      this.searchQuery$.next(null); // Set to pristine without triggering a query
      this.headerService.toggleSearch.emit(false);
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.router.navigate([{ outlets: {
      primary: ['files', folderID],
      detail: (/*this.sidenavDetailService.opened$.value &&*/ folderID.length === 24) ? ['file', folderID] : null //This was updated to force details tab to be opened always
    }}]); // Navigate to folder
  }

  clearFilterDataSource(filterFileData: FilterFileData): boolean {
    if (!Object.keys(filterFileData).length) {
      this.dataSource = this.dataSourceBeforeFilter;

      if (this.location === 'inspection' || this.location === 'inspection3d') {
        this.inspectionService.setDataSourceBeforeFilter(this.allFiles);
      }

      this.router.navigate([], {
        relativeTo: this.route,
        queryParams: {
          feature: null,
          annotationType: null,
          name: null,
          comment: null
        },
        // queryParamsHandling: 'merge'
      });
      return true;
    }
    return false;
  }

  newFolder(folder: Folder): void {
    // Define new folder
    folder = Object.assign(folder, {
      isFolder: true,
      mimeType: 'application/vnd.folder'
    });
    // If sub-folder then set the parent folderID
    if (this.activeFolderID$.value && this.activeFolderID$.value.length === 24) {
      folder.folderID = { $oid: this.activeFolderID$.value };
    }

    this.fetching = true;
    this.filesService.insertMany([folder])
    .subscribe(response => {
      this.fetching = false;
        folder = response.data[0];
        // If sidenavDetail is opened then show the new folder

        if (this.sidenavDetailService.opened$.value) {
          this.router.navigate([{ outlets: { detail: ['file', folder._id] }}], { queryParamsHandling: 'merge' });
        }
  
        this.foldersService.upsertItem(folder); // Insert folder in the folders tree
  
        if (folder.orderID && !folder.folderID) { // Folder is automatically created for an orderID inside my-files
          this.router.navigate([{ outlets: {
            primary: ['files', folder._id], // Navigate to the orderID folder
            detail: null // If sidenavDetail is opened then close it to allow the user to upload files
          }}]); // orderID query parameter will be removed
        } else { // Folder is manually created
          this.refreshed.emit('refreshed'); // Refresh to show also inherited fields
          if (this.sidenavDetailService.opened$.value) {
            // If sidenavDetail is opened show the details
            this.router.navigate([{ outlets: { detail: ['file', folder._id] }}], { queryParamsHandling: 'merge' });
          }
        }
    },
    error => {
      this.fetching = false;
      this.dialogService.showDialog('FOLDER.CREATE_FAILED', error.status, error.url, error.error);
    });
  }

  newFolderDialog(): void {
    const dialogRef = this.dialog.open(FolderDialogComponent, {
      width: '360px',
      direction: this.dir
    });

    dialogRef.afterClosed().subscribe((folder: Folder) => {
      if (folder ) { // If not cancelled

        if (this.activeFolderID$.value && this.activeFolderID$.value != 'my-files') {
          if (this.activeFolderID$.value && this.activeFolderID$.value.length === 24) {
            folder.folderID = { $oid: this.activeFolderID$.value }; // If sub-folder then set the parent folderID
            folder.orderID = this.folderModel.orderID
          }
          this.newFolder(folder);
        } else {
          this.dialogService.showDialog('Error', null, 'You are not allowed to create a folder here', null,null,true);
        }
      }
    });
  }

  hide(event?: MouseEvent,file?: FileModel, value?: boolean): void {

    if (event) {
      event.stopPropagation(); // Stop the click event from propagating to parent elements
    }

    let fileIDs: string[]
    if (!file) {
      const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];
      fileIDs = selected.map(file => ( String(file._id) ));
    } else {
      fileIDs = [String(file._id)];
    }

    this.filesService.hideFiles(fileIDs,value).subscribe(
      () => {
        //file.hidden=false
        this.refreshed.emit('refreshed');
        if (value) {
          this.dialogService.showDialog('FILE.HIDE_FILE_SUCCESS', null, 'files.component', null, null, true);
        } else {
          this.dialogService.showDialog('FILE.UNHIDE_FILE_SUCCESS', null, 'files.component', null, null, true);
        }
        this.foldersService.refresh();
      },
      error => {
        this.dialogService.showDialog('FILE.HIDE_FILE_FAILED', error.status, error.url, error.error);
      }
    );
  }

  ngAfterViewInit(): void {
    // Find contextMenuTrigger user for right-click
    this.contextMenuTrigger = this.menuTriggers.find(trigger => trigger.menuData['id'] === 'contextMenuTrigger');

    this.activeFolderID$
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(_ => this.isPageLoaded = false)

    // This will trigger fetching data (pages from backend)
    merge(
      this.activeFolderID$          // Router files/:id has changed
      .pipe(tap(value => {
        if (value) {                // means if we changed the folder
          this.cursor = 0
          this.filesService.data$.next([]);
        }
      }),
        filter(v => v !== null)     // Skip null value, means skip it if we are in the same folder
      ),  
      this.filesService.refreshed   // External refresh called
      .pipe(
        tap(value =>{
        this.cursor = 0
        this.filesService.data$.next([]);
        })
      ),                                          
      this.nextPage.                // Load next page
      pipe(    
        debounceTime(100)           // Skip input values between 100 for Type-ahead
      ),                            
      this.refreshed                // Component refresh called
      .pipe(
        tap(value => {
        this.cursor = 0
        this.filesService.data$.next([]);
        }) 
      ),                     
      this.searchQuery$             // Search has changed
      .pipe(
        tap(value =>{
        this.cursor = 0
        this.filesService.data$.next([]);
        }),
        skipWhile(v => v === null),  // Skip null value
        debounceTime(300)            // Skip input values between 300ms for Type-ahead
      ),
      this.sort.sortChange
      .pipe(
        tap(value => {
          this.cursor = 0
          this.filesService.data$.next([]);
        }), debounceTime(20)
      ),               // Sort changed
    )
    .pipe(takeUntil(this.ngDestroy$),debounceTime(50))
    .subscribe(value => {
      // When value = null it means the searchQuery$ was set to pristine
      // and because of the debounceTime(300) it executed with a 300ms delay
      if (!value) {
        return;
      }

      if (this.subscription) {
        this.subscription.unsubscribe(); // Cancel previous request
      }

      let search = this.searchQuery$.value || '';
      search = search.trim();

      const filter = searchToFilter(search);

      let msToWait = 0

      if (!Object.keys(filter).length) {
        // Set folderID from the route ID
        filter['folderID'] = this.activeFolderID$.value && this.activeFolderID$.value.length === 24
            ? { $oid: this.activeFolderID$.value }
            : { $exists: false };
    
        if (![AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login.accountType)) {
            filter['orderID'] = { $in: this.assignOrderIDs() };
    
            // Check if the assigned order IDs are empty and remove 'orderID' key from filter if true
            if (filter['folderID'].$oid) {
                delete filter['orderID'];
            }
    
            msToWait = 50;
        }
      }

      // To preserve the order of the sort fields we use Object.assign
      let sort = {};
      if (this.sort.active !== '_id' && this.sort.active !== 'mimeType' && !search.includes('type:')) {
        // Sort folders at the top, but only if not sorted or searched by type
        sort = Object.assign(sort, { isFolder: -1 });
      }
      if (this.sort.active && this.sort.direction !== '' && this.sort.active !== 'annotations.stateDimension') {
        // Sort by selected column
        sort = Object.assign(sort, { [this.sort.active]: this.sort.direction === 'desc' ? -1 : 1 });
      }
      if (this.sort.active === 'annotations.stateDimension') {
        sort = Object.assign(sort, { 'annotations.0.stateDimension': this.sort.direction === 'desc' ? -1 : 1 });
      }
      if (this.sort.active !== '_id' && this.sort.active !== 'created') {
        // Sort also by created to ensure correct pagination without getting duplicates
        sort = Object.assign(sort, { created: -1 });
      }

      // If sorted or (not next page or first navigation)
      if (value && value.hasOwnProperty('direction') || (value !== 'nextPage' && this.navigationStart)) {
        this.fetching = true;
        this.lastIndex = -1; // Reset lastIndex
        this.lastIndexID = '';
        this.lastSelected = 0; // Reset lastSelected
        // Disable toggleSelectAll to prevent a refresh in pano
        //this.toggleSelectAll(false);
        this.scrollIntoView(0);
      }

      let fields = {}
      if (this.location === 'files'){
        // Show all fields except childrenCount
        fields = { childrenCount: 0 };
        // Seaarching listener is triggered
        if (search) {
          // Show path column"
          if (!this.displayedColumns.includes('path')) {
            // Show path column
            this.displayedColumns.splice(2, 0, 'path');
          }
        } else {
          // Hide path column
          this.displayedColumns = this.displayedColumns.filter(column => column !== 'path');
          // Exclude path field if no search
          fields['path'] = 0;
        }

        // nextPage or refreshed listener is triggered
        if (
          value === 'nextPage'
          || value === 'refreshed'
        ) {
          this.currentSort = value;
          this.isPageLoaded = false;
        }

        // Sort listener is triggered
        if (this.currentSort.active !== value.active || this.currentSort.direction !== value.direction) {
          this.currentSort = value;
          this.isPageLoaded = false;
        }

        setTimeout(() => {
          if (!this.isPageLoaded) {
            this.getAllFiles(filter, sort, fields, value === 'nextPage' || value === 'refreshed', search);
          }
        }, msToWait);
        

      } else if (this.location === 'inspection' || this.location === 'inspection3d') {
        if (!this.isPageLoaded) {
          this.listenInspectionFiles(search);
        }

        if (value === 'nextPage') {
          // Check if there are more files to load
          if (this.filteredFiles.length > this.filesService.data$.value.length) {
            // Calculate number of new files to load (avoid exceeding filteredFiles length)
            const newFilesToLoad = Math.min(this.pageSize, this.filteredFiles.length - this.filesService.data$.value.length);
            // Extract new files using slice
            const newFiles = this.filteredFiles.slice(this.filesService.data$.value.length, this.filesService.data$.value.length + newFilesToLoad);
            // Update loaded files directly (no need for temporary variable)
            this.filesService.data$.next([...this.filesService.data$.value, ...newFiles]);
          }
          return;
        }

        this.filteredFiles.sort((a, b) => {
          let value = 1;

          for (let key of Object.keys(sort)) {
            let value1: any = a;
            let value2: any = b;
            key.split('.').forEach(path => {
              value1 = value1?.[path];
              value2 = value2?.[path];
            })

            value1 ||= -Infinity;
            value2 ||= -Infinity;

            if (value1 > value2) {
              value = -1 * sort[key];
            } else if (value1 < value2) {
              value = 1 * sort[key];
            } else {
              value = 0;
            }

            if (value !== 0) {
              break
            }
          }
          return value
        })

        if (this.isPageLoaded) {
          this.loadPagesTillFileIsFound()
          setTimeout(() => {
            this.scrollIntoView(this.lastIndex);
            this.fetching = false;
          }, 0);
        }
      }
    });

    this.filesService.data$
    .pipe(takeUntil(this.ngDestroy$),skip(1))
    .subscribe(data => {
      Promise.resolve().then(() => {
        this.fetching = false
        this.dataSource = data.length ? data : undefined;
        this.dataSourceCards = data.filter(file => file.tags?.includes('card'));

        this.hideTable = this.dataSourceCards.length > 0 && ![AccountType.ADMIN, AccountType.SUPERADMIN].includes(this.login.accountType)? true : false;
        this.getCardsThumbails();
        this.dataSourceNoCards = data.filter(file => !file.tags?.includes('card'));
        this.dataSourceBeforeFilter = data.length ? data : undefined;
        if (this.location === 'inspection' || this.location === 'inspection3d') {
           this.inspectionService.setDataSourceBeforeFilter(this.allFiles);
           this.initializeActiveFile()
        }
        this.table.renderRows();
        this.countSelectedRows();
        // After sorting try to find index from activeDetailID
        if (this.lastIndex === -1) {
          setTimeout(() => {
            this.lastIndexID = this.activeDetailID
            this.lastIndex = this.dataSource?.findIndex(file => file._id === this.activeDetailID)
            this.lastSelected = this.lastIndex
            this.refreshIndex()
          }, 0);
        } 
        //this.sendSelectedToInspect();
        this.getFilter();

        // see if the data includes subfolders: this is used to display "Match Data" option
        let hasSubFolder = false;
        for (let obj of data) {
          if (obj.isFolder == true) {
            hasSubFolder = true;
            break;
          }
        }
        if (!hasSubFolder) {
          this.filesService.hasSubFolder$.next(false);
        } else {
          this.filesService.hasSubFolder$.next(true);
        }
      });
    });

    this.filesService.totalItems$
    .pipe(takeUntil(this.ngDestroy$))
    .subscribe(totalItems => {
      Promise.resolve().then(() => {
        this.totalItems = totalItems;
        this.sumSize = this.dataSource ? this.dataSource.reduce((sum, c) => sum + (c.size || 0), 0) : 0;
      });
    });
  }

  // Assign possible orderIDs to the filter for anyone except admins
  private assignOrderIDs(): number[] {
    if (![AccountType.ADMIN,AccountType.SUPERADMIN].includes(this.login.accountType)) {

      if (this.ordersService.orderIds.length === 0) { 
        const cachedData = localStorage.getItem('ordersCache');
        if (cachedData && cachedData != "undefined") {
          const parsedCachedData = JSON.parse(cachedData);
          this.ordersService.orderIds = parsedCachedData.orders
            .map(order => order.legacyId);
            return this.ordersService.orderIds;
        } else {
          this.ordersService.findManyOrders(undefined, "all")
            .pipe(
              mergeMap((resp: any) => {
                return this.ordersService.enrichOrders(resp);
              }),
              tap((resp: any) => {
                // Check if fetched data is different from cached data
                this.ordersService.orderIds = resp.orders.map(order => order.legacyId);
                localStorage.setItem(('ordersCache'), JSON.stringify(resp)); // Update cache with new data
                return this.ordersService.orderIds;
              }),
              takeUntil(this.ngDestroy$))
            .subscribe((resp: any) => {
              // Do nothing
            });
        }
      } else {
        return this.ordersService.orderIds;
      }
    }
  }

  // It initializes the default image to be the first one in the dataset.
  private initializeActiveFile () {
    if (!this.activeDetailID) {
      this.activeDetailID = this.dataSource[0]._id.toString()
      this.lastIndexID = this.activeDetailID
      this.lastIndex = 0
      this.lastSelected = 0
      this.refreshIndex()
      this.showDetails(false, this.dataSource[0]);
    }
  }

  private getAllFiles(filter: object, sort: object, fields: object, concat: boolean, search: string): void {
    this.filesService.findMany(filter, sort, this.cursor, this.pageSize, fields)
      .pipe(takeUntil(this.ngDestroy$))
      .subscribe(response => {
          const pagination = response.pagination;
          if (response.data) {
            this.filesService.totalItems$.next(pagination.totalItems);
          }
          const existingFiles: FileModel[] = [];
          if (concat) {
            existingFiles.push(...this.filesService.data$.value)
          }
          this.filesService.data$.next([...existingFiles, ...(response.data || [])]);
          this.fetching = false;
          this.isPageLoaded = true;
          this.navigateToSearch(search);
          //Updating pagination params
          this.lastCountItemReceived = response.data.length
          this.cursor = pagination.cursor
        },
        error => {
          this.errorHandler(error)
        })
  }

  private enrichFilesWithFeatures(data): Observable<any> {
    return this.annotationsService.getAllAnnotationsFeatures(this.activeFolderID$.value).pipe(
      takeUntil(this.ngDestroy$),
      map(response => {
        const featuresData = response.data;
  
        if (featuresData != null) {
          data.forEach(file => {
            const fileId = file._id;
            const fileFeatures = featuresData[fileId];
  
            if (fileFeatures) {
              file.typesStats = fileFeatures;
            }
          });
        }
  
        return data;
      })
    );
  }

  private listenInspectionFiles(search: string): void {
    this.filesService.currentFiles$
      .pipe(
        switchMap(data =>
          this.enrichFilesWithFeatures(data).pipe(
            takeUntil(this.ngDestroy$)
          )
        )
      )
      .subscribe(
        enrichedData => {
          this.isPageLoaded = true;
          this.fetching = false;
          this.allFiles = enrichedData;
          this.filteredFiles = enrichedData;

          //this.loadPagesTillFileIsFound()
          this.filesService.data$.next(enrichedData.slice(0, this.pageSize));
          this.inspectionService.allFiles$.next(enrichedData);
          this.navigateToSearch(search);
        }
      );
  }

  private errorHandler(error: any): void {
    this.fetching = false;
    this.filesService.data$.next([]);
    this.filesService.totalItems$.next(0);
    if (error?.error?.errors[0].includes('timeout')) {
      error.error = 'TRY_ANOTHER_SEARCH';
    }
    this.dialogService.showDialog('FILES.GETTING_FAILED', null, error.error);
  }

  private navigateToSearch(search: string): void {
    if (search) {
      this.router.navigate(
        [{ outlets: { primary: ['files', 'search'] }}],
        { queryParams: { query: search }, queryParamsHandling: 'merge' }
      );

      this.activeFolderID$.next(null); // When searching there reset the active folder
    }
  }

  getAddress(id: number): string {
    return this.ordersService.getAddress(id)
  }

  getFilter(): void {
    if (this.isFilterListening ) return;
    this.isFilterListening = true;
    this.route.queryParamMap.subscribe((params) => {
      const filterFileData: FilterFileData = {
        ...(params.get('feature') && { feature: params.get('feature').split(',') }),
        ...(params.get('annotationType') && { annotationType: params.get('annotationType').split(',') }),
        ...(params.get('minHeight') && { minHeight: Number(params.get('minHeight')) }),
        ...(params.get('maxHeight') && { maxHeight: Number(params.get('maxHeight')) }),
        ...(params.get('imageType') && { imageType: params.get('imageType').split(',') }),
      };

      setTimeout(() => {
        if (this.allFiles.length) {

          //updating map data
          this.filteredFiles = this.filesService.filterFiles(filterFileData, this.allFiles);
          // updating files data
          this.filesService.data$.next(this.filesService.filterFiles(filterFileData, this.allFiles));

          this.dataSourceNoCards = this.dataSource.filter(file => !file.tags?.includes('card'));
        }
      }, 300);
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy$.next();
    this.ngDestroy$.complete();
    // Clear the data loaded in the service
    this.filesService.data$.next([]);
    this.filesService.totalItems$.next(0);
    this.ordersService.setActiveOrder(null) // It disables collaboration button
    this.permissionsService.resetPermissions()

    // Clear breadCrumbs of the header
    const firstChild = this.route.snapshot.parent.children[0];
    if (firstChild.routeConfig.path.includes('portfolio')) {
      this.headerService.breadCrumbs$.next([]);
    }
  }

  onContextMenu(event: MouseEvent, file: FileModel): void {
    const target = event.target as HTMLElement;
    event.stopPropagation(); // Prevents click event from bubbling up

    if (!target.closest('a, span:not(.mat-button-wrapper)')) { // anchors and spans should trigger default browser menu
      event.preventDefault();
      this.contextMenuPosition.x = `${event.clientX}px`;
      this.contextMenuPosition.y = `${event.clientY}px`;
      if (file) { // Right click on file
        this.contextMenuTrigger.menu = this.menu;
        this.contextMenuTrigger.menuData['file'] = file;
      } else { // Right click on empty container
        this.contextMenuTrigger.menu = this.fabMenu;
      }
      if (this.contextMenuTrigger.menu) {
        this.contextMenuTrigger.openMenu();
      }
    }
  }

  onDblClick(event: MouseEvent, currentItem: any, index: number): void {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const targetCheck = target.className.indexOf('mat-checkbox-layout') > -1 || // expanded area 48px
        target.className.indexOf('mat-checkbox-inner-container') > -1; // checkbox icon 24px

    if (!targetCheck) { // Ignore dblClick on checkbox
      if (currentItem.isFolder && !(currentItem.tags?.includes('inspection') || currentItem.tags?.includes('inspection3d') || currentItem.tags?.includes('viewer'))) { // Open folder
        this.navigateToFolder(currentItem._id);
      } else if (currentItem.isFolder && currentItem.tags?.includes('inspection')) {
        if (this.isSiteURL) {
          this.router.navigate(
            [{ outlets: { primary: ['portfolio', this.siteId, 'inspection', currentItem._id], detail: null }}],
            { queryParamsHandling: 'merge' }
          );
        } else {
          this.router.navigate(
            [{ outlets: { primary: ['inspection', currentItem._id], detail: null }}],
            { queryParamsHandling: 'merge' }
          );
        }
      } else if (currentItem.isFolder && currentItem.tags?.includes('inspection3d')) {
        this.router.navigate(
          [{ outlets: { primary: ['inspection3d', currentItem._id], detail: null }}],
          { queryParamsHandling: 'merge' }
        );
      } else if(currentItem.isFolder && currentItem.tags?.includes('viewer')) {
        this.router.navigate(
          [{ outlets: { primary: ['viewer', currentItem._id], detail: null}}],
          { queryParamsHandling: 'merge' }
        );
      } else if (currentItem.webViewLink) { // Open file
        if (this.folderModel.tags?.includes('inspection')){
          this.router.navigate(['', { outlets: { primary: ['inspection', this.folderModel._id], detail: ['file', currentItem._id]}}])
        } else if (this.folderModel.tags?.includes('inspection3d')){
          this.router.navigate(['', { outlets: { primary: ['inspection3d', this.folderModel._id], detail: ['file', currentItem._id]}}])
        } else {
          this.showDetails(false, currentItem, { view: 'fullscreen' });
        }
      }
    }
    window.getSelection().removeAllRanges(); // Clear text selection
  }

  // Remove dragging class from all
  onDragEnd(event: DragEvent): void {
    const target = event.target as HTMLElement;

    this.dataSource.forEach(item => item.dragging = null); // Multiple

    if (target.className.indexOf('dragging') > -1) { // Single
      this.renderer.removeClass(target, 'dragging');
    }
    Array.from(document.getElementsByClassName('drag-image')).forEach((element: HTMLElement) =>
      this.renderer.removeChild(document.body, element)); // Remove temporary drag-image element

    Array.from(document.getElementsByClassName('drop-zone')).forEach((element: HTMLElement) =>
      this.renderer.removeClass(element, 'drop-zone')); // Remove highlight from all drop-zones
  }

  @HostListener('dragenter.out-zone', ['$event', '$event.target'])
  onDragEnter(event: DragEvent, target: HTMLElement): void {
    this.onDragOver(event, target);

    if (target.nodeType === Node.ELEMENT_NODE) {
      const dropZone = target.closest('.droppable') as HTMLElement;
      if (this.platform.SAFARI) {
        this.relatedTarget = dropZone; // Safari fix that doesn't yet support event.relatedTarget
      }
      if (dropZone && dropZone.className.indexOf('dragging') === -1 && dropZone.className.indexOf('drop-zone') === -1) {
        Array.from(document.getElementsByClassName('drop-zone')).forEach((element: HTMLElement) =>
          this.renderer.removeClass(element, 'drop-zone')); // Remove highlight from other drop-zones (fix for Safari)

        this.renderer.addClass(dropZone, 'drop-zone'); // Highlight drop-zone element
      }
    }
  }

  @HostListener('dragleave.out-zone', ['$event', '$event.relatedTarget', '$event.target'])
  onDragLeave(event: DragEvent, relatedTarget: HTMLElement, target: HTMLElement): void {
    // The element the cursor just entered
    let enter = event.relatedTarget && relatedTarget.closest('mat-row[draggable], div[draggable], .droppable') as HTMLElement;
    if (this.platform.SAFARI && !enter) {
      enter = this.relatedTarget;
    }
    // The element the cursor just left
    const leave = target && target.closest('mat-row[draggable], div[draggable], .droppable') as HTMLElement;

    if (enter !== leave) { // Different elements
      // Remove highlight from other drop-zones
      Array.from(document.getElementsByClassName('drop-zone')).forEach((element: HTMLElement) => {
        if (!enter || // Leave browser
          (!enter.attributes['draggable'] && enter.className.indexOf('droppable') === -1) || // Leave drop zones
          (enter.attributes['draggable'] && enter.className.indexOf('droppable') > -1 && element !== enter) || // Not entered folder
          (leave && leave.attributes['draggable'] && leave.className.indexOf('drop-zone') > -1 && element === leave) // Leave row/thumbnail
        ) {
          this.renderer.removeClass(element, 'drop-zone');
        }
      });
    }
  }

  @HostListener('dragover.out-zone', ['$event', '$event.target'])
  onDragOver(event: DragEvent, target: HTMLElement): void {
    target = target.closest('.droppable') as HTMLElement;
    // Firefox fires ondragover events on text nodes
    if (target && target.nodeType === Node.ELEMENT_NODE) {
      event.dataTransfer.dropEffect = target.classList.contains('dragging') ? 'none' : 'copy';
      // Prevent default behavior (Prevent file from being opened)
      event.preventDefault();
      event.stopPropagation();
    }
  }

  onDragStart(event: DragEvent): void {
    const target = event.target as HTMLElement;
    this.relatedTarget = null; // Safari fix that doesn't yet support event.relatedTarget

    const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];
    const fileIDs = selected.map((file: FileModel) => { // Multiple files
      file.dragging = true;

      return String(file._id);
    });

    let folderIDs = [];
    if (fileIDs.length === 0) { // Single
      this.renderer.addClass(event.target, 'dragging');
      fileIDs.push(target.id);
      folderIDs = this.dataSource.filter(file => String(file._id) === target.id).map(file => file.folderID);
    } else {
      selected.forEach((file: FileModel) => { // Get distinct folderIDs
        if (!folderIDs.includes(file.folderID)) {
          folderIDs.push(file.folderID);
        }
      });
    }

    event.dataTransfer.setData('text/plain', JSON.stringify({ // 'text/plain' is supported by all browsers
      fileIDs,  // Dragged ids
      folderID: folderIDs.length === 1 ? folderIDs[0] : null // Source parent folderID
    }));

    // Set DownloadURL to drag single files to desktop
    // Supported by Chrome and Opera
    if (fileIDs.length === 1) {
      const file = this.dataSource.find(item => item._id === fileIDs[0]);
      if (!file.isFolder) {
        event.dataTransfer.setData('DownloadURL', `${file.mimeType}:${file.name}:${file.webViewLink}`);
      }
    } else {

      this.translate.get('MOVE_ITEMS', { count: this.selectedCount })
      .subscribe(translation => {
        const crt = this.renderer.createElement('div');
        this.renderer.setAttribute(crt, 'class',
            'drag-image mat-badge mat-badge-warn mat-badge-overlap mat-badge-above mat-badge-after mat-badge-medium');

        const text = this.renderer.createText(translation);
        this.renderer.appendChild(crt, text);

        const cnt = this.renderer.createText(String(fileIDs.length));
        const badge = this.renderer.createElement('span');
        this.renderer.setAttribute(badge, 'class', 'mat-badge-content mat-badge-active');
        this.renderer.appendChild(badge, cnt);
        this.renderer.appendChild(crt, badge);

        document.body.appendChild(crt);
        event.dataTransfer.setDragImage(crt, 0, 12);
      });
    }
  }

  @HostListener('drop', ['$event', '$event.target'])
  onDrop(event: DragEvent, target: HTMLElement): void {
    event.preventDefault(); // Prevent default behavior (Prevent file from being opened)

    const dropZone = target.closest('.droppable') as HTMLElement; // The drop zone

    let dropID = null;
    if (dropZone.tagName === 'APP-FILES' && this.activeFolderID$.value.length === 24) { // Dropped on host
      dropID = this.activeFolderID$.value; // Destination parent folderID
    } else if (dropZone.id && dropZone.id.length === 24) { // Drop on another folder
      dropID = dropZone.id;
    }

    this.renderer.removeClass(dropZone, 'drop-zone');

    // BUG: Safari doesn't get data from Chrome and Opera, always empty
    // https://github.com/ProjectMirador/mirador/issues/1433
    const text = event.dataTransfer.getData('text/plain');
    const data = text ? JSON.parse(text) : null;
    // Move internal files
    if (data && data.fileIDs) {

      const droppable = this.isDroppable(data.fileIDs, data.folderID, dropID);
      if (droppable) { // Avoid dropping a folder inside itself or one of it's children, this would create a cyclical reference

        // Update it in the DB Files collection
        const fileUpdates: FileModel = {
          _id: data.fileIDs.join(','),
          folderID: dropID ? { $oid: dropID } : ''
        };

        this.fetching = true;
        const update$ = data.fileIDs.length === 1 ? this.filesService.updateOne(fileUpdates) : this.filesService.updateMany(fileUpdates);
        update$.subscribe(
          () => {
            this.fetching = false;
            this.refreshed.emit('refreshed'); // update files data models
            this.foldersService.refresh(); // update folders data models
            this.translate.get('FILES.SUCCESSFULLY_MOVED').subscribe(translation => {
              this.snackBar.open(translation, '', { duration: 3000 });
            });
          },
          error => {
            this.fetching = false;
            this.translate.get(error.error.errors[0]).subscribe(translation => {
              this.snackBar.open(translation, '', { duration: 3000 });
            });
          }
        );
      }
    } else {
      // Upload multiple files or a folder
      if (event.dataTransfer.items && event.dataTransfer.items.length) {
        const files: Array<File> = [];

        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          const item = event.dataTransfer.items[i];
          // If dropped items aren't files, reject them
          if (item.kind === 'file') {
            const entry = item.webkitGetAsEntry();
            if (entry.isFile) {
              const file = item.getAsFile();
              files.push(file);
            } else if (entry.isDirectory) {
              // listing = scanFiles(entry); // TODO: implement folder upload
              this.dialogService.showDialog('UPLOAD.FOLDERS_NOT_SUPPORTED', null, 'UPLOAD.CREATE_NEW_FOLDERS');
            }
          }
        }
        if (files.length) {
          this.uploadService.handleFilesInput(files, dropID);
        }
      } else if (event.dataTransfer.files && event.dataTransfer.files.length) {
        // Use DataTransfer interface to access the file(s)
        this.uploadService.handleFilesInput(event.dataTransfer.files, dropID);
      }
    }
  }

  @HostListener('document:keydown', ['$event', '$event.target'])
  onKeyDown(event: KeyboardEvent, target: HTMLElement): void {

    if (!this.router.url.includes('view=fullscreen')) { // Prevent files key listening when swiper is being used
      // Because we bind to the document we need to allow the event to trigger
      // only in special conditions, to not break the key events of other controls
      // like inputs or buttons
      if (!target || // Target not defined
          this.isMobile || // On mobile don't trigger keydown events
          !(
            ['BODY', 'MAT-SIDENAV'].includes(target.nodeName) || // Body or sidenav
            target.closest('.files') || // Files container
            target.closest('.folders a') // Folders anchors
          )) {
        return;
      }
  
      /* eslint-disable complexity */
      if (event.key === 'ArrowDown' || (this.viewMode === 'grid' && event.key === 'ArrowRight')) {
        event.preventDefault(); // Prevent auto-scrolling
        if (this.lastIndex < this.dataSource.length - 1) {
          this.lastSelected = this.lastIndex++;
          this.scrollIntoView(this.lastIndex);
          this.showDetails(true, this.dataSource[this.lastIndex]);
          this.lastIndexID = this.dataSource[this.lastIndex]._id as string;
          this.sendSelectedToInspect();
        }
      } else if (event.key === 'ArrowUp' || (this.viewMode === 'grid' && event.key === 'ArrowLeft')) {
        event.preventDefault(); // Prevent auto-scrolling
        if (this.lastIndex > 0) {
          this.lastSelected = this.lastIndex--;
          this.scrollIntoView(this.lastIndex);
          this.showDetails(true, this.dataSource[this.lastIndex]);
          this.lastIndexID = this.dataSource[this.lastIndex]._id as string;
          this.sendSelectedToInspect();
        }
      }
  
      if (event.ctrlKey || event.metaKey) { // Ctrl (Windows) or Command (Mac) + A
        switch (event.key) {
          case 'a':
            event.preventDefault(); // Prevent text selection
            this.toggleSelectAll(true); // Select all
            break;
          case 's':
            event.preventDefault(); // Prevent save webpage
            if (this.lastIndex < 0) {
              this.lastIndex = this.lastSelected = 0;
              this.lastIndexID = '';
            }
            this.dataSource[this.lastIndex].selected = true; // Toggle select current
            this.download();
            break;
          default:
        }
      } else {
        switch (event.key) {
          case ' ': // Space
            event.preventDefault(); // Prevent page scroll
            if (this.lastIndex < 0) {
              this.lastIndex = this.lastSelected = 0;
              this.lastIndexID = '';
            }
            this.dataSource[this.lastIndex].selected = !this.dataSource[this.lastIndex].selected; // Toggle select current
            this.countSelectedRows();
            this.showDetails(true, this.dataSource[this.lastIndex]);
            break;
          case 'a':
            const item = this.viewMode === 'list' ? this.matRows.toArray()[this.lastIndex] : this.matThumbnails.toArray()[this.lastIndex];
            if (item != null) {
              item.element.nativeElement.getElementsByClassName('more-button')[0].click();
            }
            break;
          case 'F':
            if (event.shiftKey && this.canEditActiveFolder) {
              this.newFolderDialog();
            }
            break;
          case 'i':
            this.toggleInfo();
            break;
          case 'm':
            this.showOnMap(this.dataSource[this.lastIndex > 0 ? this.lastIndex : 0]);
            break;
          case 'v':
            this.setViewMode(this.viewMode === 'grid' ? 'list' : 'grid');
            break;
          case 'Enter':
          case 'o':
            if (this.selectedCount > 0) {
              this.toggleInfo();
            } else if (this.lastIndex >= 0) {
              if (this.dataSource[this.lastIndex].isFolder) {
                this.navigateToFolder(String(this.dataSource[this.lastIndex]._id)); // Enter folder
              } else if (this.dataSource[this.lastIndex].webViewLink) {
                this.toggleInfo({ view: 'fullscreen' });
              } else {
                this.toggleInfo();
              }
            }
            break;
          case 'Escape':
            this.lastIndex = -1; // Reset lastIndex
            this.lastIndexID = '';
            this.lastSelected = 0; // Reset lastSelected
            this.toggleSelectAll(false); // Deselect all
            break;
          case 'Delete':
            if (this.login.accountType === AccountType.SUPERADMIN) {
              if (this.lastIndex >= 0) {
                this.dataSource[this.lastIndex].selected = true;
                this.countSelectedRows();
              }
              this.delete();
            }
            break;
          default:
        }
      }
      /* eslint-enable complexity */
    }

  }

  onMouseDown(event: MouseEvent, currentItem: any, index: number): void {
    const newIndex = this.dataSource.findIndex(file => file._id === currentItem._id)
    const target = event.target as HTMLElement;
    const targetCheck = target.className.indexOf('mat-checkbox-layout') > -1 || // expanded area 48px
        target.className.indexOf('mat-checkbox-inner-container') > -1; // checkbox icon 24px

    if (event.shiftKey) {
      if (this.lastIndex !== index) {
        event.preventDefault(); // Disable text selection
        // Select all between lastSelected and current
        for (let i = Math.min(this.lastIndex, index); i <= Math.max(this.lastIndex, index); i++) {
          if (!targetCheck || i !== index) {
            this.dataSource[i].selected = index > this.lastSelected ? i >= this.lastSelected : i <= this.lastSelected;
          }
        }
      }
    } else if (event.ctrlKey || event.metaKey || targetCheck) { // Ctrl (Windows) or Command (Mac) or Checkbox
      if (!targetCheck) {
        // Toggle select current
        this.dataSource[index].selected = !this.dataSource[index].selected;
      }
      this.lastSelected = index;
    } else {
      // Deselect all
      if (!currentItem.selected) {
        this.dataSource.forEach(item => item.selected = false);
        this.lastSelected = index;
      }
    }
    if (!targetCheck) {
      this.countSelectedRows();
      this.showDetails(true, currentItem);
    }
  }

  onMouseUp(event: MouseEvent, currentItem: any, index: number): void {
    const target = event.target as HTMLElement;
    const targetCheck = target.className?.indexOf('mat-checkbox-layout') > -1 || // expanded area 48px
        target.className?.indexOf('mat-checkbox-inner-container') > -1; // checkbox icon 24px

    if (targetCheck) { // Allow checkbox models to sync
      setTimeout(() => {
        this.countSelectedRows();
        if (this.selectedCount == 0) {
          currentItem = undefined;
        }
        this.showDetails(true, currentItem);
      }, 1);
    }
  }

  onPress(event: PointerEvent, currentItem: any, index: number): void {
    const target = event.target as HTMLElement;
    if (!target.closest('a, button, .drag-handle')) {
      this.dataSource[index].selected = !this.dataSource[index].selected; // Toggle select current

      this.countSelectedRows();
      this.showDetails(true, currentItem);
    }
  }

  onTap(event: PointerEvent, currentItem: any, index: number): void {
    const target = event.target as HTMLElement;
    if (!target.closest('a, button, .drag-handle')) {
      if (this.selectedCount > 0) { // Select mode
        // Toggle select current
        this.onPress(event, currentItem, index);
      } else {
        if (currentItem.isFolder) {
          this.navigateToFolder(currentItem._id);
        } else if (this.sidenavDetailService.opened$.value) {
          // If sidenavDetail is opened show the details)
          this.showDetails(false, currentItem);
        } else {
          // Show the file in fullscreen
          this.showDetails(false, currentItem, { view: '' });
        }
      }
    }
  }

  openInspection(row: FileModel): void {
    if(this.locationURL === 'inspection3d') {
      const prefix = !this.isSiteURL ? ['inspection3d', this.folderModel._id] : ['portfolio',this.siteId,'inspection3d', this.folderModel._id];

      this.router.navigate(
        [{ outlets: { primary: ['inspection3d', this.folderModel._id], detail: (row && row._id) ? ['file', row._id] : null }}],
        { queryParamsHandling: 'merge' }
      );
    } else {
      const prefix = !this.isSiteURL ? ['inspection', this.folderModel._id] : ['portfolio',this.siteId,'inspection', this.folderModel._id];
      this.router.navigate(
        [{ outlets: { primary: prefix, detail: (row && row._id) ? ['file', row._id] : null }}],
        { queryParamsHandling: 'merge' }
      );
    }
  }

  openInspectionFolder(row: FileModel): void {
    this.router.navigate(
      [{ outlets: { primary: ['inspection', row._id], detail: null }}],
      { queryParamsHandling: 'merge' }
    );
  }

  openInspection3dFolder(row: FileModel): void {
    this.router.navigate(
      [{ outlets: { primary: ['inspection3d', row._id], detail: null }}],
      { queryParamsHandling: 'merge' }
    );
  }

  openViewer(row: FileModel): void {
    this.router.navigate(
      [{ outlets: { primary: ['viewer', row._id]}}],
      { queryParamsHandling: 'merge' }
    );
  }

  refreshIndex(): void {
    if (this.dataSource && this.activeDetailID) {
      this.lastIndex = this.dataSource.findIndex(file => file._id === this.activeDetailID);
      if (this.lastIndex !== -1) {
        this.scrollIntoView(this.lastIndex);
      }
    } else {
      this.lastIndex = -1;
    }
  }

  regenerateThumbnails(): void {
    const selected = this.dataSource ? this.dataSource.filter(item => item.selected && this.displayableTypes.includes(item.mimeType)) : [];
    selected.forEach((fileModel: any) => {
      if (fileModel.size && fileModel.size < 500 * 1024 * 1024) { // files smaller than 500MB (lambda disk limit)

        // Get image metadata
        const operations = [];
        operations.push({ name: 'meta' });

        if (fileModel.size < 100 * 1024 * 1024 && // images smaller than 100MB (lambda memory limit)
            fileModel.mimeType &&
            fileModel.mimeType.startsWith('image/') && // all images
            !fileModel.mimeType.endsWith('arw')) { // except raw images
          // Resize and crop the img to fill the 144x144 area (for table icons on @2x retina)
          operations.push({ name: 'iconURL', parameters: ['144', '144'] });
          // Resize the img to height = 720px preserving the aspect ratio (for grid thumbnails on @2x retina)
          operations.push({ name: 'thumbnailURL', parameters: ['0', '720'] });
        }

        // Lambda function for meta, iconURL and thumbnailURL
        const lambdaRequestBody: LambdaRequestBody = {
          bucket: environment.storage,
          keys: [`f/${fileModel._id}/${fileModel.name}`],
          operations
        };

        this.fetching = true;
        this.lambdaService.runFunction(`s3ImageProcessing${environment.production ? '' : 'Dev'}`, lambdaRequestBody)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(lambdaResponse => {
          const data = lambdaResponse.data;

          // Update it in the DB Files collection
          const fileUpdates: FileModel = {
            _id: fileModel._id,

            meta: data.meta || null,
            geo: data.geo || null,
            taken: data.taken && { $date: { $numberLong: String(new Date(data.taken).getTime()) }},

            height: data.fileHeight || null,
            width: data.fileWidth || null,

            iconURL: data.iconURL && data.iconURL.split('?')[0], // Remove pre-signed query
            thumbnailURL: data.thumbnailURL && data.thumbnailURL.split('?')[0] // Remove pre-signed query
          };
          this.filesService.updateOne(fileUpdates)
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(
            () => {
              // update data models
              fileModel = {
                ...fileModel,
                ...fileUpdates,
                ...{
                  iconLink: data.iconLink,  // Add pre-signed CloudFront Link
                  thumbnailLink: data.thumbnailLink,  // Add pre-signed CloudFront Link
                  taken: data.taken && new Date(data.taken),
                  modified: new Date()
                }
              };
              this.filesService.updateItem(fileModel);
              fileModel.selected = false;
              this.fetching = false;
            },
            error => {
              this.fetching = false;
              this.dialogService.showDialog('FILE.SAVING_FAILED', error.status, error.url, error.error);
            });
        },
        () => {
          this.fetching = false;
          this.translate.get('FILES.THUMBNAIL_FAILED').subscribe(translation => {
            this.snackBar.open(`${fileModel.name} ${translation}`, '', { duration: 3000 });
          });
        });
      }
    });
  }

  scrolled(bottom?: number): void {
    if (!this.dataSource) { return; }

    const D = document;
    if (this.isMobile || this.isXSmall) { // If is-mobile the sidenav-content was scrolled
      bottom = Math.max(D.body.scrollHeight, D.documentElement.scrollHeight,
                        D.body.clientHeight, D.documentElement.clientHeight) -  // Scroll Height
        this.viewportRuler.getViewportScrollPosition().top -                    // Scroll Top
        this.viewportRuler.getViewportSize().height;                            // Viewport Height
    } else if (bottom === null) { // If non-mobile the files was scrolled
      const scrollable = D.getElementById('files'); // This MUST have css 'overflow-y: auto' set to work
      bottom = scrollable ? scrollable.scrollHeight - scrollable.scrollTop - scrollable.clientHeight : null;
    }

    if (bottom !== null && bottom <= 50) { // Use 50, not 0 to be sure it always triggers the loading of a new page
      if (this.dataSource.length < this.totalItems) {
        // Wait if last page finished loading
        this.nextPage.emit('nextPage');
      }
    }
  }

  scrollIntoView(index: number): void {
    if (this.matRows || this.matThumbnails) {
      const item = this.viewMode === 'list' ? this.matRows.toArray()[index] : this.matThumbnails.toArray()[index];
      if (item != null) {
        const rect = item.element.nativeElement.getBoundingClientRect();
        const rectWrapper = this.wrapperFiles.nativeElement.getBoundingClientRect()
        if ((rect.y - rectWrapper.y <= 30) || ((rect.y + rect.height) > window.innerHeight)) {
          item.element.nativeElement.scrollIntoView(false, { behavior: 'instant' });
        }
      }
    }
  }

  bentleyGetEquipment(): void {
    const dialogRef = this.dialog.open(BentleyDialogComponent, {
      width: '450px',
    });

    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined) {
        const bentleyProjectID = dialog.bentleyProjectID;
        if (bentleyProjectID !== undefined && bentleyProjectID.length === 36) {
          const fileID = this.dataSource ? this.dataSource.filter(item => item.selected)[0]._id : "";

          const subscription = this.filesService.bentleyGetEquipment({
              "bentleyProjectID": bentleyProjectID,
              "fileID": fileID
          })
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(response => {
          });
        }
      }
    });
  }

  sendToImogent(): void {
    // add Dialog so that user has to enter RaptorMaps orderID
    const dialogRef = this.dialog.open(SendToImogentDialogComponent, {
      width: '360px',
      direction: this.dir
    });

    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined) {
        const imogentSuborderId = dialog.imogentSuborderId;
        if (dialog.imogentSuborderId !== undefined && imogentSuborderId.length === 20) {
          const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];
          const fileIDs = selected.map(file => ({ $oid: String(file._id) }));

          const subscription = this.filesService.sendToImogent({ fileIDs, imogentSuborderId })
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(response => {
            this.translate.get([
              'FILES.SENT_TO_IMOGENT',
              'INFO'
            ]).subscribe(translations => {
              const snackBarRef = this.snackBar.open(
                translations['FILES.SENT_TO_IMOGENT'],
                translations['INFO'],
                { duration: 10000 });

              snackBarRef.onAction().subscribe(() => {
                const message = JSON.stringify(response.data, (key, value) => key === 'upload_session_id' ?
                    `<a href="https://app.raptormaps.com/image-sets/map" rel="noopener" target="_blank">${value}</a>` : value, 2)
                    .replace(/"</, '<').replace(/>"/, '>').replace(/\\/g, '');

                this.dialogService.showDialog('FILES.SENT_TO_IMOGENT', null, message, null);
              });
            });
          },
          error => {
            this.snackBar.dismiss();
            this.dialogService.showDialog('FILES.SEND_TO_IMOGENT', error.status, error.url, error.error);
          });

          this.translate.get([
            'FILES.SENDING_TO_IMOGENT',
            'CANCEL'
          ], { count: fileIDs.length }).subscribe(translations => {
            const snackBarRef = this.snackBar.open(
              translations['FILES.SENDING_TO_IMOGENT'],
              translations['CANCEL'],
              { duration: 0 }); // Show forever

            snackBarRef.onAction().subscribe(() => {
              subscription.unsubscribe();
            });
          });
        }
      }
    });
  }

  loadPagesTillFileIsFound() {
    // numberOfNewFilesToLoad = the index of the active file - how many we already loaded + 10(to avoid placing the file at the bottom of the page so it triggers nextPage event)
    const numberOfNewFilesToLoad = this.filteredFiles.findIndex(file => file._id === this.activeDetailID) - this.filesService.data$.value.length +20
    // Extract new files using slice
    const newFiles = this.filteredFiles.slice(this.filesService.data$.value.length, numberOfNewFilesToLoad);
    // Update loaded files directly (no need for temporary variable)
    this.filesService.data$.next([...this.filesService.data$.value, ...newFiles]);
  }

  sendSelectedToInspect(): void {
    if (this.location === 'inspection' && this.dataSourceBeforeFilter && this.dataSourceBeforeFilter.length > 0 && this.lastIndexID){
      const searched = this.filesService.data$.value.find(file => file._id === this.lastIndexID);
      setTimeout(() => {
        if (!searched) {
          this.lastIndex = -1;
          const numberOfNewFilesToLoad = this.filteredFiles.findIndex(file => file._id === this.lastIndexID) + 10
          const newFiles = this.filteredFiles.slice(0, numberOfNewFilesToLoad);
          this.filesService.data$.next([...this.filesService.data$.value,  ...newFiles]);
          return;
        }
        this.inspectionService.setSelectedFile(searched)
      }, 50);
    }
  }

  sendToRaptorMaps(): void {
    // add Dialog so that user has to enter RaptorMaps orderID
    const dialogRef = this.dialog.open(SendToRaptorMapsDialogComponent, {
      width: '360px',
      direction: this.dir
    });

    dialogRef.afterClosed().subscribe((dialog: any) => {
      if (dialog !== undefined) {
        const orderID = parseFloat(dialog.orderId);
        if (dialog.orderId !== undefined && !Number.isNaN(orderID) && orderID > 0) {
          const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];
          const fileIDs = selected.map(file => ({ $oid: String(file._id) }));

          const subscription = this.filesService.sendToRaptorMaps({ fileIDs, orderID })
          .pipe(takeUntil(this.ngDestroy$))
          .subscribe(response => {
            this.translate.get([
              'FILES.SENT_TO_RAPTOR_MAPS',
              'INFO'
            ]).subscribe(translations => {
              const snackBarRef = this.snackBar.open(
                translations['FILES.SENT_TO_RAPTOR_MAPS'],
                translations['INFO'],
                { duration: 10000 });

              snackBarRef.onAction().subscribe(() => {
                const message = JSON.stringify(response.data, (key, value) => key === 'upload_session_id' ?
                    `<a href="https://app.raptormaps.com/image-sets/map" rel="noopener" target="_blank">${value}</a>` : value, 2)
                    .replace(/"</, '<').replace(/>"/, '>').replace(/\\/g, '');

                this.dialogService.showDialog('FILES.SENT_TO_RAPTOR_MAPS', null, message, null);
              });
            });
          },
          error => {
            this.snackBar.dismiss();
            this.dialogService.showDialog('FILES.SEND_TO_RAPTOR_MAPS', error.status, error.url, error.error);
        });

          this.translate.get([
            'FILES.SENDING_TO_RAPTOR_MAPS',
            'CANCEL'
          ], { count: fileIDs.length }).subscribe(translations => {
            const snackBarRef = this.snackBar.open(
              translations['FILES.SENDING_TO_RAPTOR_MAPS'],
              translations['CANCEL'],
              { duration: 0 }); // Show forever

            snackBarRef.onAction().subscribe(() => {
              subscription.unsubscribe();
            });
          });

        }
      }
    });

  }

  getCardsThumbails(): void {
    this.dataSourceCards.forEach(item => {
      if (item.card && item.card.thumbnail) {
        this.filesService.findOne(item.card.thumbnail)
        .pipe(takeUntil(this.ngDestroy$))
        .subscribe(
        response => {
          item.thumbnailLink = response.data.thumbnailLink;
        });
      }
    });
  }

  getGridWidth(file: FileModel): number {
    if (this.location === 'inspection') {
      return ((file.width * ((this.isXSmall || this.isSmall) ? 136 : 236) / file.height / 2) || ((this.isXSmall || this.isSmall) ? 136 : 236 / 2));
    } else {
      return ((file.width * ((this.isXSmall || this.isSmall) ? 136 : 236) / file.height) || ((this.isXSmall || this.isSmall) ? 136 : 236));
    }
  }

  setViewMode(viewMode: 'grid' | 'list'): void {
    this.viewMode = viewMode;
    setTimeout(() => { this.scrolled(null); }, 1);
  }

  showDetailsStopEvent(event: MouseEvent, onlyIfOpened: boolean, currentItem: any) {
    //event.preventDefault();
    //event.stopPropagation();
    //this.showDetails(onlyIfOpened, currentItem)
  }

  showDetails(onlyIfOpened: boolean, currentItem: any, params: Params = {}): void {
    // If click on checkbox and detail not opened then exit
    if (onlyIfOpened && !this.sidenavDetailService.opened$.value) {
      return;
    }

    // Show details
    if (this.selectedCount > 1) {
      this.router.navigate(
        [{ outlets: { detail: ['files', this.selectedCount] }}],
        { queryParamsHandling: 'merge' }
      );

    } else {

      if (this.dataSource && this.dataSource.length && this.selectedCount !== 0) {
        // Checked item has priority over currentItem
        if (this.selectedCount === 1) {
          currentItem = this.dataSource.find(item => item.selected);
        }

        // Find currentItem from activeDetailID
        if (!currentItem) {
          currentItem = this.dataSource.find(file => file._id === this.activeDetailID);
        }
      }
      // Set parent folder if no valid currentItem
      if ((!currentItem || currentItem._id.length !== 24) &&
          this.activeFolderID$.value && this.activeFolderID$.value.length === 24) {
        currentItem = { _id: this.activeFolderID$.value };
      }

      // Show first item if nothing is selected
      if (!currentItem && this.dataSource && this.dataSource.length) {
        currentItem = this.dataSource[0];
      }
      this.router.navigate(
        [{ outlets: { detail: (currentItem && currentItem._id.length === 24) ? ['file', currentItem._id, params] : null }}],
        { queryParamsHandling: 'merge' }
      );
    }
  }

  // eslint-disable-next-line complexity
  showOnMap(currentItem?: any): void {
    if (this.selectedCount > 1 || currentItem && currentItem._id) {

      // Show on map
      if (this.selectedCount > 1) {

        const isFolder = currentItem.isFolder;
        const selected = this.dataSource ? this.dataSource.filter(item => item.selected) : [];
        const fileIDs = selected
        .filter(file => isFolder ? file.isFolder : !file.isFolder) // Select either folders or files, not both
        .map(file => String(file._id));

        this.router.navigate(
          [{ outlets: { primary: ['maps', 'search'] }}],
          { queryParams: { query: (isFolder ? 'folderID:' : '') + fileIDs.join(',') }}
        );

      } else {

        // Checked item has priority over currentItem
        if (this.selectedCount === 1) {
          currentItem = this.dataSource ? this.dataSource.find(item => item.selected) : currentItem;
        }

        // Set parent folder if no valid currentItem
        if ((!currentItem || currentItem._id.length !== 24) &&
            this.activeFolderID$.value && this.activeFolderID$.value.length === 24) {
          currentItem = { _id: this.activeFolderID$.value };
        }

        // Show first item if nothing is selected
        if (!currentItem && this.dataSource && this.dataSource.length) {
          currentItem = this.dataSource[0];
        }

        if (this.showOnMapIsSupported(currentItem)) {
          this.router.navigate(
            [{ outlets: { primary: ['maps', 'search'] }}],
            { queryParams: { query: (currentItem.isFolder ? 'folderID:' : '') + currentItem._id }}
          );
        }
      }
    }
  }

  showOnMapIsSupported(file: FileModel): boolean {
    return this.selectedCount > 1 ||
      file.isFolder ||
      !!file.geo ||
      (file.tags &&
      (file.tags.includes('orthomosaic') || file.tags.includes('point_cloud')));
  }

  showTable(): void {
    this.hideTable = false;
  }

  toggleColumn(column: string): void {
    let columns = this.displayedColumns;
    const index = columns.indexOf(column);
    if (index > -1) { // Remove displayed column
      columns.splice(index, 1);
    } else {  // Add displayed column and also maintain position
      columns = this.columns.filter(c => columns.includes(c.value) || c.value === column).map(c => c.value);
    }
    this.displayedColumns = columns;
    this.storageService.setItem('files-displayed-columns', this.displayedColumns);
  }

  toggleInfo(params: Params = {}): void {
    if (this.sidenavDetailService.opened$.value) {
      this.router.navigate([{ outlets: { detail: null }}], { queryParamsHandling: 'merge' });
    } else {
      if (this.lastIndex === -1 && this.activeFolderID$.value && this.activeFolderID$.value.length === 24) {
        this.showDetails(false, { _id: this.activeFolderID$.value });
      } else {
        this.showDetails(false, this.dataSource[this.lastIndex], params);
      }
    }
  }

  toggleSelect(file: FileModel): void {
    file.selected = !file.selected;
    this.countSelectedRows();
  }

  // Toggle select all, or force selected value on all
  toggleSelectAll(selected?: boolean): void {
    if (this.dataSource) {
      selected = (selected === false || selected === true) ? selected : !this.isLoadedSelected;
      this.dataSource.forEach(item => {
          item.selected = selected;
      });
      this.countSelectedRows();
      this.showDetails(true, null); // Show details for selected items
    }
  }

  updateCardsWidth(): void {
    if (this.cards) {
      if (this.cards.nativeElement.offsetWidth >= 1000) {
        this.cardsInRow = 4;
      } else if (this.cards.nativeElement.offsetWidth >= 750) {
        this.cardsInRow = 3;
      } else if (this.cards.nativeElement.offsetWidth >= 500) {
        this.cardsInRow = 2;
      } else {
        this.cardsInRow = 1;
      }
    }
  }

  watermark(): void {
    const selected = this.dataSource ? this.dataSource.filter(item => item.selected && this.displayableTypes.includes(item.mimeType)) : [];
    const filtered = selected.filter((item) => {
      if (item.width && this.displayableTypes.includes(item.mimeType)) {
        return item;
      }
    });

    const fileIDs = filtered.map(file => ({ _id: { $oid: String(file._id) }}));

    const parentFolder = String(filtered[0]["folderID"]);

    this.filesService.watermark(fileIDs, parentFolder).subscribe()
  }

  trackByIndex = i => i; // https://angular.io/guide/template-syntax#ngfor-with-trackby
}
