import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Portfolio, PortfolioStats, ResponseData } from 'src/app/shared/portfolio/portfolio.model';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PortfoliosService {

  constructor(
    private readonly http: HttpClient 
  ) { }

  public insertOne(portfolio: Portfolio): Observable<ResponseData<Portfolio>> {
    return this.http.post<ResponseData<Portfolio>>(`${environment.apiPath}portfolios`, portfolio)
  }

  public getPortfolios (): Observable<any> {
    return this.http.get<any>(`${environment.apiPath}portfolios?pageSize=5000`)
  }

  public getPortfolioStats(id: string): Observable<ResponseData<PortfolioStats>> {
    return this.http.get<ResponseData<PortfolioStats>>(`${environment.apiPath}portfolios/${id}/statistics`)
  }

  public getPortfolioById(id: string): Observable<ResponseData<Portfolio>> {
    return this.http.get<ResponseData<Portfolio>>(`${environment.apiPath}portfolios/${id}`)
  }

  public deleteOne(PortfolioId: string): Observable<any> {
    return this.http.delete<Portfolio>(`${environment.apiPath}portfolios/${PortfolioId}`);
  }

  public updateOne(portfolio: Portfolio): Observable<ResponseData<Portfolio>>{
    console.log('here')
    return this.http.patch<ResponseData<Portfolio>>(`${environment.apiPath}portfolios/${portfolio._id}`,portfolio);
  }
}
