import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countOf'
})
export class CountOfPipe implements PipeTransform {
  transform(scores: Record<string, number> | null | undefined, valueToCount: number): number {
    if (!scores) {
      return 0;
    }
    return Object.values(scores).filter(score => score === valueToCount).length;
  }
}